import React, {Component} from 'react';
import Localization from "../public/Localization";
import Constants from "../user/Constants";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Footer from "../public/Footer";

class StudentSubmission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            submissions: [],
            submissionsLoaded: false,
            nameCz: " / ",
            nameEn: " / ",
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/student/submission/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                     + "/" + this.props.params.parallelId + "/" + this.props.params.milestoneIdentifier, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({submissions: data.submissions, nameCz: " - " + data.descriptionCz + " / " + data.nameCz, nameEn: " - " + data.descriptionEn + " / " + data.nameEn});
                        this.setState({submissionsLoaded: true});
                    }
                });
        })();
    }

    getFilenameList(files) {
        const filelist = files.map(file => {
            file.link = Constants.GetServer() + "/api/user/submissionfiledownload/" + file.fileId + "/" + file.token + "";
            return <li key = {file.fileId}>
                    <a href={file.link} target="_blank">{file.filename}</a>
            </li>
        });
        return filelist;
    }

    getFilePartName(file) {
        if (Localization.GetLanguage() === "CZ") {
            if (file.partNameCz === undefined) return "";
            return Localization.Get("studentSubmission.part") + " " + file.partNameCz + ", ";
        } else {
            if (file.partNameEn === undefined) return "";
            return Localization.Get("studentSubmission.part") + " " + file.partNameEn + ", ";
        }
    }

    getResultList() {
        const results = [];
        this.state.submissions.forEach(submission => {
            if (submission.id.toString() === this.props.params.submissionCode) {
                const sortedGradings = submission.gradings.sort((a, b) => a.part - b.part);
                for (let i = 0; i < sortedGradings.length; i++) {
                    let found = false;
                    for (let j = 0; j < submission.parts.length; j++) {
                        if (submission.parts[j].part === sortedGradings[i].part) {
                            found = true;
                            results[results.length] = {
                                part: sortedGradings[i].part + ": " + (Localization.GetLanguage() === "CZ" ? submission.parts[j].nameCz : submission.parts[j].nameEn),
                                points: sortedGradings[i].points,
                                gradedBy: sortedGradings[i].gradedBy,
                            }
                        }
                    }
                    if (!found) {
                        results[results.length] = {
                            part: sortedGradings[i].part,
                            points: sortedGradings[i].points,
                            gradedBy: sortedGradings[i].gradedBy,
                        }
                    }
                }
            }
        });
        return results.map(result => {
            return <tr key={result.part}>
                <td>{result.part}</td>
                <td>{result.points}</td>
                <td>{result.gradedBy}</td>
            </tr>
        });
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const files = [];

        this.state.submissions.forEach(submission => {
            if (submission.id.toString() === this.props.params.submissionCode) {
                submission.files.forEach(file => {
                    const oneFile = {
                        part: this.getFilePartName(file),
                        filename: file.filename,
                        pictures: []
                    }
                    file.pictures.forEach(picture => {
                        oneFile.pictures[oneFile.pictures.length] = {
                            id: picture.pictureId,
                            page: picture.page,
                            token: picture.token
                        };
                    })
                    files[files.length] = oneFile;
                });
            }
        })

        let pictureCounter = 0;
        files.forEach(file => {
           file.pictures.forEach(picture => {
               window.sessionStorage.setItem("picturePath" + pictureCounter, "/submissionview/" + picture.id +"/" + picture.token);
               pictureCounter++;
           });
        });
        window.sessionStorage.setItem("pictureCounter", pictureCounter.toString());

        const fileList = files.map(file => {

            const pictureList = file.pictures.map(picture => {

                return <tr key={picture.id}>
                    <td>{picture.page + " (" + picture.id + ")"}</td>
                    <td>
                        <a href={Constants.GetServer() + "/api/user/picturedownload/" + picture.id +"/" + picture.token} target="_blank"><img src={Constants.GetServer() + "/api/user/smallpicturedownload/" + picture.id +"/" + picture.token} /></a>
                    </td>
                    <td>
                        <Button size="sm" color="primary" onClick={() => {window.open("/submissionview/" + picture.id + "/" + picture.token);}}>
                            {Localization.Get("studentSubmission.teacherCorrection")}</Button>
                    </td>
                </tr>
            });

            return <li key={file.filename}>
                <h4>{this.getFilePartName(file)}{Localization.Get("studentSubmission.file")} {file.filename}</h4>
                <Table bordered hover>
                    <thead>
                    <tr>
                        <th>{Localization.Get("studentSubmission.page")}</th>
                        <th>{Localization.Get("studentSubmission.picture")}</th>
                        <th>{Localization.Get("studentSubmission.operations")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pictureList}
                    </tbody>
                </Table>
            </li>
        })


        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("studentSubmission.title")}</h2><br/>
                            <h3>{Localization.Get("studentSubmission.submissionList")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode}
                                {(this.state.submissionsLoaded === false) ? "" : (Localization.GetLanguage() === "CZ" ? this.state.nameCz : this.state.nameEn)} / {this.props.params.submissionCode} </h3>
                            { (this.state.submissionsLoaded === false) ? <LoadingSpinner /> : <div>
                                <ul>
                                    {fileList}
                                </ul>
                                <br/><br/>
                                <h4>{Localization.Get("studentSubmission.results")}</h4>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("studentSubmission.part")}</th>
                                        <th>{Localization.Get("studentSubmission.points")}</th>
                                        <th>{Localization.Get("studentSubmission.gradedBy")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getResultList()}
                                    </tbody>
                                </Table>
                            </div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/studentsubmissions/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId + "/" + this.props.params.milestoneIdentifier}>
                                {Localization.Get("studentSubmission.goBack")}</Button>
                            &nbsp;
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default StudentSubmission;