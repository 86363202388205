import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "../public/Rest";
import Accordion from 'react-bootstrap/Accordion';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Footer from "../public/Footer";

class LocalizationElement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            pageName: "",
            elements: [],
            newElementName: '',
            cz: [],
            en: [],
            czOriginal: [],
            enOriginal: [],
            newCz: '',
            newEn: '',
            refresh: 0,
        }
        this.makeNewElement = this.makeNewElement.bind(this);
        this.saveElement = this.saveElement.bind(this);
    }

    parseResponse(data) {
        const czLoc = [];
        const enLoc = [];
        const czOriginalLoc = [];
        const enOriginalLoc = [];
        data.elements.forEach(element => {
            czLoc[element.id] = element.cz;
            enLoc[element.id] = element.en;
            czOriginalLoc[element.id] = element.cz;
            enOriginalLoc[element.id] = element.en;
        })
        this.setState({cz: czLoc});
        this.setState({en: enLoc});
        this.setState({czOriginal: czOriginalLoc});
        this.setState({enOriginal: enOriginalLoc});
        this.setState({elements: data.elements});
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/localization/page/' + this.props.params.pageId, method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({pageName: data.pageName});
                         this.parseResponse(data);
                     }
                 });
        })();
    }

    updateSet(lg, id, item) {
        lg[id] = item;
        return lg;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const elementList = this.state.elements.map(element => {
            const disabled = (this.state.cz[element.id] === this.state.czOriginal[element.id] && this.state.en[element.id] === this.state.enOriginal[element.id]);
            return <Accordion.Item eventKey={element.id}>
                <Accordion.Header>{element.name} ({element.savedBy}, {element.timeSaved})</Accordion.Header>
                <Accordion.Body>
                    <Form onSubmit={this.onSubmitForm}>
                        {Localization.Get("language.cz")}:
                        <textarea className="form-control" placeholder="" value={this.state.cz[element.id] || ''}
                                  onChange={event => {this.setState({cz: this.updateSet(this.state.cz, element.id, event.target.value)})}}
                                  rows={5}/><br/>
                        {Localization.Get("language.en")}:
                        <textarea className="form-control" placeholder="" value={this.state.en[element.id] || ''}
                                  onChange={event => {this.setState({en: this.updateSet(this.state.en, element.id, event.target.value)})}}
                                  rows={5}/><br/>
                        <Button size="sm" color="success" onClick={() => {this.saveElement(element.id);}} disabled={disabled}>{Localization.Get("localization.elementSave")}</Button>&nbsp;
                        <Button size="sm" color="primary" tag={Link} to={"/localizationelementhistory/" + this.props.params.pageId + "/" + element.id}> {Localization.Get("localization.elementHistory")}</Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        })
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("localization.title")}</h2><br/>
                            <h3>{Localization.Get("localization.elementList")} {this.state.pageName}</h3>
                            { this.state.pageName === "" ? <LoadingSpinner /> : <div>
                                <Accordion flush alwaysOpen>
                                    {elementList}
                                </Accordion>
                                <br/><h3>{Localization.Get("localization.newElement")}</h3><br/>
                            <Form onSubmit={this.onSubmitForm}>
                                <Form.Group className="mb-3" controlId="newElementNameText">
                                    <Form.Label>{Localization.Get("localization.newElementName")}</Form.Label>
                                    <Form.Control type="text" placeholder="" value={this.state.newElementName || ''} onChange={event => {this.setState({newElementName: event.target.value})}}
                                                  maxLength={50} onKeyUp={event => {if (event.key === "Enter") this.makeNewElement();}}/><br/>
                                    <Form.Label for="tacz">{Localization.Get("localization.newElementNameCz")}</Form.Label>
                                    <textarea className="form-control" id="tacz" placeholder="" value={this.state.newCz || ''}
                                              onChange={event => {
                                                  this.setState({newCz: event.target.value})
                                              }}
                                              rows={2}/><br/>
                                    <Form.Label for="taen">{Localization.Get("localization.newElementNameEn")}</Form.Label>
                                    <textarea className="form-control" id="taen" placeholder="" value={this.state.newEn || ''}
                                              onChange={event => {
                                                  this.setState({newEn: event.target.value})
                                              }}
                                              rows={2}/>
                                </Form.Group>
                                <Button color="success" onClick={this.makeNewElement} disabled={this.state.newElementName === ''}>
                                    {Localization.Get("localization.submitNewElement")}
                                </Button>&nbsp;
                                <Button color="primary" tag={Link} to="/localizationpage">{Localization.Get("localization.back")}</Button><br/>&nbsp;
                            </Form>
                            </div>
                            }
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    onSubmitForm(event) {
        event.preventDefault();
    }

    makeNewElement() {
        if (this.state.newElementName === "") return;
        rest({path: '/api/admin/localization/page/' + this.props.params.pageId + '/element', method: 'post', body: {name: this.state.newElementName, newCz: this.state.newCz, newEn: this.state.newEn}},
             (data) => {
                 if (data.result === 1) {
                     this.parseResponse(data);
                 }
        });
        this.setState({newElementName: ""});
        this.setState({newCz: ""});
        this.setState({newEn: ""});
        return 1;
    }

    saveElement(elementId) {
        if (this.state.newPageName === "") return;
        rest({path: '/api/admin/localization/element', method: 'put', body: {pageId: this.props.params.pageId, elementId: elementId, cz: this.state.cz[elementId], en: this.state.en[elementId]}},
             (data) => {
                 if (data.result === 1) {
                     this.parseResponse(data);
                 }
             });
        return 1;
    }

}

export default LocalizationElement;