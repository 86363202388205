import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import {ButtonGroup} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "../public/Rest";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class LocalizationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            pages: [],
            newPageName: '',
            refresh: 0,
        }
        this.makeNewPage = this.makeNewPage.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/localization/page', method: 'get'},
                 (data) => {
                     if (data.result === 1) this.setState({pages: data.pages});
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const pageList = this.state.pages.map(page => {
            return <tr key={page.id}>
                <td>{page.id}</td>
                <td>{page.name}</td>
                <td>
                    <Button size="sm" color="primary" tag={Link} to={"/localizationelement/" + page.id}>{Localization.Get("localization.pageEdit")}</Button>
                </td>
            </tr>
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("localization.title")}</h2><br/>
                            <h3>{Localization.Get("localization.pageList")}</h3>
                            { this.state.pages.length === 0 ? <LoadingSpinner /> : <div>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{Localization.Get("localization.pageName")}</th>
                                        <th>{Localization.Get("localization.pageOperation")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageList}
                                    </tbody>
                                </Table></div> }<br/>
                            <h3>{Localization.Get("localization.newPage")}</h3><br/>
                            <Form onSubmit={this.onSubmitForm}>
                                <Form.Group className="mb-3" controlId="newPageNameText">
                                    <Form.Label>{Localization.Get("localization.newPageName")}</Form.Label>
                                    <Form.Control type="text" placeholder="" value={this.state.newPageName || ''} onChange={event => {this.setState({newPageName: event.target.value})}}
                                                  maxLength={50} onKeyUp={event => {if (event.key === "Enter") {event.preventDefault(); this.makeNewPage();}}}/>
                                </Form.Group>
                                <Button color="primary" onClick={(event) => {event.preventDefault(); this.makeNewPage();}} disabled={(this.state.newPageName === '')}>
                                    {Localization.Get("localization.newPageSubmit")}
                                </Button><br/>&nbsp;
                            </Form>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    onSubmitForm(event) {
        event.preventDefault();
    }

    makeNewPage() {
        if (this.state.newPageName === "") return;
        rest({path: '/api/admin/localization/page', method: 'post', body: {name: this.state.newPageName}},
             (data) => {
                if (data.result === 1) this.setState({pages: data.pages});
             });
        this.setState({newPageName: ""});
        return 1;
    }

}

export default LocalizationPage;