import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import Form from 'react-bootstrap/Form';
import rest from "../public/Rest";
import Accordion from 'react-bootstrap/Accordion';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import Footer from "../public/Footer";

class LocalizationElementHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            pageName: "",
            elementName: "",
            elements: [],
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/localization/element/' + this.props.params.elementId, method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({pageName: data.pageName});
                         this.setState({elementName: data.elementName});
                         this.setState({elements: data.elements});
                     }
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const elementList = this.state.elements.map(element => {
            return <Accordion.Item eventKey={element.id}>
                <Accordion.Header>{element.name} ({element.savedBy}, {element.timeSaved})</Accordion.Header>
                <Accordion.Body>
                    {Localization.Get("language.cz")}:
                    <Form.Control type="text" placeholder="" value={element.cz || ''} maxLength={50} disabled="true"/><br/>
                    {Localization.Get("language.en")}:
                    <Form.Control type="text" placeholder="" value={element.en || ''} maxLength={50} disabled="true"/>
                </Accordion.Body>
            </Accordion.Item>
        })
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("localization.title")}</h2><br/>
                            <h3>{Localization.Get("localization.elementListHistory1")} {this.state.pageName} {Localization.Get("localization.elementListHistory2")} {this.state.elementName}</h3>
                            { this.state.pageName === "" ? <LoadingSpinner /> : <div>
                                <Accordion flush alwaysOpen>
                                    {elementList}
                                </Accordion>
                                <br/>
                                <Button color="primary" tag={Link} to={"/localizationelement/" + this.props.params.pageId}> {Localization.Get("localization.elementBack")}</Button>
                            </div>
                            }
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
             </Container>
        );
    }

}

export default LocalizationElementHistory;