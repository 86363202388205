import { Component } from 'react';
import '../App.css';

class Localization extends Component {

    static localization;
    static language;
    static processing = false;
    static callbacks = [];
    static callbackId = 0;

    static Get(key) {
        if (Localization.localization !== undefined && window.localStorage.getItem('localization_off') !== "true") {
            const translation = Localization.localization.get(key);
            if (translation === undefined) return key;
            return translation;
        }
        return key;
    }

    static SwitchOff() {
        window.localStorage.setItem('localization_off', "true");
    }

    static SwitchOn() {
        window.localStorage.setItem('localization_off', "false");
    }

    static IsInitialized() {
        const initialized = window.sessionStorage.getItem('localization_initialized') === "true";
        if (initialized && Localization.localization === undefined) Localization.SetLanguageSet();
        return initialized;
    }

    static GetLanguage() {
        if (window.sessionStorage.getItem('language') === null) {
            if (navigator.language.includes('cs')) {
                window.sessionStorage.setItem('language', 'CZ');
                document.documentElement.setAttribute("lang", 'cs');
            } else {
                window.sessionStorage.setItem('language', 'EN');
                document.documentElement.setAttribute("lang", 'en');
            }
        }
        return window.sessionStorage.getItem('language');
    }

    static SetLanguage(newLanguage) {
        if (newLanguage === 'CZ') {
            window.sessionStorage.setItem('language', 'CZ');
            document.documentElement.setAttribute("lang", 'cs');
        } else {
            window.sessionStorage.setItem('language', 'EN');
            document.documentElement.setAttribute("lang", 'en');
        }
        this.SetLanguageSet();
    }

    static Clear(callback) {
        window.sessionStorage.setItem('localization_initialized', "false");
        this.Initialize(callback, true);
    }

    static reload() {
        window.location = "/";
        window.location.reload(true);
    }

    static Initialize(callback, clear) {
        if (this.IsInitialized()) {
            return true;
        } else {
            this.callbacks[this.callbackId++] = callback;
            if (!this.processing) {
                this.processing = true;
                try {
                    fetch((clear === true) ? '/api/admin/localization/new' : '/api/public/localization', {
                        method: 'get',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            console.log("error while reading localization");
                            setTimeout(() => this.reload(), 1000);
                        }
                    }).then(data => {
                        for (let i = 0; i < data.localization.length; i++) {
                            let myMap = new Map();
                            for (let j = 0; j < data.localization[i].itemList.length; j++) {
                                myMap.set(data.localization[i].itemList[j].key, data.localization[i].itemList[j].value);
                            }
                            window.sessionStorage.setItem('localization' + data.localization[i].language, JSON.stringify(Array.from(myMap.entries())));
                        }
                        this.SetLanguageSet();
                        window.sessionStorage.setItem('localization_initialized', "true");
                        window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                        window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                        while (this.callbackId > 0) {
                            this.callbacks[--this.callbackId]();
                        }
                    }).catch((error) => {
                        console.log("failure while reading localization: " + error);
                        setTimeout(() => this.reload(), 1000);
                    }).finally(() => {
                        this.processing = false;
                    });
                } catch (error) {
                    console.log("general failure while reading localization: " + error);
                    setTimeout(() => this.reload(), 1000);
                }
            }
            return false;
        }
    }

    static SetLanguageSet() {
        const language = Localization.GetLanguage();
        const itemList = window.sessionStorage.getItem('localization' + language);
        Localization.localization = new Map(JSON.parse(itemList));
        Localization.language = language;
    }

    static GetPlainText(text) {
        let newText = "";
        const src = "áčďéěíĺňóřšťúůýž";
        const out = "acdeeilnorstuuyz";
        for (let i = 0; i < text.length; i++) {
            const letter = text[i].toLowerCase();
            let found = false;
            for (let j = 0; j < src.length && !found; j++) {
                if (letter === src[j]) {
                    found = true;
                    newText = newText + out[j];
                }
            }
            if (!found) newText = newText + letter;
        }
        return newText;
    }

}

export default Localization;