import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "./Navigation";
import rest from "../public/Rest";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Button} from "reactstrap";
import Footer from "../public/Footer";

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            username: null,
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/user/userinfo', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({username: data.username});
                         window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                         window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                         window.sessionStorage.setItem('ROLES', data.roles);
                         const isAdmin = (data.roles.includes('ROLE_ADMIN') ? "true" : "false");
                         const isTeacher = (data.roles.includes('ROLE_TEACHER') ? "true" : "false");
                         const isStudent = (data.roles.includes('ROLE_STUDENT') ? "true" : "false");
                         console.log("admin " + isAdmin);
                         console.log("teacher " + isTeacher);
                         console.log("student " + isStudent);
                         window.sessionStorage.setItem('IS_ADMIN', isAdmin);
                         window.sessionStorage.setItem('IS_TEACHER', isTeacher);
                         window.sessionStorage.setItem('IS_STUDENT', isStudent);
                         if (isAdmin !== "true") {
                             if (isTeacher === "true") {
                                 window.location = "/teachercourses";
                             } else if (isStudent) {
                                 window.location = "/studentcourses";
                             }
                         }
                     }
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {(this.state.isLanguageInitialized && this.state.username !== null) ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <p>{(Localization.Get("landing.intro")).replace("{$username}", this.state.username)}</p>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
             </Container>
        );
    }

}

export default LandingPage;