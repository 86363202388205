import React, {Component} from 'react';
import '../App.css';

class Constants extends Component {

    static GetServer() {
       return window.origin.replace("3000", "8086");
    }
}

export default Constants;