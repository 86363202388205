import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import {ToastContainer} from 'react-toastify';
import Table from "react-bootstrap/Table";
import {Button, Input} from "reactstrap";
import Constants from "../user/Constants";
import {toastError, toastSuccess} from "../public/Toaster";
import {Link} from "react-router-dom";
import "../user/editor.css";
import Footer from "../public/Footer";
import System from "@zxing/library/es2015/core/util/System";


class SubmissionCorrection extends Component {

    constructor(props) {
        const editorIsLoaded = (document.getElementById("assignmentsEditorDetector") !== null);
        if (editorIsLoaded) window.location.reload(); // the editor is there and I do not know how to get rid of it
        super(props);
        this.state = {
            isLanguageInitialized: false,
            submission: undefined,
            submissionLoaded: false,
            callBacksInjected: false,
            editorLoaded: false,
            showResults: false,
            pictures: [], // it is pictures[pictureId], it contains the images only
            states: [], // it is pictures[pictureId], it contains the states if pictures
            drawings: [], // it is drawings[pictureId]
            drawingIds: [], // it is drawings[pictureId]
            drawingsChanged: [], // true, if drawingsChanges[pictureId] changed
            studentAssigned: [], // it is studentAssigned[pictureId]
            partAssigned: [], // it is partAssigned[pictureId]
            gradings: [], // it is gradings[student]
            gradingLines: [], // it is a property of part for which the file was submitted
            reopenCallBack: undefined,
            expectedPictureId: undefined,
            expectedDrawingId: undefined,
            fileId: "-1",
            imageCache: [],
            studentFiltered: "",
            defaultPartFiltered: "?",
            partFiltered: "",
            stateFiltered: "",
            mode: undefined, // edit, locked, view
            locking: false,
            pictureSets: [],
            locks: [],
            lockUpdater: false,
        }
        this.pageBottom = React.createRef();
        this.getImage = this.getImage.bind(this);
        this.injectCallBacks = this.injectCallBacks.bind(this);
        this.editorCallBack = this.editorCallBack.bind(this);
    }

    localizationReplace(text, key) {
        return text.replace(">" + key + "<", ">" + Localization.Get(key) + "<");
    }

    injectCallBacks() {
        if (!this.state.callBacksInjected) {
            try {
                window["setCallBack"](this.editorCallBack);
                window["setGetImage"](this.getImage);
                this.setState({callBacksInjected: true});
            } catch (e) {
                (() => setTimeout(() => this.injectCallBacks(), 100))();
            }
        }
    }

    getCachedImage(id, token) {
        if (this.state.imageCache[id] === undefined) {
            const imageCache = this.state.imageCache;
            const img = document.createElement("img");
            img.setAttribute("src", Constants.GetServer() + "/api/user/smallpicturedownload/" + id + "/" + token);
            img.setAttribute("loading", "lazy");
            imageCache[id] = img;
            this.setState({imageCache: imageCache});
        }
        this.setCachedImage(id);
        return "";
    }

    setCachedImage(id) {
        setTimeout(() => {
            const div = document.getElementById(id);
            if (div === null) {
                this.setCachedImage(id);
            } else {
                div.appendChild(this.state.imageCache[id]);
            }
        }, 1000);
    }

    showDrawing(e, pictureId, drawingId) {
        this.openPicture(pictureId, drawingId);
        e.preventDefault();
        return false;
    }

    getDrawings(picture) {
        const drawingList = picture.drawings.map(correction => {
            return <li>
                <a href={"/submissioncorrection/" + this.props.params.submissionId + "/" + this.props.params.submissionToken + "/" + picture.id + "/" + correction.id}
                onClick={(e) => {this.showDrawing(e, picture.id, correction.id)}}>{Localization.GetLanguage() === "CZ" ? correction.dateCz : correction.dateEn}, {correction.correctedBy}</a>
            </li>
        })
        return drawingList;
    }

    // this is being called from the editor
    getImage(pictureId, drawingId) {

        if (pictureId === undefined) {
            return {
                picture: undefined,
                drawing: undefined,
            };
        }

        const parts = [];
        parts[0] = {
            id: 0,
            name: Localization.Get("submissionCorrection.part0"),
        }
        for (let i = 0; i < this.state.submission.parts.length; i++) {
            parts[i + 1] = {
                id: i + 1,
                name: (Localization.GetLanguage() === "CZ" ? this.state.submission.parts[i].nameCz : this.state.submission.parts[i].nameEn),
            }
        }

        const properties = this.getPictureProperties(pictureId);
        return {
            picture: this.state.pictures[pictureId],
            pictureId: pictureId,
            previousPictureId: properties.previousPictureId,
            nextPictureId: properties.nextPictureId,
            students: this.state.submission.students,
            student: properties.student,
            part: properties.part,
            parts: parts,
            drawing: this.state.drawingIds[pictureId] === drawingId ? this.state.drawings[pictureId] : undefined,
            state: properties.state,
            pictureSet: properties.set,
            downloadPictureId: pictureId,
        };
    }

    getPictureForId(id) {
        if (id === null) return null;
        for (let i = 0; i < this.state.submission.files.length; i++) {
            for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                if (this.state.submission.files[i].pictures[j].pictureId === id) return this.state.submission.files[i].pictures[j];
            }
        }
    }

    getPictureProperties(pictureId) {
        const studentAssigned = this.state.studentAssigned;
        const partAssigned = this.state.partAssigned;
        for (let i = 0; i < this.state.submission.files.length; i++) {
            for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                if (pictureId === this.state.submission.files[i].pictures[j].pictureId) {
                    if (studentAssigned[pictureId] === undefined) studentAssigned[pictureId] = this.state.submission.files[i].pictures[j].student;
                    if (partAssigned[pictureId] === undefined) partAssigned[pictureId] = (this.state.submission.files[i].pictures[j].part).toString();
                    let set = [];
                    let previousPictureId = null;
                    let nextPictureId = null;
                    if (this.state.mode === undefined) alert ("undefined mode");
                    if (this.state.mode === "edit") {
                        // edit
                        set = this.createPictureSet();
                        previousPictureId = this.state.submission.files[i].pictures[j].previousPictureId;
                        if (this.state.stateFiltered !== "D") {
                            // let's check the picture is not deleted
                            let picture = this.getPictureForId(previousPictureId);
                            while (previousPictureId !== null && ((this.state.states[previousPictureId] === undefined && picture.state === "D") || (this.state.states[previousPictureId] !== undefined && this.state.states[previousPictureId] === "D"))) {
                                previousPictureId = picture.previousPictureId;
                                picture = this.getPictureForId(previousPictureId);
                            }
                        }
                        nextPictureId = this.state.submission.files[i].pictures[j].nextPictureId;
                        if (this.state.stateFiltered !== "D") {
                            // let's check the picture is not deleted
                            let picture = this.getPictureForId(nextPictureId);
                            while (nextPictureId !== null && ((this.state.states[nextPictureId] === undefined && picture.state === "D") || (this.state.states[nextPictureId] !== undefined && this.state.states[nextPictureId] === "D"))) {
                                nextPictureId = picture.nextPictureId;
                                picture = this.getPictureForId(nextPictureId);
                            }
                        }
                    } else {
                        // locked
                        for (let i = 0; i < this.state.pictureSets.length; i++) {
                            for (let j = 0; j < this.state.pictureSets[i].length; j++) {
                                if (this.state.pictureSets[i][j] === pictureId) {
                                    set = this.state.pictureSets[i];
                                    if (j !== 0) {
                                        previousPictureId = this.state.pictureSets[i][j - 1];
                                    }
                                    if (j < this.state.pictureSets[i].length - 1) {
                                        nextPictureId = this.state.pictureSets[i][j + 1];
                                    }
                                }
                            }
                        }
                    }
                    let state = this.state.submission.files[i].pictures[j].state;
                    if (this.state.states[pictureId] !== undefined) state = this.state.states[pictureId];
                    this.setState({studentAssigned: studentAssigned, partAssigned: partAssigned});
                    return {
                        student: (studentAssigned[pictureId] === undefined ? this.state.submission.files[i].pictures[j].student : studentAssigned[pictureId]),
                        part: (partAssigned[pictureId] === undefined ? (this.state.submission.files[i].pictures[j].part).toString() : partAssigned[pictureId]),
                        previousPictureId: previousPictureId,
                        nextPictureId: nextPictureId,
                        set: set,
                        state: state,
                    }
                }
            }
        }
        this.setState({studentAssigned: studentAssigned, partAssigned: partAssigned});
    }

    // this is being called from the editor
    editorCallBack(operation, dataObject, callBack) {
        if (operation === "fetchPicture") {
            this.fetchPicture(dataObject.pictureId, dataObject.drawingId, callBack);
        }
        if (operation === "closeEditor") {
            this.savePicture(dataObject);
            this.showResults();
            this.setState({reopenCallBack: callBack});
        }
        if (operation === "nextPage" || operation === "previousPage" || operation === "specificPicture"
            || operation === "previousSet" || operation === "nextSet") {
            this.savePicture(dataObject);
            callBack("");
        }
        if (operation === "newLock") {
            this.savePicture(dataObject);
            this.getNewLock("callBack", callBack);
        }
        if (operation === "changeStudent") {
            const gradings = this.state.gradings;
            if (parseInt(dataObject.currentPart) === 0) {
                gradings[dataObject.oldStudent] = dataObject.values;
            } else {
                if (gradings[dataObject.oldStudent] === undefined) {
                    gradings[dataObject.oldStudent] = [];
                    for (let i = 0; i < dataObject.values.length; i++) {
                        if (i !== parseInt(dataObject.currentPart) - 1) {
                            gradings[dataObject.oldStudent][i] = null;
                        } else {
                            gradings[dataObject.oldStudent][i] = dataObject.values[i];
                        }
                    }
                } else {
                    gradings[dataObject.oldStudent][parseInt(dataObject.currentPart) - 1] = dataObject.values[parseInt(dataObject.currentPart) - 1];
                }
            }
            if (gradings[dataObject.newStudent] === undefined) {
                const values = this.getOldGrading(dataObject.newStudent);
                callBack(values);
            } else {
                const values = this.getOldGrading(dataObject.newStudent);
                for (let i = 0; i < values.length; i++) {
                    if (gradings[dataObject.newStudent][i] !== null) values[i] = gradings[dataObject.newStudent][i];
                }
                callBack(values);
            }
            this.setState({gradings: gradings});
        }
    }

    savePicture(dataObject) {
        const drawingIds = this.state.drawingIds;
        const drawings = this.state.drawings;
        const drawingsChanged = this.state.drawingsChanged;
        const states = this.state.states;
        const partAssigned = this.state.partAssigned;
        const studentAssigned = this.state.studentAssigned;
        if (dataObject.changed || drawingIds[dataObject.pictureId] !== 0) {
            drawingsChanged[dataObject.pictureId] = true;
            drawings[dataObject.pictureId] = dataObject.drawingData;
            drawingIds[dataObject.pictureId] = 0;
        }
        studentAssigned[dataObject.pictureId] = dataObject.student;
        partAssigned[dataObject.pictureId] = dataObject.part;
        states[dataObject.pictureId] = dataObject.state;
        this.setState({drawingIds: drawingIds, drawings: drawings, drawingsChanged: drawingsChanged, states: states,
                          partAssigned: partAssigned, studentAssigned: studentAssigned});
    }

    createPictureSet() {
        const pictureSet = [];
        this.state.submission.files.forEach(file => {
            file.pictures.forEach(picture => {
                if (this.state.stateFiltered === "D") {
                    pictureSet[pictureSet.length] = picture.pictureId;
                } else {
                    if ((this.state.states[picture.pictureId] === undefined && picture.state !== "D") || (this.state.states[picture.pictureId] !== undefined && this.state.states[picture.pictureId] !== "D")) {
                        pictureSet[pictureSet.length] = picture.pictureId;
                    }
                }
            });
        });
        return pictureSet;
    }

    fetchPicture(pictureId, drawingId, callBack) {
        if (pictureId === 0) {
            pictureId = this.state.expectedPictureId;
            drawingId = this.state.expectedDrawingId;
            const mode = this.state.mode;
            callBack({pictureId: pictureId, drawingId: drawingId, mode: mode});
        }
        if (this.state.pictures[pictureId] === undefined) {
            for (let i = 0; i < this.state.submission.files.length; i++) {
                for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                    if (pictureId === this.state.submission.files[i].pictures[j].pictureId) {
                        rest({
                                 path: '/api/user/picture/' + pictureId + "/"
                                       + this.state.submission.files[i].pictures[j].token, method: 'get'
                             },
                             (imageData) => {
                                 if (imageData.result === 1) {
                                     const picture = new Blob([this.binaryStringToArray(atob(imageData.image))],
                                                              {type: 'image/jpg'});
                                     const pictures = this.state.pictures;
                                     pictures[pictureId] = picture;
                                     this.setState({pictures: pictures});
                                 } else {
                                     callBack({abort: true});
                                     toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
                                     alert(Localization.Get("submissionCorrection.networkNotProcessed"));
                                 }
                             }, false, () => {
                                callBack({abort: true});
                                toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
                                alert(Localization.Get("submissionCorrection.networkNotProcessed"));
                            });
                    }
                }
            }
        }
        if (this.state.drawingIds[pictureId] !== drawingId) {
            rest({
                     path: '/api/teacher/drawing/' + pictureId + "/" + this.props.params.submissionToken
                           + "/" + drawingId, method: 'get'
                 },
                 (drawingData) => {
                     if (drawingData.result === 1) {
                         const drawings = this.state.drawings;
                         const drawingIds = this.state.drawingIds;
                         const drawingChanged = this.state.drawingsChanged;
                         drawings[pictureId] = drawingData.drawing;
                         drawingIds[pictureId] = drawingId;
                         drawingChanged[pictureId] = false;
                         this.setState({drawings: drawings, drawingIds: drawingIds, drawingChanged: drawingChanged});
                     } else {
                         callBack({abort: true});
                         toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
                         alert(Localization.Get("submissionCorrection.networkNotProcessed"));
                     }
                 }, false, () => {
                    callBack({abort: true});
                    toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
                    alert(Localization.Get("submissionCorrection.networkNotProcessed"));
                }
            );
        }
    }

    showResults() {
        this.setState({showResults: true});
    }

    removeScript(scriptToRemove) {
        const suspects = document.getElementsByTagName("script");
        for (let i = suspects.length - 1; i >= 0; i--){
            if (suspects[i] !== null && suspects[i].getAttribute("src") !== null
                && suspects[i].getAttribute("src") === scriptToRemove.getAttribute("src")) {
                suspects[i].parentNode.removeChild(suspects[i]);
            }
        }
    }

    binaryStringToArray(binary) {
        const array = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) array[i] = binary.charCodeAt(i);
        return array;
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            if (!this.state.submissionLoaded) {
                this.loadSubmissionData();
            }
        })();
        if (this.props.params.pictureId === "0") {
            this.setState({showResults: true});
        } else {
            (() => {
                this.triggerOpenPicture(parseInt(this.props.params.pictureId));
            })();
        }
        window.sessionStorage.setItem("lastNetworkCheck", "0");
        setTimeout(() => { this.checkSaveChanges(); }, 1000);
    }

    triggerOpenPicture(id) {
        if (!this.state.submissionLoaded) {
            setTimeout(() => {
                this.triggerOpenPicture(id);
            }, 100);
        } else {
            this.openPicture(parseInt(this.props.params.pictureId), parseInt(this.props.params.drawingId));
        }
    }

    makeGradingLines(submission) {
        const gradingLines = [];
        for (let i = 0; i < submission.parts.length; i++) {
            gradingLines[gradingLines.length] = {
                nameCz: submission.parts[i].nameCz,
                nameEn: submission.parts[i].nameEn,
                contributesTo: submission.parts[i].contributesTo,
                contributesToNameCz: submission.parts[i].contributesToNameCz,
                contributesToNameEn: submission.parts[i].contributesToNameEn,
                minimumValue: submission.parts[i].minimumValue,
                maximumValue: submission.parts[i].maximumValue,
            }
        }
        return gradingLines;
    }

    getPointTable(submission) {
        let result = "";
        for (let i = 0; i < submission.parts.length; i++) {
            const milestoneName = (i + 1) + ": " + (Localization.GetLanguage() === "CZ" ? submission.parts[i].nameCz : submission.parts[i].nameEn);
            result = result + "<tr id=\"partLine" + i + "\" key='" + i + "'>\n"
                     + "<td colSpan='3'>" + milestoneName + "</td><td>" + submission.parts[i].minimumValue
                     + "</td><td>" + submission.parts[i].maximumValue + "</td><td><input id=\"pointsGiven" + i + "\" type=\"number\" min=\""
                     + submission.parts[i].minimumValue + "\" max=\""
                     + submission.parts[i].maximumValue + "\" value=\"0\"></td>\n"
                     + "</tr>";
        }
        return result;
    }

    loadSubmissionData() {
        rest({path: '/api/teacher/submission/' + this.props.params.submissionId + "/" + this.props.params.submissionToken, method: 'get'},
             (submissionData) => {
                 if (submissionData.result === 1 && !this.state.submissionLoaded) {
                     submissionData.students = submissionData.students.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
                     // reset previous and next pictures - it happened that files are in a different order here
                     let lastPicture = null;
                     submissionData.files.forEach(file => {
                         file.pictures.forEach(picture => {
                             if (lastPicture === null) {
                                 picture.previousPictureId = null;
                             } else {
                                 picture.previousPictureId = lastPicture.pictureId;
                                 lastPicture.nextPictureId = picture.pictureId;
                             }
                             picture.nextPictureId = null;
                             lastPicture = picture;
                         })
                     })
                     this.setState({
                                       submission: submissionData,
                                       submissionLoaded: true,
                                       pictures: [], // it is pictures[pictureId]
                                       drawings: [], // it is drawings[pictureId]
                                       drawingIds: [], // it is drawings[pictureId]
                                       drawingsChanged: [], // true, if drawings[pictureId] changed
                                       studentAssigned: [], // it is studentAssigned[pictureId]
                                       partAssigned: [], // it is partAssigned[pictureId]
                                       states: [], // it is state[pictureId]
                                       gradings: [], // it is gradings[student]
                                       gradingLines: this.makeGradingLines(submissionData), // it is a property of part for which the file was submitted
                                       fileId: "-1",
                                   });
                     if (!this.state.lockUpdater) {
                         this.setState({lockUpdater: true});
                         (() => {this.regularLockUpdater()})();
                     }
                 }
             }, false, () => {});
    }

    loadEditor() {
        if (!this.state.editorLoaded) rest({path: '/api/user/editor', method: 'get'},
                                           (data) => {
                                               if (data.result === 1 && !this.state.editorLoaded) {
                                                   let html = data.html;
                                                   html = this.localizationReplace(html, "editor.maxPoints");
                                                   html = this.localizationReplace(html, "editor.pointsGiven");
                                                   html = this.localizationReplace(html, "editor.releasedForStudent");
                                                   html = this.localizationReplace(html, "editor.deleted");
                                                   html = this.localizationReplace(html, "editor.fontSize");
                                                   html = this.localizationReplace(html, "editor.close");
                                                   html = this.localizationReplace(html, "editor.newComment");
                                                   html = this.localizationReplace(html, "editor.note");
                                                   html = this.localizationReplace(html, "editor.insert");
                                                   html = this.localizationReplace(html, "editor.milestone");
                                                   html = this.localizationReplace(html, "editor.minPoints");
                                                   html = this.localizationReplace(html, "editor.touchToDraw");
                                                   html = this.localizationReplace(html, "editor.pictures");
                                                   html = this.localizationReplace(html, "editor.student");
                                                   html = this.localizationReplace(html, "editor.part");
                                                   html = this.localizationReplace(html, "editor.parts");
                                                   html = this.localizationReplace(html, "editor.points");
                                                   html = html.replace("editor.pointTable", this.getPointTable(this.state.submission));
                                                   window.editorHtml = html;
                                                   const script = document.createElement("script");
                                                   script.src = '/api/user/editor.js';
                                                   script.async = true;
                                                   this.removeScript(script);
                                                   document.body.appendChild(script);
                                                   this.injectCallBacks();
                                                   this.setState({editorLoaded: true});
                                               }
                                           }, false, () => {
                toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
                alert(Localization.Get("submissionCorrection.networkNotProcessed"));
            });
    }

    openPicture(pictureId, drawingId) {
        this.setState({expectedPictureId: pictureId, expectedDrawingId: drawingId, mode: "edit"});
        (() => {
            this.openPictureSub(pictureId, drawingId, "edit");
        })();
    }

    openPictureSub(pictureId, drawingId, mode) {
        if (this.state.mode !== mode) {
            setTimeout(() => {
                this.openPictureSub(pictureId, drawingId, mode)
            }, 10);
        } else {
            if (this.state.reopenCallBack === undefined) {
                this.loadEditor();
            } else {
                this.state.reopenCallBack({newPictureId: pictureId, newDrawingId: drawingId, mode: mode});
            }
        }
    }

    openLockedPicture(pictureId) {
        this.setState({expectedPictureId: pictureId, expectedDrawingId: 0, mode: "locked"});
        (() => {
            this.openPictureSub(pictureId, 0, "locked");
        })();
    }

    regularLockUpdater() {
        this.updateCurrentLocks();
        setTimeout(() => {this.regularLockUpdater();}, 60 * 1000 * 10);
    }

    updateCurrentLocks() {
        rest({path: '/api/teacher/lock/' + this.props.params.submissionId + "/" + this.props.params.submissionToken, method: 'get'}, (data) => {
            if (data.result === 1) {
                this.setState({locks: data.locks});
            }
        }, false, () => {});
    }

    getCurrentLocks() {
        this.setState({locking: true});
        rest({path: '/api/teacher/lock/' + this.props.params.submissionId + "/" + this.props.params.submissionToken, method: 'get'}, (data) => {
            this.setState({locking: false});
            if (data.result === 1) {
                toastSuccess(Localization.Get("submissionCorrection.locksUpdated"));
                this.setState({pictureSets: data.pictures, locks: data.locks});
            } else {
                toastError(Localization.Get("general.error"));
            }
        }, false, () => {
            this.setState({locking: false});
            toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
            alert(Localization.Get("submissionCorrection.networkNotProcessed"));
        });
    }

    deleteLock(pictureId) {
        this.setState({locking: true});
        rest({path: '/api/teacher/lock/' + this.props.params.submissionId + "/" + this.props.params.submissionToken + "/" + pictureId, method: 'delete'}, (data) => {
            this.setState({locking: false});
            if (data.result === 1) {
                toastSuccess(Localization.Get("submissionCorrection.locksUpdated"));
                this.setState({pictureSets: data.pictures, locks: data.locks});
            } else {
                toastSuccess(Localization.Get("general.error"));
            }
        }, false, () => {
                 this.setState({locking: false});
                 toastError(Localization.Get("submissionCorrection.networkNotConfirmed"));
                 alert(Localization.Get("submissionCorrection.networkNotConfirmed"));
        });
    }

    getNewLock(action, callBack) {
        this.setState({locking: true});
        rest({path: '/api/teacher/lock/' + this.props.params.submissionId + "/" + this.props.params.submissionToken, method: 'put',
                 body: {username: this.state.studentFiltered, part: this.state.partFiltered}}, (data) => {
            this.setState({locking: false});
            if (data.result === 0 || data.pictures.length === 0 || data.pictures[0].length === 0) {
                toastSuccess(Localization.Get("submissionCorrection.nothingToLock"));
                callBack("");
            } else {
                const locks = this.state.locks;
                for (let i = 0; i < data.locks.length; i++) {
                    locks[locks.length] = data.locks[i];
                }
                const pictureSets = this.state.pictureSets;
                pictureSets[pictureSets.length] = data.pictures[0];
                this.setState({pictureSets: pictureSets, locks: locks});
                if (action === "open") this.openLockedPicture(data.pictures[0][0]);
                if (action === "callBack") {
                    (() => {
                        callBack(data.pictures[0][0]);
                    })();
                }
            }
        }, false, () => {
            this.setState({locking: false});
            callBack("");
            toastError(Localization.Get("submissionCorrection.networkNotProcessed"));
            alert(Localization.Get("submissionCorrection.networkNotProcessed"));
        });
    }

    getFileList() {
        const files = [];
        for (let i = 0; i < this.state.submission.files.length; i++) {
            if (this.state.fileId === "-1" || this.state.fileId === i.toString()) {
                files[files.length] = {
                    id: i,
                    name: this.state.submission.files[i].filename,
                };
            }
        }
        window.sessionStorage.setItem("pictureCounter", "0");
        return files.map(file => {
            return <li key = {"file" + file} >
                <h4>{Localization.Get("submissionCorrection.file")} {file.name}</h4>
                <Table bordered hover>
                    <thead>
                    <tr key = {"fileTr" + file}>
                        <th>{Localization.Get("submissionCorrection.page")}</th>
                        <th>{Localization.Get("submissionCorrection.picture")}</th>
                        <th>{Localization.Get("submissionCorrection.student")}</th>
                        <th>{Localization.Get("submissionCorrection.part")}</th>
                        <th>{Localization.Get("submissionCorrection.state")}</th>
                        <th>{Localization.Get("submissionCorrection.drawings")}</th>
                        <th>{Localization.Get("submissionCorrection.correction")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getPictureList(file.id)}
                    </tbody>
                </Table>
            </li>
        });
    }

    getPictureList(fileId) {
        const pictures = [];
        for (let j = 0; j < this.state.submission.files[fileId].pictures.length; j++) {
            const picture = this.state.submission.files[fileId].pictures[j];
            let originalStudent = picture.student;
            if (originalStudent === "" || originalStudent === null) originalStudent = "?";
            const originalPart = picture.part;
            const assignedStudent = this.state.studentAssigned[picture.pictureId];
            const assignedPart = this.state.partAssigned[picture.pictureId];
            const newState = this.state.states[picture.pictureId];
            let newStudent = (assignedStudent === undefined ? originalStudent : assignedStudent);
            if (newStudent === "" || newStudent === "?") newStudent = "?";
            const newPart = (assignedPart === undefined ? originalPart : assignedPart);
            const correctionData = this.state.drawings[picture.pictureId];
            let drawings = [];
            if (picture.drawings !== undefined && picture.drawings !== null && picture.drawings.length > 1) drawings =
                JSON.parse(picture.drawings);
            const studentSelected = ((this.state.studentFiltered === "")
                                     || (this.state.studentFiltered === assignedStudent) || (this.state.studentFiltered === originalStudent));
            const partSelected = ((this.state.partFiltered === "")
                                  || (parseInt(this.state.partFiltered) === parseInt(assignedPart)) || (parseInt(this.state.partFiltered) === parseInt(originalPart)));
            const stateSelected = ((this.state.stateFiltered === "" && ((newState === undefined && picture.state !== "D") || (newState !== undefined && newState !== "D")))
                                   || (this.state.stateFiltered === newState) || (newState === undefined && this.state.stateFiltered === picture.state));
            if (studentSelected && partSelected && stateSelected) {
                let lockedByUser = false;
                let pictureSet = [];
                for (let i = 0; i < this.state.pictureSets.length; i++) {
                    for (let k = 0; k < this.state.pictureSets[i].length; k++) {
                        if (this.state.pictureSets[i][k] === picture.pictureId) {
                            lockedByUser = true;
                            pictureSet = this.state.pictureSets[i];
                        }
                    }
                }
                let lockedFor = "";
                for (let i = 0; i < this.state.locks.length; i++) {
                    if (this.state.locks[i].pictureId === picture.pictureId) {
                        lockedFor = this.state.locks[i].name;
                    }
                }
                let stateCode = "";
                if (this.state.states[picture.pictureId] === undefined) {
                    stateCode = picture.state;
                } else {
                    stateCode = this.state.states[picture.pictureId];
                }
                let state = "";
                if (stateCode === "P") state = Localization.Get("submissionCorrection.notCorrected");
                if (stateCode === "C") state = Localization.Get("submissionCorrection.corrected");
                if (stateCode === "R") state = Localization.Get("submissionCorrection.releasedForStudent");
                if (stateCode === "D") state = Localization.Get("submissionCorrection.deleted");
                let shownToStudent = "";
                if (picture.shownToStudent === true) shownToStudent = "(" + Localization.Get("submissionCorrection.shownToStudent") + ")";
                pictures[pictures.length] = {
                    id: picture.pictureId,
                    filename: this.state.submission.files[fileId].filename,
                    page: picture.page + " (" + picture.pictureId + ")",
                    originalStudent: originalStudent,
                    newStudent: newStudent,
                    originalPart: originalPart,
                    newPart: newPart,
                    drawings: drawings,
                    correction: (correctionData === undefined ? "" : "action"),
                    token: picture.token,
                    state: state,
                    lockedByUser: lockedByUser,
                    pictureSet: pictureSet,
                    lockedFor: lockedFor,
                    originalState: picture.state,
                    shownToStudent: shownToStudent,
                }
            }
        }
        return pictures.map(picture => {
            if (picture.originalState === "R") {
                const pictureCounter = parseInt(window.sessionStorage.getItem("pictureCounter"));
                window.sessionStorage.setItem("picturePath" + pictureCounter, "/submissionview/" + picture.id + "/" + picture.token);
                window.sessionStorage.setItem("pictureCounter", (pictureCounter + 1).toString());
            }
            return <tr key={picture.id}>
                <td>{picture.page}</td>
                <td>
                    <a href={Constants.GetServer() + "/api/user/picturedownload/" + picture.id +"/" + picture.token} target="_blank"><div id={picture.id}></div></a>
                    {this.getCachedImage(picture.id, picture.token)}
                    {picture.originalState === "R" ? <div><br/>
                        <Button size="sm" color="info" onClick={() => {window.open("/submissionview/" + picture.id + "/" + picture.token);}}>
                            {Localization.Get("submissionCorrection.studentView")}</Button>
                    </div> : ""}
                </td>
                <td>{picture.originalStudent} &#10132; {picture.newStudent}<br/> {picture.shownToStudent}</td>
                <td>{picture.originalPart} &#10132; {picture.newPart}</td>
                <td>{picture.state}
                    {picture.lockedFor !== "" ? <div><br/><br/>
                        {Localization.Get("submissionCorrection.lockedFor") + ": " + picture.lockedFor}
                        <br/><br/>
                        {this.state.locking ? <LoadingSpinner /> :
                        <Button color="danger" onClick={() => {
                            this.deleteLock(picture.id);
                        }}>{Localization.Get("submissionCorrection.deleteLock")}</Button>}
                    </div> : ""}
                </td>
                <td>
                    <ul>
                    {this.getDrawings(picture)}
                    </ul>
                </td>
                <td>{picture.correction !== "action" ?
                     <Button color="primary" onClick={() => {
                         this.openPicture(picture.id, 0);
                     }}>{Localization.Get("submissionCorrection.loadPicture")}</Button>
                                                     :
                     <Button color="success" onClick={() => {
                         this.openPicture(picture.id, 0);
                     }}>{Localization.Get("submissionCorrection.showPicture")}</Button>}
                    {picture.lockedByUser ? <div><br/><Button color="warning" onClick={() => {
                        this.openLockedPicture(picture.id);
                    }}>{Localization.Get("submissionCorrection.showLockedPicture")}</Button></div> : "" }
                    <br/>
                </td>
            </tr>
        });
    }

    getOldGrading(username) {
        const oldGrading = [];
        for (let i = 0; i < this.state.submission.parts.length; i++) {
            oldGrading[i] = "";
        }
        for (let i = 0; i < this.state.submission.gradings.length; i++) {
            const grading = this.state.submission.gradings[i];
            if (grading.student === username) {
                if (grading.points === null) {
                    oldGrading[grading.part - 1] = "";
                } else {
                    oldGrading[grading.part - 1] = grading.points.toString();
                }
            }
        }
        return oldGrading;
    }

    calculateResults() {
        let canSave = false;

        const results = [];

        const assignedStudents = new Set();
        for (let i = 0; i < this.state.submission.files.length; i++) {
            for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                const pictureId = this.state.submission.files[i].pictures[j].pictureId;
                if (this.state.studentAssigned[pictureId] !== undefined) {
                    assignedStudents.add(this.state.studentAssigned[pictureId]);
                    if (this.state.studentAssigned[pictureId] !== this.state.submission.files[i].pictures[j].student) canSave = true;
                }
                assignedStudents.add(this.state.submission.files[i].pictures[j].student);
                if (this.state.partAssigned[pictureId] !== undefined && this.state.partAssigned[pictureId] !== this.state.submission.files[i].pictures[j].part) canSave = true;
                if (this.state.states[pictureId] !== undefined && this.state.states[pictureId] !== this.state.submission.files[i].pictures[j].state) canSave = true;
            }
        }
        for (let k = 0; k < this.state.submission.students.length; k++) {
            const username = this.state.submission.students[k].username;
            if (assignedStudents.has(username)) {
                const oldGrading = this.getOldGrading(username);
                if (this.state.gradings[username] !== undefined) {
                    // and there is a picture that is corrected for this student
                    let pictureFound = false;
                    for (let i = 0; i < this.state.submission.files.length && !pictureFound; i++) {
                        for (let j = 0; j < this.state.submission.files[i].pictures.length && !pictureFound; j++) {
                            const pictureId2 = this.state.submission.files[i].pictures[j].pictureId;
                            if (this.state.studentAssigned[pictureId2] === username ||
                                (this.state.studentAssigned[pictureId2] === undefined && this.state.submission.files[i].pictures[j].student === username && this.state.drawings[pictureId2] !== undefined)) {
                                pictureFound = true;
                            }
                        }
                    }
                    if (!pictureFound) {
                        this.removeResults(username);
                        results[results.length] = {
                            username: this.state.submission.students[k].username,
                            name: this.state.submission.students[k].name,
                            newGrading: [],
                            oldGrading: oldGrading,
                            canDeleteMe: true,
                        }
                    } else {
                        canSave = true;
                        results[results.length] = {
                            username: this.state.submission.students[k].username,
                            name: this.state.submission.students[k].name,
                            newGrading: this.state.gradings[this.state.submission.students[k].username],
                            oldGrading: oldGrading,
                            canDeleteMe: true,
                        }
                    }
                } else {
                    let canDeleteMe = false;
                    for (let i = 0; i < this.state.submission.files.length; i++) {
                        for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                            const pictureId = this.state.submission.files[i].pictures[j].pictureId;

                            if (this.state.studentAssigned[pictureId] === username || (this.state.studentAssigned[pictureId] === undefined && this.state.submission.files[i].pictures[j].student === username)) {
                                if (this.state.studentAssigned[pictureId] !== undefined || this.state.partAssigned[pictureId] !== undefined || this.state.drawingsChanged[pictureId] !== undefined) {
                                    canSave = true;
                                    canDeleteMe = true;
                                }
                            }

                        }
                    }
                    results[results.length] = {
                        username: this.state.submission.students[k].username,
                        name: this.state.submission.students[k].name,
                        newGrading: [],
                        oldGrading: oldGrading,
                        canDeleteMe: canDeleteMe,
                    }
                }
            }
        }
        return {results: results, canSave: canSave};
    }

    getResultList() {
        return this.calculateResults().results.map(result => {
            if (result.newGrading.length === 0) {
                return <tr key={result.username}>
                    <td>{result.name}</td>
                    <td><table>{this.getNewValues(result.oldGrading)}<td>&nbsp;</td><td></td><td></td>{this.getGrading(result.username, result.oldGrading, false)}</table></td>
                    <td></td>
                    <td>
                        { result.canDeleteMe ?
                          <Button color="danger" onClick={() => {this.removeResults(result.username, result.username);}}>{Localization.Get("submissionCorrection.deleteChanges")}</Button>
                            : ""
                        }
                    </td>
                </tr>
            } else {
                return <tr key={result.username}>
                    <td>{result.name}</td>
                    <td><table>{this.getNewValues(result.oldGrading)}<td>&nbsp;</td><td></td><td></td>{this.getGrading(result.username, result.oldGrading, false)}</table></td>
                    <td><table>{this.getNewValues(result.newGrading, result.oldGrading)}<td>&nbsp;</td><td></td><td></td>{this.getGrading(result.username, result.newGrading, true)}</table></td>
                    <td>
                        <Button color="danger" onClick={() => {this.removeResults(result.username, result.username);}}>{Localization.Get("submissionCorrection.deleteChanges")}</Button>
                    </td>
                </tr>
            }
        });
    }

    getLines(grading) {
        const lines = [];
        for (let i = 0; i < grading.length && i < this.state.gradingLines.length; i++) {
            lines[lines.length] = {
                key: i,
                caption: (i + 1) + ": " + (Localization.GetLanguage() === "CZ" ? this.state.gradingLines[i].nameCz : this.state.gradingLines[i].nameEn),
                value: grading[i],
                contributesTo: this.state.gradingLines[i].contributesTo,
                contributesToName: Localization.GetLanguage() === "CZ" ? this.state.gradingLines[i].contributesToNameCz : this.state.gradingLines[i].contributesToNameEn,
                maximumValue: this.state.gradingLines[i].maximumValue,
                minimumValue: this.state.gradingLines[i].minimumValue,
            }
        }
        return lines;
    }

    removeResults(username) {
        const gradings = this.state.gradings;
        gradings[username] = undefined;
        for (let i = 0; i < this.state.submission.files.length; i++) {
            for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                const pictureId = this.state.submission.files[i].pictures[j].pictureId;
                if (this.state.studentAssigned[pictureId] === username || (this.state.studentAssigned[pictureId] === undefined && this.state.submission.files[i].pictures[j].student === username)) {
                    const studentAssigned = this.state.studentAssigned;
                    const partAssigned  = this.state.partAssigned;
                    const drawings = this.state.drawings;
                    const drawingsChanged = this.state.drawingsChanged;
                    const drawingIds = this.state.drawingIds;
                    const pictures = this.state.pictures;
                    const states = this.state.states;

                    studentAssigned[pictureId] = undefined;
                    partAssigned[pictureId] = undefined;
                    drawings[pictureId] = undefined;
                    drawingsChanged[pictureId] = undefined;
                    drawingIds[pictureId] = undefined;
                    pictures[pictureId] = undefined;
                    states[pictureId] = undefined;

                    this.setState({studentAssigned: studentAssigned, partAssigned: partAssigned, drawings: drawings,
                                      drawingsChanged: drawingsChanged, drawingIds: drawingIds, pictures: pictures, states: states})
                }
            }
        }
        this.setState({gradings: gradings});
    }

    getGrading(username, grading, editable) {
        const lines = this.getLines(grading);
        if (!editable) {
            return lines.map(line => {
                return <tr key={lines.key}><td>{line.caption}:</td><td><b>{line.value}</b></td><td>(&#10132; {line.contributesToName})</td></tr>
            });
        } else {
            return lines.map(line => {
                if (line.value === null) {
                    return <tr key={lines.key}><td>{line.caption}:</td>
                        <td><input type="number" value="" disabled
                                   min={line.minimumValue} max={line.maximumValue} /></td><td>({line.minimumValue} &lt;= x &lt;= {line.maximumValue})</td></tr>
                } else {
                    return <tr key={lines.key}><td>{line.caption}:</td>
                        <td><input type="number" value={line.value} onChange={(e) => {this.setGradingValue(username, line.key, e.target.value);}}
                                   min={line.minimumValue} max={line.maximumValue} /></td><td>({line.minimumValue} &lt;= x &lt;= {line.maximumValue})</td></tr>
                }
            });
        }
    }

    setGradingValue(username, part, value) {
        if (value.trim() === "") value = "";
        const gradings = this.state.gradings;
        gradings[username][part] = value;
        this.setState({gradings: gradings});
    }

    getContributions(grading, oldGrading) {
        const lines = this.getLines(grading);
        const oldLines = ((oldGrading === undefined) ? undefined : this.getLines(oldGrading));
        const contributions = [];
        for (let i = 0; i < lines.length; i++) {
            let found = false;
            for (let j = 0; j < contributions.length; j++) {
                if (lines[i].contributesTo === contributions[j].identifier) {
                    found = true;
                    if (oldLines !== undefined && isNaN(parseFloat(lines[i].value)) && !isNaN(parseFloat(oldLines[i].value))) {
                        contributions[j].value = contributions[j].value + parseFloat(oldLines[i].value);
                    } else {
                        contributions[j].value = contributions[j].value + (isNaN(parseFloat(lines[i].value)) ? 0 : parseFloat(lines[i].value));
                    }
                }
            }
            if (!found) {
                if (oldLines !== undefined && isNaN(parseFloat(lines[i].value)) && !isNaN(parseFloat(oldLines[i].value))) {
                    contributions[contributions.length] = {
                        identifier: lines[i].contributesTo,
                        name: lines[i].contributesToName,
                        value: parseFloat(oldLines[i].value),
                    }
                } else {
                    contributions[contributions.length] = {
                        identifier: lines[i].contributesTo,
                        name: lines[i].contributesToName,
                        value: isNaN(parseFloat(lines[i].value)) ? 0 : parseFloat(lines[i].value),
                    }
                }
            }
        }
        return contributions;
    }

    getNewValues(grading, oldGrading) {
        const contributions = this.getContributions(grading, oldGrading);
        return contributions.map(contribution => {
            return <tr key={contribution.identifier}><td>{contribution.name}:</td><td></td><td><b>{contribution.value}</b></td></tr>
        })
    }

    handleFilenameList = (e) => {
        this.setState({fileId: e.target.value});
    }

    handleStudentList = (e) => {
        this.setState({studentFiltered: e.target.value});
    }

    handlePartList = (e) => {
        this.setState({partFiltered: e.target.value});
    }

    handleStateList = (e) => {
        this.setState({stateFiltered: e.target.value});
    }

    checkSaveChanges() {
        const lastNetworkCheck = Number.parseInt(window.sessionStorage.getItem("lastNetworkCheck"));
        if (lastNetworkCheck > System.currentTimeMillis() - 60000) return;
        window.sessionStorage.setItem("lastNetworkCheck", System.currentTimeMillis().toString());

        const correctionsDto = {
            gradingDtos: [],
            drawingDtos: [],
            pictureSubmissionDtos: [],
        }

        rest({
                 path: '/api/teacher/savecorrections/' + this.props.params.submissionId + '/' + this.props.params.submissionToken,
                 method: 'post', body: correctionsDto
             },
             (data) => {
                 if (data.result !== 1) {
                     toastError(Localization.Get("submissionCorrection.networkError"));
                     alert(Localization.Get("submissionCorrection.networkError"));
                 }
                 setTimeout(() => { this.checkSaveChanges(); }, 60000);
             }, false, () => {
                toastError(Localization.Get("submissionCorrection.networkError"));
                alert(Localization.Get("submissionCorrection.networkError"));
                setTimeout(() => { this.checkSaveChanges(); }, 60000);
        });
    }

    saveChanges() {
        const correctionsDto = {
            gradingDtos: [],
            drawingDtos: [],
            pictureSubmissionDtos: [],
        }
        let studentsChanged = "";
        for (let i = 0; i < this.state.submission.students.length; i++) {
            const student = this.state.submission.students[i].username;
            if (this.state.gradings[student] !== undefined) {
                for (let j = 0; j < this.state.gradings[student].length; j++) {
                    if (this.state.gradings[student][j] !== null) {
                        if (!studentsChanged.includes(student)) studentsChanged = studentsChanged + student + "|";
                        const gradingDto = {
                            student: student,
                            part: j + 1,
                            points: isNaN(parseFloat(this.state.gradings[student][j])) ? null : parseFloat(this.state.gradings[student][j]),
                        }
                        correctionsDto.gradingDtos[correctionsDto.gradingDtos.length] = gradingDto;
                    }
                }
            }
        }
        for (let i = 0; i < this.state.submission.files.length; i++) {
            for (let j = 0; j < this.state.submission.files[i].pictures.length; j++) {
                const pictureId = this.state.submission.files[i].pictures[j].pictureId;
                if (this.state.partAssigned[pictureId] !== undefined || this.state.studentAssigned[pictureId] !== undefined || this.state.states[pictureId] !== undefined) {
                    correctionsDto.pictureSubmissionDtos[correctionsDto.pictureSubmissionDtos.length] = {
                        pictureId: pictureId,
                        student: this.state.studentAssigned[pictureId] !== undefined ? this.state.studentAssigned[pictureId] : null,
                        part: this.state.partAssigned[pictureId] !== undefined ? parseInt(this.state.partAssigned[pictureId]) : null,
                        state: this.state.states[pictureId] !== undefined ? this.state.states[pictureId] : this.state.submission.files[i].pictures[j].state,
                    }
                    if (this.state.drawingsChanged[pictureId] !== undefined && this.state.drawingsChanged[pictureId] && this.state.drawings[pictureId] !== undefined) {
                        correctionsDto.drawingDtos[correctionsDto.drawingDtos.length] = {
                            pictureId: pictureId,
                            drawing: this.state.drawings[pictureId],
                        }
                    }
                }
            }
        }
        const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
        for (let i = 0; i < sequenceListCount; i++) {
            if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                window.sessionStorage.setItem("submissionListChanged" + i, window.sessionStorage.getItem("submissionListChanged" + i) + studentsChanged);
            }
        }
        rest({
                 path: '/api/teacher/savecorrections/' + this.props.params.submissionId + '/' + this.props.params.submissionToken,
                 method: 'post', body: correctionsDto
             },
             (data) => {
                 if (data.result === 1) {
                     this.setState({submissionLoaded: false, locks: [], pictureSets: []});
                     this.loadSubmissionData();
                     // https://bosctechlabs.com/scroll-to-an-element-in-react/ 17.2.2023
                     setTimeout(() => {
                         this.pageBottom.current.scrollIntoView();
                     }, 2500);
                     toastSuccess(Localization.Get("submissionCorrection.saveCorrectionSuccess"));
                 } else {
                     toastError(Localization.Get("submissionCorrection.saveCorrectionError"));
                 }
             }, false, () => {
                toastError(Localization.Get("submissionCorrection.networkNotConfirmed"));
                alert(Localization.Get("submissionCorrection.networkNotConfirmed"));
             });
    }

    getPreviousSubmission() {
        const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
        let previousSubmissionId = null;
        let previousSubmissionToken = null;
        for (let i = 0; i < sequenceListCount; i++) {
            if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                if (previousSubmissionId == null) return null;
                return {id: previousSubmissionId, token: previousSubmissionToken};
            } else {
                previousSubmissionId = window.sessionStorage.getItem("submissionListId" + i);
                previousSubmissionToken = window.sessionStorage.getItem("submissionListToken" + i);
            }
        }
        return null;
    }

    getNextSubmission() {
        const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
        for (let i = 0; i < sequenceListCount; i++) {
            if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                if (i < sequenceListCount - 1) return {id: window.sessionStorage.getItem("submissionListId" + (i + 1)),
                    token: window.sessionStorage.getItem("submissionListToken" + (i + 1))};
            }
        }
        return null;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        if (this.state.showResults && this.state.submissionLoaded) {
            const files = [];
            files[0] = {
                id: -1,
                filename: "",
            }
            for (let i = 0; i < this.state.submission.files.length; i++) {
                files[files.length] = {
                    id: i,
                    filename: this.state.submission.files[i].filename,
                }
            }
            const filenameList = Array.from(files).map(file => {
                return <option key={file.id} value={file.id}>{file.filename}</option>
            });

            const students = [];
            students[0] = {id: "", value: ""};
            students[1] = {id: "?", value: "?"};
            for (let i = 0; i < this.state.submission.students.length; i++) {
                students[students.length] = {id: this.state.submission.students[i].username, value: this.state.submission.students[i].name};
            }
            const studentList = students.map(student => {
                return <option key={student.id} value={student.id}>{student.value}</option>
            });

            const parts = [];
            parts[0] = {id: "", value: ""};
            parts[1] = {id: 0, value: "0: " + Localization.Get("submissionCorrection.part0")};
            for (let i = 0; i < this.state.submission.parts.length; i++) {
                if (Localization.GetLanguage() === "CZ") {
                    parts[parts.length] = {id: i + 1, value: (i + 1) + ": " + this.state.submission.parts[i].nameCz};
                } else {
                    parts[parts.length] = {id: i + 1, value: (i + 1) + ": " + this.state.submission.parts[i].nameEn};
                }
            }
            const partList = parts.map(part => {
                return <option key={part.id} value={part.id}>{part.value}</option>
            });
            let partFiltered = this.state.partFiltered;
            if (this.state.defaultPartFiltered === "?") {
                if (parts.length > 0 && this.state.defaultPartFiltered === "?") {
                    const preferredPart = window.sessionStorage.getItem("preferredPart");
                    parts.forEach(part => {
                        if (part.id === preferredPart || part.id.toString() === preferredPart) {
                            partFiltered = part.id;
                            this.setState({partFiltered: partFiltered});
                        }
                    })
                    this.setState({defaultPartFiltered: ""});
                }
            }

            const states =[];
            states[0] = {id: "", value: ""};
            states[1] = {id: "P", value: Localization.Get("submissionCorrection.notCorrected")};
            states[2] = {id: "C", value: Localization.Get("submissionCorrection.corrected")};
            states[3] = {id: "R", value: Localization.Get("submissionCorrection.releasedForStudent")};
            states[4] = {id: "D", value: Localization.Get("submissionCorrection.deleted")};
            const stateList = states.map(state => {
                return <option key={state.id} value={state.id}>{state.value}</option>
            });

            return (
                <Container>
                    <Navigation /><br/>
                    <Row>
                        <Col md={12}>
                            {this.state.isLanguageInitialized ? <div>
                                <h1>{Localization.Get("main.title")}</h1>
                                <h2>{Localization.Get("submissionCorrection.title")}</h2><br/>
                                <h3>{Localization.Get("submissionCorrection.submissions") + " / " + this.state.submission.semesterCode
                                     + " / " + this.state.submission.courseCode + " / " + this.state.submission.parallelCode + " / " + this.props.params.submissionId}</h3><br/>
                            </div> : <LoadingSpinner />}
                            {this.state.submissionLoaded ? <div>
                                <h4>{Localization.Get("submissionCorrection.pictureList")}</h4>
                                <Row>
                                    <Col md={12}>
                                        <strong>{Localization.Get("submissionCorrection.fileList")}</strong>
                                        { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                            <Input type="select" onChange={this.handleFilenameList} value={this.state.filename} >
                                                {filenameList}
                                            </Input> </div>
                                        }
                                    </Col>
                                </Row><br/>
                                <Row>
                                    <Col md={3}>
                                        <strong>{Localization.Get("submissionCorrection.studentList")}</strong>
                                        { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                            <Input type="select" onChange={this.handleStudentList} value={this.state.studentFiltered} >
                                                {studentList}
                                            </Input> </div>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        <strong>{Localization.Get("submissionCorrection.partList")}</strong>
                                        { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                            <Input type="select" onChange={this.handlePartList} value={partFiltered} >
                                                {partList}
                                            </Input> </div>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        <strong>{Localization.Get("submissionCorrection.correctionState")}</strong>
                                        { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                            <Input type="select" onChange={this.handleStateList} value={this.state.stateFiltered} >
                                                {stateList}
                                            </Input> </div>
                                        }
                                    </Col>
                                    <Col md={3}>
                                        <strong>{Localization.Get("submissionCorrection.openLocked")}</strong><br/>
                                        { this.state.studentFiltered !== "?" && partFiltered !== "" ? <div>
                                            {this.state.locking ? <LoadingSpinner /> : <Button color="warning" onClick={() => {this.getNewLock("open", (result) => {});}}>{Localization.Get("submissionCorrection.getNewLock")}</Button>}
                                            <br/>&nbsp;</div> : "" }
                                        {this.state.locking ? <LoadingSpinner /> : <div>
                                            <Button color="success" onClick={() => {this.getCurrentLocks();}}>{Localization.Get("submissionCorrection.updateLocks")}</Button><br/>&nbsp;<br/>
                                            <Button color="danger" onClick={() => {this.deleteLock(0);}}>{Localization.Get("submissionCorrection.deleteMyLocks")}</Button>
                                        </div>}
                                    </Col>
                                </Row>
                                <br/>
                                <ul>
                                    {this.getFileList()}
                                </ul>
                                <br/><br/>
                                <h4>{Localization.Get("submissionCorrection.resultList")}</h4>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("submissionCorrection.student")}</th>
                                        <th>{Localization.Get("submissionCorrection.currentGrading")}</th>
                                        <th>{Localization.Get("submissionCorrection.createdGrading")}</th>
                                        <th>{Localization.Get("submissionCorrection.operations")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getResultList()}
                                    </tbody>
                                </Table>
                                <div ref={this.pageBottom}></div>
                                {this.calculateResults().canSave ? <Button color="success" onClick={() => {this.saveChanges();}}>{Localization.Get("submissionCorrection.saveChanges")}</Button> :
                                 <div>
                                     <Button color="primary" tag={Link} to={"/teachersubmissions/" + this.state.submission.semesterCode + "/" + this.state.submission.courseCode + "/" + this.state.submission.parallelCode + "/" + this.state.submission.parallelId}>
                                         {Localization.Get("submissionCorrection.goBack")}</Button>
                                     &nbsp;
                                     <Button color="warning" tag={Link} to={"/submissiongrades/" + this.props.params.submissionId + "/" + this.props.params.submissionToken}>
                                         {Localization.Get("submissionCorrection.goGrades")}</Button>&nbsp;
                                     {this.getPreviousSubmission() !== null ?
                                      <Button color="primary" onClick={() => {
                                          window.sessionStorage.setItem("preferredPart", this.state.partFiltered);
                                          window.location = "/submissioncorrection/" + this.getPreviousSubmission().id + "/" + this.getPreviousSubmission().token + "/0/0";}}>
                                          &#10094; {Localization.Get("submissionCorrection.previousSubmission")}</Button>
                                                                            : ""}
                                     {this.getPreviousSubmission() !== null ? " " : ""}
                                     {this.getNextSubmission() !== null ?
                                      <Button color="primary" onClick={() => {
                                          window.sessionStorage.setItem("preferredPart", this.state.partFiltered);
                                          window.location = "/submissioncorrection/" + this.getNextSubmission().id + "/" + this.getNextSubmission().token + "/0/0";}}>
                                          {Localization.Get("submissionCorrection.nextSubmission")} &#10095;</Button>
                                                                        : ""}
                                 </div> }
                                <br/><br/>
                            </div> : <LoadingSpinner />}
                        </Col>
                    </Row>
                    <Footer />
                    <ToastContainer/>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Navigation /><br/>
                    <Row>
                        <Col md={12}>
                            {this.state.isLanguageInitialized ? <div>
                                <h1>{Localization.Get("main.title")}</h1>
                                <h2>{Localization.Get("submissionCorrection.title")}</h2>
                            </div> : <LoadingSpinner />}
                            {this.state.submissionLoaded ? <div></div> : <LoadingSpinner />}
                        </Col>
                    </Row>
                    <Footer />
                    <ToastContainer/>
                </Container>
            );
        }

    }

}

export default SubmissionCorrection;