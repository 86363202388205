import React, {Component} from 'react';
import Localization from "../public/Localization";
import Constants from "../user/Constants";
import '../App.css';
import {Button, Input} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-GB";
import {toastError, toastSuccess} from "../public/Toaster";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Footer from "../public/Footer";

class TeacherSubmissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            milestones: [],
            milestonesLoaded: false,
            nameCz: " / ",
            nameEn: " / ",
            milestone: "",
            state: "",
            submittedBy: "",
            submittedFor: "",
            savingNewDeadline: [], // savingNewDeadline[submissionId]
            newDeadlines: [], // newDeadline[submissionId]
            refresh: 0,
            conversionState: "",
        }
        registerLocale("cs", cs);
        registerLocale("en-GB", en);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/teacher/submission/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                     + "/" + this.props.params.parallelId, method: 'get'},
                (data) => {
                    this.getSubmissions(data);
                });
        })();
    }

    getSubmissions(data) {
        if (data.result === 1) {
            data.milestoneSubmissions.forEach(milestone => {
                milestone.submissions.forEach(submission => {
                    submission.newDeadline = Date.parse(submission.newDeadline);
                })
            })
            let conversionState = "";
            if (data.converting === true) {
                conversionState = data.convertingSubmissionId + " / " + data.convertingPictureId + ", " + data.convertingTimeStamp;
            }
            this.setState({milestonesLoaded: true, milestones: data.milestoneSubmissions, descriptionCz: " - " + data.descriptionCz, descriptionEn: " - " + data.descriptionEn,
                              conversionState: conversionState});
        }
    }

    setReleased(submissionId) {
        this.setState({milestonesLoaded: false});
        rest({path: '/api/teacher/submission/release/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                    + "/" + this.props.params.parallelId + "/" + submissionId, method: 'put'},
             (data) => {
                 this.getSubmissions(data);
             });
    }

    setDeleted(submissionId) {
        this.setState({milestonesLoaded: false});
        rest({path: '/api/teacher/submission/delete/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                    + "/" + this.props.params.parallelId + "/" + submissionId, method: 'put'},
             (data) => {
                 this.getSubmissions(data);
             });
    }

    setUndeleted(submissionId) {
        this.setState({milestonesLoaded: false});
        rest({path: '/api/teacher/submission/undelete/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                    + "/" + this.props.params.parallelId + "/" + submissionId, method: 'put'},
             (data) => {
                 this.getSubmissions(data);
             });
    }

    getSubmissionState(state) {
        if (state === "Q") return Localization.Get("submissionState.requested");
        if (state === "S") return Localization.Get("submissionState.submitted");
        if (state === "N") return Localization.Get("submissionState.converted");
        if (state === "F") return Localization.Get("submissionState.conversionFailed");
        if (state === "C") return Localization.Get("submissionState.corrected");
        if (state === "R") return Localization.Get("submissionState.releasedForStudent");
        if (state === "D") return Localization.Get("submissionState.deleted");
    }

    getFilenameList(files) {
        const filelist = files.map(file => {
            file.link = Constants.GetServer() + "/api/user/submissionfiledownload/" + file.fileId + "/" + file.token + "";
            return <li key = {file.fileId}>
                    <a href={file.link} target="_blank">{file.filename}</a>
            </li>
        });
        return filelist;
    }

    handleSemesterChange = (e) => {
        this.setState({semester: e.target.value});
        this.setState({coursesLoaded: false});
        this.loadCourses(e.target.value);
    }

    handleMilestoneList = (e) => {
        this.setState({milestone: e.target.value});
    }

    handleStateList = (e) => {
        this.setState({state: e.target.value});
    }

    handleSubmittedByList = (e) => {
        this.setState({submittedBy: e.target.value});
    }

    handleSubmittedForList = (e) => {
        this.setState({submittedFor: e.target.value});
    }

    getCurrentDeadline(submissionId) {
        const milestones = this.state.milestones;
        for (let i = 0; i < milestones.length; i++) {
            for (let j = 0; j < milestones[i].submissions.length; j++) {
                if (milestones[i].submissions[j].id === submissionId) {
                    return milestones[i].submissions[j].newDeadline;
                }
            }
        }
    }

    getHour(submissionId, newDeadline) {
        try {
            if (newDeadline === null || isNaN(newDeadline)) {
                return "";
            } else {
                return <Input type="select" onChange={(e) => this.setNewDeadlineHour(submissionId, e.target.value)} value={(new Date(newDeadline)).getHours()} >
                    [<option value={0}>00</option>, <option value={1}>01</option>, <option value={2}>02</option>, <option value={3}>03</option>,
                    <option value={4}>04</option>, <option value={5}>05</option>, <option value={6}>06</option>, <option value={7}>07</option>,
                    <option value={8}>08</option>, <option value={9}>09</option>, <option value={10}>10</option>, <option value={11}>11</option>,
                    <option value={12}>12</option>, <option value={13}>13</option>, <option value={14}>14</option>, <option value={15}>15</option>,
                    <option value={16}>16</option>, <option value={17}>17</option>, <option value={18}>18</option>, <option value={19}>19</option>,
                    <option value={20}>20</option>, <option value={21}>21</option>, <option value={22}>22</option>, <option value={23}>23</option>]
                </Input>
            }
        } catch (e) {
            return "";
        }
    }

    getMinute(submissionId, newDeadline) {
        try {
            if (newDeadline === null || isNaN(newDeadline)) {
                return "";
            } else {
                return <Input type="select" onChange={(e) => this.setNewDeadlineMinute(submissionId, e.target.value)} value={(new Date(newDeadline)).getMinutes()} >
                    [<option value={0}>00</option>, <option value={5}>05</option>, <option value={10}>10</option>, <option value={15}>15</option>,
                    <option value={20}>20</option>, <option value={25}>25</option>, <option value={30}>30</option>, <option value={35}>35</option>,
                    <option value={40}>40</option>, <option value={45}>45</option>, <option value={50}>50</option>, <option value={55}>55</option>]
                </Input>
            }
        } catch (e) {
            return "";
        }
    }

    datesEqual(a, b) {
        if (a.getFullYear() !== b.getFullYear()) return false;
        if (a.getMonth() !== b.getMonth()) return false;
        if (a.getDay() !== b.getDay()) return false;
        if (a.getHours() !== b.getHours()) return false;
        if (a.getMinutes() !== b.getMinutes()) return false;
        return true;
    }

    setNewDeadlineHour(submissionId, hour) {
        const newDeadlines = this.state.newDeadlines;
        if (newDeadlines[submissionId] === undefined) newDeadlines[submissionId] = new Date(this.getCurrentDeadline(submissionId));
        newDeadlines[submissionId].setHours(hour);
        this.setState({newDeadlines: newDeadlines});
        this.setNewDeadline(submissionId, newDeadlines[submissionId]);
    }

    setNewDeadlineMinute(submissionId, minute) {
        const newDeadlines = this.state.newDeadlines;
        if (newDeadlines[submissionId] === undefined) newDeadlines[submissionId] = new Date(this.getCurrentDeadline(submissionId));
        newDeadlines[submissionId].setMinutes(minute);
        this.setState({newDeadlines: newDeadlines});
        this.setNewDeadline(submissionId, newDeadlines[submissionId]);
    }

    setNewDeadline(submissionId, newDeadline) {
        if (this.datesEqual(new Date(this.getCurrentDeadline(submissionId)), newDeadline)) {
            const newDeadlines = this.state.newDeadlines;
            newDeadlines[submissionId] = undefined;
            this.setState({newDeadlines: newDeadlines});
        } else {
            const newDeadlines = this.state.newDeadlines;
            newDeadlines[submissionId] = newDeadline;
            this.setState({newDeadlines: newDeadlines});
        }
    }

    saveNewDeadline(submissionId) {
        const savingNewDeadline = this.state.savingNewDeadline;
        savingNewDeadline[submissionId] = true;
        this.setState({savingNewDeadline: savingNewDeadline});
        let token = "";
        const milestones = this.state.milestones;
        for (let i = 0; i < milestones.length; i++) {
            for (let j = 0; j < milestones[i].submissions.length; j++) {
                if (milestones[i].submissions[j].id === submissionId) {
                    token = milestones[i].submissions[j].token;
                }
            }
        }
        rest({
                 path: '/api/teacher/submissiondeadline/' + submissionId + "/" + token, method: 'put', body: {newDeadline: this.state.newDeadlines[submissionId]}
             },
             (data) => {
                 if (data.result === 1) {
                     const milestones = this.state.milestones;
                     for (let i = 0; i < milestones.length; i++) {
                         for (let j = 0; j < milestones[i].submissions.length; j++) {
                             if (milestones[i].submissions[j].id === submissionId) {
                                 milestones[i].submissions[j].newDeadline = this.state.newDeadlines[submissionId];
                             }
                         }
                     }
                     const newDeadlines = this.state.newDeadlines;
                     newDeadlines[submissionId] = undefined;
                     this.setState({milestones: milestones, newDeadlines: newDeadlines});
                     toastSuccess("teacherSubmissions.dateSaved");
                 } else {
                     toastError("teacherSubmissions.dateNotSaved");
                 }
                 const savingNewDeadline = this.state.savingNewDeadline;
                 savingNewDeadline[submissionId] = undefined;
                 this.setState({savingNewDeadline: savingNewDeadline});
             }
        );
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const submissions = [];
        const submittedBys = new Set();
        submittedBys.add("");
        const states = new Set();
        states.add("");
        const submittedFors = new Set();
        submittedFors.add("");

        this.state.milestones.forEach(milestone => {
            milestone.submissions.forEach(submission => {
                submittedBys.add(submission.submittedBy);
                submittedFors.add(submission.submittedFor);
                states.add(submission.state);
                submission.milestoneNameCz = milestone.nameCz;
                submission.milestoneNameEn = milestone.nameEn;
                submission.milestoneIdentifier = milestone.identifier;
                if (((this.state.state === "" && submission.state !== "D") || this.state.state === submission.state)
                    && (this.state.milestone === "" || this.state.milestone === milestone.identifier)
                    && (this.state.submittedBy === "" || this.state.submittedBy === submission.submittedBy)
                    && (this.state.submittedFor === "" || this.state.submittedFor === submission.submittedFor)) {
                    submissions[submissions.length] = submission;
                }
            });
        });

        let isDeletedIn = false;
        states.forEach(state => {
            if (state === "D") isDeletedIn = true;
        });
        if (!isDeletedIn) states.add("D");

        const submissionsWithComment = [];
        submissions.forEach(submission => {
            if (submission.comment !== null && submission.comment !== "") {
                submissionsWithComment[submissionsWithComment.length] = submission;
                submissionsWithComment[submissionsWithComment.length] = {commentLine: submission.comment, id: submission.id};
            } else {
                submissionsWithComment[submissionsWithComment.length] = submission;
            }
        })

        const sortedSubmissions = submissionsWithComment.sort((a, b) => a.id - b.id);

        let submissionListCount = 0;
        for (let i = 0; i < sortedSubmissions.length; i++) {
            if (sortedSubmissions[i].commentLine === undefined) {
                window.sessionStorage.setItem("submissionListId" + submissionListCount, sortedSubmissions[i].id);
                window.sessionStorage.setItem("submissionListChanged" + submissionListCount, "");
                window.sessionStorage.setItem("submissionListToken" + submissionListCount++, sortedSubmissions[i].token);
            }
        }
        window.sessionStorage.setItem("submissionListCount", submissionListCount.toString());
        window.sessionStorage.setItem("preferredPart", "");

        const submissionList = sortedSubmissions.map(submission => {

            if (submission.commentLine !== undefined) {
                return <tr key={submission.id + "comment"}>
                    <td>{submission.id}</td>
                    <td colSpan={10}>
                        <b>{Localization.Get("teacherSubmissions.comment")}</b><br/>{submission.commentLine}
                    </td>
                </tr>
            } else {
                const filenameList = this.getFilenameList(submission.files);
                return <tr key={submission.id}>
                    <td>{submission.id}</td>
                    <td>{Localization.GetLanguage() === "CZ" ? submission.dateCz : submission.dateEn}</td>
                    <td>{Localization.GetLanguage() === "CZ" ? submission.milestoneNameCz : submission.milestoneNameEn}</td>
                    <td>{this.getSubmissionState(submission.state)}</td>
                    <td>{this.state.savingNewDeadline[submission.id] !== undefined ? <LoadingSpinner /> : <div>
                        {submission.type === "S" ?
                         this.state.newDeadlines[submission.id] === undefined ?
                         <div>
                             <DatePicker className="form-control" dropdownMode="select" closeOnScroll={true} locale={Localization.GetLanguage() === "CZ" ? "cs" : "en"}
                                         dateFormat={Localization.GetLanguage() === "CZ" ? "dd.MM.yyyy" : "MM/dd/yyyy"} calendarStartDay={1}
                                         selected={submission.newDeadline === undefined ? "" : submission.newDeadline} onChange={ (date) => this.setNewDeadline(submission.id, date)} />
                             {this.getHour(submission.id, submission.newDeadline)}
                             {this.getMinute(submission.id, submission.newDeadline)}
                         </div> :
                         <div>
                             <DatePicker className="form-control" dropdownMode="select" closeOnScroll={true} locale={Localization.GetLanguage() === "CZ" ? "cs" : "en"}
                                         dateFormat={Localization.GetLanguage() === "CZ" ? "dd.MM.yyyy" : "MM/dd/yyyy"} calendarStartDay={1}
                                         selected={this.state.newDeadlines[submission.id]} onChange={ (date) => this.setNewDeadline(submission.id, date)} />
                             {this.getHour(submission.id, this.state.newDeadlines[submission.id])}
                             {this.getMinute(submission.id, this.state.newDeadlines[submission.id])}
                             <Button size="sm" color="success" onClick={() => {this.saveNewDeadline(submission.id);}}>
                                 {Localization.Get("teacherSubmissions.saveNewDeadline")}</Button>
                         </div>
                             : "" }
                    </div> }
                    </td>
                    <td>{submission.submittedBy + " / " + submission.submittedFor}</td>
                    <td>
                        <ul>
                            {filenameList}
                        </ul>
                        <a href={Constants.GetServer() + "/api/teacher/submission/backup/" + submission.id +"/" + submission.token} target="_blank">{Localization.Get("teacherSubmissions.backup")}</a>
                    </td>
                    <td>
                        { (submission.state !== "D" && submission.state !== "Q" && submission.state !== "S") ?
                          <Button size="sm" color="primary" tag={Link} to={"/submissioncorrection/" + submission.id + "/" + submission.token + "/0/0"}>
                              {Localization.Get("teacherSubmissions.teacherCorrection")}</Button> : "" }
                    </td>
                    <td>
                        { (submission.state !== "D" && submission.state !== "Q" && submission.state !== "S") ?
                          <Button size="sm" color="warning" tag={Link} to={"/submissiongrades/" + submission.id + "/" + submission.token}>
                            {Localization.Get("teacherSubmissions.teacherGrades")}</Button> : "" }
                    </td>
                    <td>
                        { (submission.state === "C" || submission.state === "N") ?
                          <Button size="sm" color="success" onClick={() => {this.setReleased(submission.id);}}>
                              {Localization.Get("teacherSubmissions.setReleased")}</Button> : "" }
                    </td>
                    <td>
                        { submission.state === "D" ?
                          <Button size="sm" color="success" onClick={() => {this.setUndeleted(submission.id);}}>
                              {Localization.Get("teacherSubmissions.setUndeleted")}</Button> :
                          <Button size="sm" color="danger" onClick={() => {this.setDeleted(submission.id);}}>
                              {Localization.Get("teacherSubmissions.setDeleted")}</Button>
                        }
                    </td>
                </tr>
            }

        });

        const milestones = [];
        milestones[0] = {identifier: "", nameCz: "", nameEn: ""};
        this.state.milestones.forEach(milestone => {
            if (milestone.submissions.length > 0) milestones[milestones.length] = milestone;
        })

        const milestoneList = milestones.map(milestone => {
            return <option key={milestone.identifier} value={milestone.identifier}>{Localization.GetLanguage() === "CZ" ? milestone.nameCz : milestone.nameEn}</option>
        })

        const stateList = Array.from(states).map(state => {
            return <option key={state} value={state}>{this.getSubmissionState(state)}</option>
        })

        const submittedByList = Array.from(submittedBys).map(submittedBy => {
            return <option key={submittedBy} value={submittedBy}>{submittedBy}</option>
        })

        const submittedForList = Array.from(submittedFors).map(submittedFor => {
            return <option key={submittedFor} value={submittedFor}>{submittedFor}</option>
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("teacherSubmissions.title")}</h2><br/>
                            <h3>{Localization.Get("teacherSubmissions.submissionList")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode}
                                {(this.state.milestonesLoaded === false) ? "" : (Localization.GetLanguage() === "CZ" ? this.state.descriptionCz : this.state.descriptionEn) } </h3><br/>
                            <Button color="primary" tag={Link} to={"/teachercourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                {Localization.Get("teacherSubmissions.goBack")}</Button>&nbsp;
                            <Button color="info" tag={Link} to={"/teacherattendance/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId}>{Localization.Get("teacherSubmissions.attendance")}</Button>
                            &nbsp;
                            <br/>&nbsp;<br/>
                            {this.state.conversionState === "" ? "" : <div>
                                <b>{Localization.Get("teacherSubmissions.converting")}</b> {this.state.conversionState}<br/>&nbsp;<br/></div>}
                            <Row>
                                <Col md={3}>
                                    <strong>{Localization.Get("teacherSubmissions.milestoneList")}</strong>
                                    { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                        <Input type="select" onChange={this.handleMilestoneList} value={this.state.milestone} >
                                            {milestoneList}
                                        </Input> </div>
                                    }
                                </Col>
                                <Col md={3}>
                                    <strong>{Localization.Get("teacherSubmissions.stateList")}</strong>
                                    { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                        <Input type="select" onChange={this.handleStateList} value={this.state.state} >
                                            {stateList}
                                        </Input> </div>
                                    }
                                </Col>
                                <Col md={3}>
                                    <strong>{Localization.Get("teacherSubmissions.submittedByList")}</strong>
                                    { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                        <Input type="select" onChange={this.handleSubmittedByList} value={this.state.submittedBy} >
                                            {submittedByList}
                                        </Input> </div>
                                    }
                                </Col>
                                <Col md={3}>
                                    <strong>{Localization.Get("teacherSubmissions.submittedForList")}</strong>
                                    { this.state.milestonesLoaded === false ? <LoadingSpinner /> : <div>
                                        <Input type="select" onChange={this.handleSubmittedForList} value={this.state.submittedFor} >
                                            {submittedForList}
                                        </Input> </div>
                                    }
                                </Col>
                            </Row>
                            <br/>
                            { (this.state.milestonesLoaded === false) ? <LoadingSpinner /> : <div>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("teacherSubmissions.id")}</th>
                                        <th>{Localization.Get("teacherSubmissions.date")}</th>
                                        <th>{Localization.Get("teacherSubmissions.milestone")}</th>
                                        <th>{Localization.Get("teacherSubmissions.state")}</th>
                                        <th>{Localization.Get("teacherSubmissions.newDeadline")}</th>
                                        <th>{Localization.Get("teacherSubmissions.submittedBy") + " / " + Localization.Get("teacherSubmissions.submittedFor")}</th>
                                        <th>{Localization.Get("teacherSubmissions.files")}</th>
                                        <th colSpan="4">{Localization.Get("teacherSubmissions.operations")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {submissionList}
                                    </tbody>
                                </Table>
                            </div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/teachercourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                {Localization.Get("teacherSubmissions.goBack")}</Button>&nbsp;
                            <Button color="info" tag={Link} to={"/teacherattendance/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                                                + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId}>{Localization.Get("teacherSubmissions.attendance")}</Button>&nbsp;
                            <Button color="success" tag={Link}
                                    to={"/submitmilestone/teacher/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/"
                                        + this.props.params.parallelCode + "/" + this.props.params.parallelId + "/" + 0 }>{Localization.Get("teacherSubmissions.submit")}
                            </Button>
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default TeacherSubmissions;