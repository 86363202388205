import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "./Navigation";
import rest from "../public/Rest";
import {ToastContainer} from 'react-toastify';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Button} from "reactstrap";
import "./editor.css";
import Footer from "../public/Footer";

class SubmissionView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            drawing: undefined,
            drawingLoaded: false,
            picture: undefined,
            pictureLoaded: false,
            callBacksInjected: false,
            editorLoaded: false,
            pictureIdShown: 0,
            refresh: 0,
        }
        this.getImage = this.getImage.bind(this);
        this.injectCallBacks = this.injectCallBacks.bind(this);
        this.editorCallBack = this.editorCallBack.bind(this);
    }

    localizationReplace(text, key) {
        return text.replace(">" + key + "<", ">" + Localization.Get(key) + "<");
    }

    injectCallBacks() {
        if (!this.state.callBacksInjected) {
            try {
                window["setCallBack"](this.editorCallBack);
                window["setGetImage"](this.getImage);
                this.setState({callBacksInjected: true});
            } catch (e) {
                (() => setTimeout(() => this.injectCallBacks(), 100))();
            }
        }
    }

    // this is being called from the editor
    getImage() {
        return {
            picture: this.state.picture,
            pictureId: 0,
            previousPictureId: null,
            nextPictureId: null,
            students: [],
            student: "",
            part: 0,
            parts: [],
            drawing: this.state.drawing,
            state: "R",
            pictureSet: [],
            downloadPictureId: this.props.params.pictureId,
        };
    }

    editorCallBack(operation, dataObject, callBack) {
        if (operation === "fetchPicture") {
            this.fetchPicture(dataObject.pictureId, dataObject.drawingId, callBack);
        }
    }

    fetchPicture(pictureId, drawingId, callBack) {
        if (pictureId === 0) {
            pictureId = 0;
            drawingId = 0;
            const mode = "view";
            callBack({pictureId: pictureId, drawingId: drawingId, mode: mode});
        }
    }

    removeScript(scriptToRemove) {
        const suspects = document.getElementsByTagName("script");
        for (let i = suspects.length; i >= 0; i--){
            if (suspects[i] && suspects[i].getAttribute("src") !== null
                && suspects[i].getAttribute("src").indexOf(`${scriptToRemove}`) !== -1 ){
                suspects[i].parentNode.removeChild(suspects[i]);
            }
        }
        return 1;
    }

    binaryStringToArray(binary) {
        const array = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) array[i] = binary.charCodeAt(i);
        return array;
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            if (!this.state.submissionLoaded) rest({path: '/api/user/drawing/' + this.props.params.pictureId + "/" + this.props.params.pictureToken, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({drawingLoaded: true, drawing: data.drawing, pictureIdShown: this.props.params.pictureId});
                        rest({path: '/api/user/picture/' + this.props.params.pictureId + "/" + this.props.params.pictureToken, method: 'get'},
                             (data) => {
                                 if (data.result === 1 && !this.state.pictureLoaded) {
                                     const picture = new Blob([this.binaryStringToArray(atob(data.image))], {type: 'image/jpg'});
                                     this.setState({picture: picture, pictureLoaded: true});
                                     if (!this.state.editorLoaded) rest({path: '/api/user/viewer', method: 'get'},
                        (data) => {
                                       if (data.result === 1 && !this.state.editorLoaded) {
                                           let html = data.html;
                                           html = this.localizationReplace(html, "editor.note");
                                           window.editorHtml = html;
                                           const script = document.createElement("script");
                                           script.src = '/api/user/viewer.js';
                                           script.async = true;
                                           this.removeScript(script);
                                           document.body.appendChild(script);
                                           this.injectCallBacks();
                                           this.setState({editorLoaded: true});
                                       }
                                   });
                                 }
                             });
                    }
                });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("submissionView.title")}</h2><br/>
                        </div> : <LoadingSpinner />}
                         {this.state.drawingLoaded && this.state.pictureLoaded && this.state.editorLoaded ? <div></div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default SubmissionView;