import React from 'react';
import './App.css';
import Home from './public/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from "./user/LandingPage";
import LocalizationElementHistoryWrapper from "./admin/LocalizationElementHistoryWrapper"
import LocalizationPage from "./admin/LocalizationPage";
import LoginBlocked from "./public/LoginBlocked";
import Login from "./public/Login";
import SystemSettings from "./admin/SystemSettings";
import Log from "./admin/Log";
import LocalizationElementWrapper from "./admin/LocalizationElementWrapper"
import Statistics from "./admin/Statistics";
import TeacherCourses from "./teacher/TeacherCourses"
import TeacherCourseWrapper from "./teacher/TeacherCourseWrapper";
import SetupParallelWrapper from "./teacher/SetupParallelWrapper";
import StudentCourses from "./student/StudentCourses";
import StudentCourseWrapper from "./student/StudentCourseWrapper";
import SubmitMilestoneWrapper from "./user/SubmitMilestoneWrapper";
import StudentSubmissionsWrapper from "./student/StudentSubmissionsWrapper";
import StudentSubmissionWrapper from "./student/StudentSubmissionWrapper";
import TeacherSubmissionsWrapper from "./teacher/TeacherSubmissionsWrapper";
import SubmissionCorrectionWrapper from "./teacher/SubmissionCorrectionWrapper";
import SubmissionViewWrapper from "./user/SubmissionViewWrapper";
import SubmissionGradesWrapper from "./teacher/SubmissionGradesWrapper";
import NewCodesWrapper from "./teacher/NewCodesWrapper";
import ScanCodesWrapper from "./teacher/ScanCodesWrapper";
import ChangePassword from "./admin/ChangePassword";
import UserManagement from "./admin/UserManagement";
import Logout from "./user/Logout";
import TeacherChartWrapper from "./teacher/TeacherChartWrapper";
import TeacherAttendanceWrapper from "./teacher/TeacherAttendanceWrapper";

// https://stackoverflow.com/questions/69967745/react-router-v6-access-a-url-parameter

export default function App() {
    return (
        // https://github.com/remix-run/react-router/blob/v4.1.1/FAQ.md#why-doesnt-my-application-render-after-refreshing 23. 5. 2023
        // HashRouter místo BrowserRouter as Router
        <Router forceRefresh={true}>
            <Routes>
                <Route exact path='/' element={<Home/>}/>
                <Route exact path='/landingpage' element={<LandingPage/>}/>
                <Route exact path='/localizationelement/:pageId' element={<LocalizationElementWrapper/>}/>
                <Route exact path='/localizationelementhistory/:pageId/:elementId' element={<LocalizationElementHistoryWrapper/>}/>
                <Route exact path='/localizationpage' element={<LocalizationPage/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/loginblocked' element={<LoginBlocked/>}/>
                <Route exact path='/showlog' element={<Log/>}/>
                <Route exact path='/statistics' element={<Statistics/>}/>
                <Route exact path='/systemsettings' element={<SystemSettings/>}/>
                <Route exact path='/teachercourses' element={<TeacherCourses/>} />
                <Route exact path='/studentcourses' element={<StudentCourses/>} />
                <Route exact path='/teachercourse/:semesterCode/:courseCode' element={<TeacherCourseWrapper/>} />
                <Route exact path='/teacherchart/:semesterCode/:courseCode' element={<TeacherChartWrapper/>} />
                <Route exact path='/studentcourse/:semesterCode/:courseCode' element={<StudentCourseWrapper/>} />
                <Route exact path='/setupparallel/:semesterCode/:courseCode/:parallelCode/:parallelId' element={<SetupParallelWrapper/>} />
                <Route exact path='/submitmilestone/:type/:semesterCode/:courseCode/:parallelCode/:parallelId/:milestoneIdentifier' element={<SubmitMilestoneWrapper/>} />
                <Route exact path='/studentsubmissions/:semesterCode/:courseCode/:parallelCode/:parallelId/:milestoneIdentifier' element={<StudentSubmissionsWrapper/>} />
                <Route exact path='/teachersubmissions/:semesterCode/:courseCode/:parallelCode/:parallelId' element={<TeacherSubmissionsWrapper/>} />
                <Route exact path='/teacherattendance/:semesterCode/:courseCode/:parallelCode/:parallelId' element={<TeacherAttendanceWrapper/>} />
                <Route exact path='/scancodes/:semesterCode/:courseCode/:parallelCode/:parallelId' element={<ScanCodesWrapper/>} />
                <Route exact path='/studentsubmission/:semesterCode/:courseCode/:parallelCode/:parallelId/:milestoneIdentifier/:submissionCode' element={<StudentSubmissionWrapper/>} />
                <Route exact path='/submissioncorrection/:submissionId/:submissionToken/:pictureId/:drawingId' element={<SubmissionCorrectionWrapper/>} />
                <Route exact path='/submissiongrades/:submissionId/:submissionToken' element={<SubmissionGradesWrapper/>} />
                <Route exact path='/submissionview/:pictureId/:pictureToken' element={<SubmissionViewWrapper/>} />
                <Route exact path='/changepassword' element={<ChangePassword/>} />
                <Route exact path='/usermanagement' element={<UserManagement/>} />
                <Route exact path='/newcodes' element={<NewCodesWrapper/>} />
                <Route exact path='/logout' element={<Logout/>} />
            </Routes>
        </Router>
    )
}
