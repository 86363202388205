import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ButtonGroup} from "react-bootstrap";
import {Button, Input} from "reactstrap";
import {Link} from "react-router-dom";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class StudentCourses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            courses: [],
            coursesLoaded: false,
            semestersLoaded: false,
            semesters: [],
            semester: "",
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/user/semester', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({semesters: data.semesters});
                         this.setState({semestersLoaded: true});
                         this.setState({semester: data.currentSemesterCode});
                         this.loadCourses(data.currentSemesterCode);
                     }
                 });
        })();
    }

    getCourseName(course) {
        if (Localization.GetLanguage() === "CZ") {
            return course.nameCz;
        } else {
            return course.nameEn;
        }
    }

    getSemesterName(semester) {
        if (Localization.GetLanguage() === "CZ") {
            return semester.code + " - " + semester.nameCz;
        } else {
            return semester.code + " - " + semester.nameEn;
        }
    }

    handleSemesterChange = (e) => {
        this.setState({semester: e.target.value});
        this.setState({coursesLoaded: false});
        this.loadCourses(e.target.value);
    }

    loadCourses(semesterCode) {
        if (semesterCode !== "") {
            rest({path: '/api/student/course/' + semesterCode, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({courses: data.courses});
                        this.setState({coursesLoaded: true});
                    }
                });
        }
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const semesterList =  this.state.semesters.map(semester => {
                    return <option key={semester.code} value={semester.code}>{this.getSemesterName(semester)}</option>
                })

        const courseList = this.state.courses.map(course => {
            return <tr key={course.code}>
                <td>{course.code}</td>
                <td>{this.getCourseName(course)}</td>
                <td>
                    {(course.canSubmit === true || course.shown) ?
                        <Button size="sm" color="primary" tag={Link} to={"/studentcourse/" + this.state.semester + "/" + course.code}>{Localization.Get("studentCourses.view")}</Button>
                    : "" }
                </td>
            </tr>
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("studentCourses.title")}</h2><br/>
                             <h3>{Localization.Get("studentCourses.semesterList")}</h3>
                             { this.state.semestersLoaded === false ? <LoadingSpinner /> : <div>
                                 <Input type="select" onChange={this.handleSemesterChange} value={this.state.semester} >
                                     {semesterList}
                                 </Input> </div>
                             }
                             <br/>
                             <h3>{Localization.Get("studentCourses.courseList")}</h3>
                             { this.state.coursesLoaded === false ? <LoadingSpinner /> : <div>
                                 <Table bordered hover>
                                     <thead>
                                     <tr>
                                         <th>{Localization.Get("studentCourses.courseCode")}</th>
                                         <th>{Localization.Get("studentCourses.courseName")}</th>
                                         <th>{Localization.Get("studentCourses.courseOperation")}</th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {courseList}
                                     </tbody>
                                 </Table></div> }
                             <p>&nbsp;</p>
                         </div> : <LoadingSpinner />}
                     </Col>
                 </Row>
                 <Footer />
             </Container>
        );
    }

}

export default StudentCourses;