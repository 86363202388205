import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "../public/Rest";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import CheckPassword from "./CheckPassword";
import {toastError, toastSuccess} from "../public/Toaster";
import Navigation from "../user/Navigation";
import Footer from "../public/Footer";


class changePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            oldPassword: "",
            oldPasswordFeedback: "",
            newPassword1: "",
            newPassword1Feedback: "",
            newPassword2: "",
            newPassword2Feedback: "",
            refresh: 0,
            canSet: false,
        }
        this.setPassword = this.setPassword.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
    }

    validatePasswords(oldPassword, newPassword1, newPassword2) {
        if (CheckPassword(oldPassword)) {
            this.setState({oldPasswordFeedback: CheckPassword(oldPassword)});
        } else {
            this.setState({oldPasswordFeedback: ""});
        }
        let ok = true;
        if (CheckPassword(newPassword1)) {
            this.setState({newPassword1Feedback: CheckPassword(newPassword1)});
            ok = false;
        } else {
            this.setState({newPassword1Feedback: ""});
        }
        if (newPassword1 !== newPassword2) {
            this.setState({newPassword2Feedback: Localization.Get("changePassword.copyDifferent")});
            ok = false;
        } else {
            this.setState({newPassword2Feedback: ""});
        }
        this.setState({canSet: ok});
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("changePassword.title")}</h2>
                             <Form>
                                 <Form.Group className="mb-3">
                                     <br/>
                                     <Form.Label><b>{Localization.Get("changePassword.oldPassword")}</b></Form.Label>
                                     <Form.Control isInvalid={this.state.oldPasswordFeedback !== ""} type="password" placeholder="" value={this.state.oldPassword || ''} onChange={event => {this.setState({oldPassword: event.target.value});
                                         this.validatePasswords(event.target.value, this.state.newPassword1, this.state.newPassword2)}} maxLength={50}
                                                   onKeyUp={event => {if (event.key === "Enter") this.setPassword();}} />
                                     {this.state.oldPasswordFeedback !== "" ? <Form.Text>{this.state.oldPasswordFeedback}</Form.Text> : "" }
                                     <br/>
                                     <Form.Label><b>{Localization.Get("changePassword.newPassword1")}</b></Form.Label>
                                     <Form.Control isInvalid={this.state.newPassword1Feedback !== ""} type="password" placeholder="" value={this.state.newPassword1 || ''} onChange={event => {this.setState({newPassword1: event.target.value});
                                         this.validatePasswords(this.state.oldPassword, event.target.value, this.state.newPassword2)}} maxLength={50}
                                                   onKeyUp={event => {if (event.key === "Enter") this.setPassword();}} />
                                     {this.state.newPassword1Feedback !== "" ? <Form.Text>{this.state.newPassword1Feedback}</Form.Text> : "" }
                                     <br/>
                                     <Form.Label><b>{Localization.Get("changePassword.newPassword2")}</b></Form.Label>
                                     <Form.Control isInvalid={this.state.newPassword2Feedback !== ""} type="password" placeholder="" value={this.state.newPassword2 || ''} onChange={event => {this.setState({newPassword2: event.target.value});
                                         this.validatePasswords(this.state.oldPassword, this.state.newPassword1, event.target.value)}} maxLength={50}
                                                   onKeyUp={event => {if (event.key === "Enter") this.setPassword();}} />
                                     {this.state.newPassword2Feedback !== "" ? <Form.Text>{this.state.newPassword2Feedback}</Form.Text> : "" }
                                     <br/>
                                 </Form.Group>
                                 <Button color="primary" onClick={this.setPassword} disabled={!this.state.canSet}>
                                     {Localization.Get("changePassword.setPassword")}
                                 </Button>
                             </Form>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    setPassword() {
        if (this.state.canSet) {
            rest({path: '/api/admin/password', method: 'put', body: {oldPassword: this.state.oldPassword, newPassword: this.state.newPassword1}},
                 (data) => {
                     if (data.result === 1) {
                         toastSuccess("changePassword.passwordChanged");
                     } else {
                         toastError("changePassword.passwordNotChanged");
                     }
                 });
        }
    }

}

export default changePassword;