import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import {toastError, toastSuccess} from "../public/Toaster";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import rest from "../public/Rest";
import Footer from "../public/Footer";

class SystemSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            refresh: 0,
            lastKosSyncStart: "",
            lastKosSyncFinish: "",
            kosClientId: "",
            kosClientSecret: "",
            ssoClientId: "",
            ssoClientSecret: "",
            ssoRedirectUrl: "",
        };
        this.clearCache = this.clearCache.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/kossync/', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({lastKosSyncStart: data.lastKosSyncStart, lastKosSyncFinish: data.lastKosSyncFinish})
                     }
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("settings.title")}</h2><br/>
                            <h3>{Localization.Get("settings.localization")}</h3>
                            <Button color="success" onClick={() => {this.localizationSwitchOn(); }}>
                                {Localization.Get("settings.localizationOn")}
                            </Button>&nbsp;
                            <Button color="warning" onClick={() => {this.localizationSwitchOff(); }}>
                                {Localization.Get("settings.localizationOff")}
                            </Button>&nbsp;
                            <Button color="info" onClick={() => {this.clearCache(); }}>
                                {Localization.Get("settings.clearCache")}
                            </Button>
                            <p>&nbsp;</p>
                            <h3>{Localization.Get("settings.kosSync")}</h3>
                            {Localization.Get("settings.lastKosSyncStart")} {this.state.lastKosSyncStart} <br/>
                            {Localization.Get("settings.lastKosSyncFinish")} {this.state.lastKosSyncFinish} <br/> <br/>
                            <Button color="success" onClick={() => {this.kosSyncRequest();}}>
                                {Localization.Get("settings.kosSyncRequest")}
                            </Button>
                            <p>&nbsp;</p>
                            <h3>{Localization.Get("settings.kosConnection")}</h3>
                            <Form.Label>{Localization.Get("settings.kosClientId")}</Form.Label>
                            <Form.Control type="text" value={this.state.kosClientId || ''} onChange={event => {this.setState({kosClientId: event.target.value})}}/><br/>
                            <Form.Label>{Localization.Get("settings.kosClientSecret")}</Form.Label>
                            <Form.Control type="text" value={this.state.kosClientSecret || ''} onChange={event => {this.setState({kosClientSecret: event.target.value})}}/><br/>
                            <Button color="danger" onClick={() => {this.setKosConnection();}}>
                                {Localization.Get("settings.setKosConnection")}
                            </Button>
                            <p>&nbsp;</p>
                            <h3>{Localization.Get("settings.ssoConnection")}</h3>
                            <Form.Label>{Localization.Get("settings.ssoClientId")}</Form.Label>
                            <Form.Control type="text" value={this.state.ssoClientId || ''} onChange={event => {this.setState({ssoClientId: event.target.value})}}/><br/>
                            <Form.Label>{Localization.Get("settings.ssoClientSecret")}</Form.Label>
                            <Form.Control type="text" value={this.state.ssoClientSecret || ''} onChange={event => {this.setState({ssoClientSecret: event.target.value})}}/><br/>
                            <Form.Label>{Localization.Get("settings.ssoRedirectUrl")}</Form.Label>
                            <Form.Control type="text" value={this.state.ssoRedirectUrl || ''} onChange={event => {this.setState({ssoRedirectUrl: event.target.value})}}/><br/>
                            <Button color="danger" onClick={() => {this.setSsoConnection();}}>
                                {Localization.Get("settings.setSsoConnection")}
                            </Button>
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    setKosConnection() {
        if (window.confirm(Localization.Get("settings.confirmKos"))) {
            rest({path: '/api/admin/kosconnection', method: 'put', body: {kosClientId: this.state.kosClientId, kosClientSecret: this.state.kosClientSecret}},
                 (data) => {
                     if (data.result === 1) {
                         toastSuccess("settings.kosUpdated");
                     } else {
                         toastError("general.error");
                     }
                 });
            return 1;
        }
    }

    setSsoConnection() {
        if (window.confirm(Localization.Get("settings.confirmSso"))) {
            rest({path: '/api/admin/ssoconnection', method: 'put', body: {ssoClientId: this.state.ssoClientId, ssoClientSecret: this.state.ssoClientSecret, ssoRedirectUrl: this.state.ssoRedirectUrl}},
                 (data) => {
                     if (data.result === 1) {
                         toastSuccess("settings.ssoUpdated");
                     } else {
                         toastError("general.error");
                     }
                 });
            return 1;
        }
    }

    localizationSwitchOn() {
        Localization.SwitchOn();
        toastSuccess("settings.localizationSwitchedOn");
        this.forceUpdate();
    }

    localizationSwitchOff() {
        Localization.SwitchOff();
        toastSuccess("settings.localizationSwitchedOff");
        this.forceUpdate();
    }

    clearCache() {
        this.setState({isLanguageInitialized: false});
        Localization.Clear(() => {this.setState({isLanguageInitialized: true});});
        toastSuccess("settings.localizationCacheCleared");
    }

    kosSyncRequest() {
        rest({path: '/api/admin/kossync/', method: 'post', body: {}},
             (data) => {
                 if (data.result === 1) {
                     toastSuccess("settings.kosSyncRequested");
                 } else {
                     toastError("general.error");
                 }
             });
    }

}

export default SystemSettings;