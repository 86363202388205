import React, {Component} from 'react';
import Localization from "./Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "./Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "./Rest";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Footer from "./Footer";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            username: "",
            password: "",
            refresh: 0,
        }
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("login.title")}</h2>
                             <Form>
                                 <Form.Group className="mb-3">
                                     <br/><Form.Label>{Localization.Get("login.username")}</Form.Label>
                                     <Form.Control id="username" type="text" placeholder="" value={this.state.username || ''} onChange={event => {this.setState({username: event.target.value})}} maxLength={50}
                                                   onKeyUp={event => {if (event.key === "Enter") this.login();}}/><br/>
                                     <Form.Label>{Localization.Get("login.password")}</Form.Label>
                                     <Form.Control id="password" type="password" placeholder="" value={this.state.password || ''} onChange={event => {this.setState({password: event.target.value})}} maxLength={50}
                                                   onKeyUp={event => {if (event.key === "Enter") this.login();}}/>
                                 </Form.Group>
                                 <Button color="primary" onClick={this.login} disabled={this.state.username === '' || this.state.password === ''}>
                                     {Localization.Get("login.submit")}
                                 </Button>&nbsp;
                                 <Button color="success" onClick={this.ssoLogin}>
                                     {Localization.Get("login.CVUT")}
                                 </Button>
                             </Form>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <p>&nbsp;</p>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    login() {
        if (this.state.username === "" || this.state.password === "") return;
        rest({path: '/api/public/csrf', method: 'get'}, (data) => {
            if (data.result === 1) {
                window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                rest({path: '/api/public/login', method: 'post', body: {username: this.state.username, password: this.state.password}},
                     (data) => {
                         if (data.result === 1) {
                             window.location = "/landingpage";
                         }
                     });
            } else {
                window.location = "/";
            }
        });
        return 1;
    }

    ssoLogin() {
        window.location = "/api/oauth20?code=null&state=null";
    }

}

export default Login;