import React from "react";
import "./spinner.css";
import Localization from "./Localization";

export default function Footer() {
    return (
        <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div className="footer">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div className="footerLine">
                <hr/>
                <footer>
                    {Localization.IsInitialized() ? <div>
                    {Localization.Get("footer.copyright")}<br/>
                    {Localization.Get("footer.issues")} <a href="https://gitlab.fit.cvut.cz/petrzja5/assignments/-/issues">LINK</a>
                    </div> : "" }
                </footer>
            </div>
        </div>
    );
}