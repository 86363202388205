import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavbarToggler} from "reactstrap";
import Localization from "../public/Localization";
import React, {useState} from "react";
import {Collapse} from "reactstrap";
import Footer from "../public/Footer";

export default function Navigation() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Navbar bg="primary" variant="dark" expand="md">
            <Container>
                <Navbar.Brand href="/landingpage">{Localization.Get("navigation.home")}</Navbar.Brand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto">
                        { window.sessionStorage.getItem("IS_TEACHER") === "true" ? <Nav.Link href="/teachercourses">{Localization.Get("navigation.forTeachers")}</Nav.Link> : "" }
                        { window.sessionStorage.getItem("IS_STUDENT") === "true" ? <Nav.Link href="/studentcourses">{Localization.Get("navigation.forStudents")}</Nav.Link> : "" }
                        { window.sessionStorage.getItem("IS_ADMIN") === "true" ? <div>
                        <NavDropdown title={Localization.Get("navigation.settings")} id="basic-nav-dropdown">
                            { window.sessionStorage.getItem("IS_TEACHER") === "true" ? <NavDropdown.Item href="/newcodes">{Localization.Get("navigation.newCodes")}</NavDropdown.Item> : "" }
                            { window.sessionStorage.getItem("IS_TEACHER") === "true" ? <NavDropdown.Divider /> : "" }
                            <NavDropdown.Item href="/showlog">{Localization.Get("navigation.log")}</NavDropdown.Item>
                            <NavDropdown.Item href="/statistics">{Localization.Get("navigation.statistics")}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/changepassword">{Localization.Get("navigation.passwordChange")}</NavDropdown.Item>
                            <NavDropdown.Item href="/usermanagement">{Localization.Get("navigation.userManagement")}</NavDropdown.Item>
                            <NavDropdown.Item href="/localizationpage">{Localization.Get("navigation.localization")}</NavDropdown.Item>
                            <NavDropdown.Item href="/systemsettings">{Localization.Get("navigation.systemSettings")}</NavDropdown.Item>
                        </NavDropdown>
                        </div> : (window.sessionStorage.getItem("IS_TEACHER") === "true" ? <div>
                            <Nav.Link href="/newcodes">{Localization.Get("navigation.newCodes")}</Nav.Link>
                        </div> : "") }
                        <Nav.Link href="/logout">{Localization.Get("navigation.logout")}</Nav.Link>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
           )
}