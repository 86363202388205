import 'react-toastify/dist/ReactToastify.css';
import toastIt from "./Toaster";

export default async function rest(request, successFunction, crashOnError = true, recoveryFunction) {
    let csrfHeaderName = window.sessionStorage.getItem('CSRF_HEADER');
    let csrfToken = window.sessionStorage.getItem('CSRF_TOKEN');
    let csrfHeader = {};
    csrfHeader[csrfHeaderName] = csrfToken;
    if (request.method === 'get') {
        await fetch(request.path, {
            method: request.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 403 || response.status === 302) {
                if (crashOnError) {(() => {window.location = "/";})();} else recoveryFunction();
            } else {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error("Call error: " + response.status);
                    if (crashOnError) {(() => {window.location = "/";})();} else recoveryFunction();
                }
            }
        }).then(data => {
            toastIt(data);
            data.context = request.context;
            successFunction(data);
        }).catch((error) => {
            console.error("Call error: " + error);
            if (crashOnError) {(() => {window.location = "/";})();} else recoveryFunction();
        });
    } else {
        await fetch(request.path, {
            method: request.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...csrfHeader,
            },
            body: JSON.stringify(request.body),
        }).then(response => {
            if (response.status === 403 || response.status === 302) {
                //sessionStorage.clear();
                rest({path: '/api/public/csrf', method: 'get'}, (data) => {
                    if (data.result === 1) {
                        window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                        window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                    }
                    if (crashOnError) {(() => {window.location = "/";})();} else recoveryFunction();
                });
            } else {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error("Call error: " + response.status);
                    if (crashOnError) {(() => {window.location = "/";})();} else recoveryFunction();
                }
            }
        }).then(data => {
            toastIt(data);
            data.context = request.context;
            successFunction(data);
        }).catch((error) => {
            console.error("Call error: " + error);
            if (crashOnError) {
                rest({path: '/api/public/csrf', method: 'get'}, (data) => {
                    if (data.result === 1) {
                        window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                        window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                    }
                    (() => {window.location = "/";})();
                });
            } else {
                recoveryFunction();
            }
        });
    }
}