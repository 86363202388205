import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button, Input} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "../public/Rest";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import CheckPassword from "./CheckPassword";
import {toastError, toastSuccess} from "../public/Toaster";
import Navigation from "../user/Navigation";
import Footer from "../public/Footer";

class changePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            users: [],
            newPassword1: "",
            newPassword1Feedback: "",
            newPassword2: "",
            newPassword2Feedback: "",
            newUserPassword1: "",
            newUserPassword1Feedback: "",
            newUserPassword2: "",
            newUserPassword2Feedback: "",
            refresh: 0,
            canSet: false,
            canSetNewUser: false,
            username: "",
            newUsername: "",
        }
        this.setPassword = this.setPassword.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.createNewUser = this.createNewUser.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/user', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setUsers(data.users);
                     }
                 });
        })();
    }

    setUsers(users) {
        if (users.length > 0) {
            this.setState({users: users});
            this.setState({username: users[0].username});
            this.setState({usersLoaded: true});
        } else {
            this.setState({users: users});
            this.setState({username: ""});
            this.setState({usersLoaded: true});
        }
    }

    validatePasswords(newPassword1, newPassword2) {
        let ok = true;
        if (CheckPassword(newPassword1)) {
            this.setState({newPassword1Feedback: CheckPassword(newPassword1)});
            ok = false;
        } else {
            this.setState({newPassword1Feedback: ""});
        }
        if (newPassword1 !== newPassword2) {
            this.setState({newPassword2Feedback: Localization.Get("userManagement.copyDifferent")});
            ok = false;
        } else {
            this.setState({newPassword2Feedback: ""});
        }
        this.setState({canSet: ok});
    }

    validateNewPasswords(newPassword1, newPassword2) {
        let ok = true;
        if (CheckPassword(newPassword1)) {
            this.setState({newUserPassword1Feedback: CheckPassword(newPassword1)});
            ok = false;
        } else {
            this.setState({newUserPassword1Feedback: ""});
        }
        if (newPassword1 !== newPassword2) {
            this.setState({newUserPassword2Feedback: Localization.Get("userManagement.copyDifferent")});
            ok = false;
        } else {
            this.setState({newUserPassword2Feedback: ""});
        }
        this.setState({canSetNewUser: ok});
    }

    handleUserChange = (e) => {
        this.setState({username: e.target.value});
    }

    render() {

        const userList =  this.state.users.map(user => {
            return <option key={user.username} value={user.username}>{user.fullName}</option>
        })

        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("userManagement.title")}</h2>
                             <h3>{Localization.Get("userManagement.userSetup")}</h3>
                             <Form>
                                 <Form.Group className="mb-3">
                                     <Form.Label><b>{Localization.Get("userManagement.currentUsers")}</b></Form.Label>
                                     <Input type="select" onChange={this.handleUserChange} value={this.state.username} >
                                         {userList}
                                     </Input>
                                     <br/>
                                     <Form.Label><b>{Localization.Get("userManagement.newPassword1")}</b></Form.Label>
                                     <Form.Control autoComplete="new-password" isInvalid={this.state.newPassword1Feedback !== ""} type="password" placeholder="" value={this.state.newPassword1 || ''} onChange={event => {this.setState({newPassword1: event.target.value});
                                         this.validatePasswords(event.target.value, this.state.newPassword2)}} maxLength={50} />
                                     {this.state.newPassword1Feedback !== "" ? <Form.Text>{this.state.newPassword1Feedback}</Form.Text> : "" }
                                     <br/>
                                     <Form.Label><b>{Localization.Get("userManagement.newPassword2")}</b></Form.Label>
                                     <Form.Control autoComplete="new-password" isInvalid={this.state.newPassword2Feedback !== ""} type="password" placeholder="" value={this.state.newPassword2 || ''} onChange={event => {this.setState({newPassword2: event.target.value});
                                         this.validatePasswords(this.state.newPassword1, event.target.value)}} maxLength={50} />
                                     {this.state.newPassword2Feedback !== "" ? <Form.Text>{this.state.newPassword2Feedback}</Form.Text> : "" }
                                     <br/>
                                 </Form.Group>
                                 <Button color="primary" onClick={this.setPassword} disabled={!this.state.canSet || this.state.username === ""}>
                                     {Localization.Get("userManagement.setPassword")}
                                 </Button>&nbsp;
                                 <Button color="danger" onClick={this.deleteUser} disabled={this.state.username === ""}>
                                     {Localization.Get("userManagement.deleteUser")}
                                 </Button>
                             </Form>
                             <br/>&nbsp;<br/>
                             <h3>{Localization.Get("userManagement.newUser")}</h3>
                             <Form>
                                 <Form.Group className="mb-3">
                                     <Form.Label><b>{Localization.Get("userManagement.newUsername")}</b></Form.Label>
                                     <Form.Control autoComplete="off" type="text" placeholder="" value={this.state.newUsername || ''} onChange={event => {this.setState({newUsername: event.target.value.toLowerCase()})}} maxLength={50} />
                                     <br/>
                                     <Form.Label><b>{Localization.Get("userManagement.newUserPassword1")}</b></Form.Label>
                                     <Form.Control autoComplete="new-password" isInvalid={this.state.newUserPassword1Feedback !== ""} type="password" placeholder="" value={this.state.newUserPassword1 || ''} onChange={event => {this.setState({newUserPassword1: event.target.value});
                                         this.validateNewPasswords(event.target.value, this.state.newUserPassword2)}} maxLength={50} />
                                     {this.state.newUserPassword1Feedback !== "" ? <Form.Text>{this.state.newUserPassword1Feedback}</Form.Text> : "" }
                                     <br/>
                                     <Form.Label><b>{Localization.Get("userManagement.newUserPassword2")}</b></Form.Label>
                                     <Form.Control autoComplete="new-password" isInvalid={this.state.newUserPassword2Feedback !== ""} type="password" placeholder="" value={this.state.newUserPassword2 || ''} onChange={event => {this.setState({newUserPassword2: event.target.value});
                                         this.validateNewPasswords(this.state.newUserPassword1, event.target.value)}} maxLength={50} />
                                     {this.state.newUserPassword2Feedback !== "" ? <Form.Text>{this.state.newUserPassword2Feedback}</Form.Text> : "" }
                                     <br/>
                                 </Form.Group>
                                 <Button color="warning" onClick={this.createNewUser} disabled={this.state.newUsername === "" || !this.state.canSetNewUser}>
                                     {Localization.Get("userManagement.createNew")}
                                 </Button>&nbsp;
                             </Form>
                             <p>&nbsp;</p>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    setPassword() {
        if (this.state.canSet) {
            rest({path: '/api/admin/user', method: 'put', body: {username: this.state.username, password: this.state.newPassword1}},
                 (data) => {
                     if (data.result === 1) {
                         toastSuccess("userManagement.passwordChanged");
                     } else {
                         toastError("userManagement.passwordNotChanged");
                     }
                 });
        }
    }

    deleteUser() {
        if (window.confirm(Localization.Get("userManagement.deleteThisUser"))) {
            rest({path: '/api/admin/user', method: 'PATCH', body: {username: this.state.username}},
                 (data) => {
                     if (data.result === 1) {
                         toastSuccess("userManagement.userDeleted");
                         this.setUsers(data.users);
                     } else {
                         toastError("userManagement.userNotDeleted");
                     }
                 });
        }
    }

    createNewUser() {
        rest({path: '/api/admin/user', method: 'post', body: {username: this.state.newUsername, password: this.state.newUserPassword1}},
             (data) => {
                 if (data.result === 1) {
                     toastSuccess("userManagement.userCreated");
                     this.setUsers(data.users);
                 } else {
                     toastError("userManagement.userNotCreated");
                 }
             });
    }

}

export default changePassword;