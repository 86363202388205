import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";

class TeacherAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            dataLoaded: false,
            nameCz: " / ",
            nameEn: " / ",
            refresh: 0,
            attendance: [],
            studentCount: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/teacher/attendance/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                     + "/" + this.props.params.parallelId, method: 'get'},
                (data) => {
                    this.setState({dataLoaded: true, attendance: data.attendance, descriptionCz: " - " + data.descriptionCz,
                                      descriptionEn: " - " + data.descriptionEn, studentCount: data.studentCount});
                });
        })();
    }

    getAttendanceSum() {
        let students = this.state.attendance.length;
        let submissions = 0;
        let files = 0;
        let pages = 0;
        let shownToStudents = 0;
        let localQrCodes = 0;
        let qrCodes = 0;
        let attendanceQrCodes = 0;
        this.state.attendance.forEach(attendanceLine => {
           submissions += attendanceLine.submissions;
           files += attendanceLine.files;
           pages += attendanceLine.pages;
           shownToStudents += attendanceLine.shownToStudent;
           localQrCodes += attendanceLine.localQrCodes;
           qrCodes += attendanceLine.qrCodes;
           attendanceQrCodes += attendanceLine.attendanceQrCodes;
        });
        return <tr key="total">
            <td><b>{Localization.Get("teacherAttendance.total")}&nbsp;{students}</b></td>
            <td><b>{submissions}</b></td>
            <td><b>{files}</b></td>
            <td><b>{pages}</b></td>
            <td><b>{shownToStudents}</b></td>
            <td><b>{localQrCodes}</b></td>
            <td><b>{qrCodes}</b></td>
            <td><b>{attendanceQrCodes}</b></td>
        </tr>
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const attendance = [];

        this.state.attendance.forEach(attendanceLine => {
            attendance[attendance.length] = {
                student: attendanceLine.student,
                submissions: attendanceLine.submissions,
                files: attendanceLine.files,
                pages: attendanceLine.pages,
                shownToStudent: attendanceLine.shownToStudent,
                localQrCodes: attendanceLine.localQrCodes,
                qrCodes: attendanceLine.qrCodes,
                attendanceQrCodes: attendanceLine.attendanceQrCodes,
            }
        })

        const sortedAttendance = attendance.sort((a, b) => a.student.localeCompare(b.student));

        const attendanceList = sortedAttendance.map(attendance => {

            return <tr key={attendance.student}>
                <td>{attendance.student}</td>
                <td>{attendance.submissions === 0 ? "" : attendance.submissions}</td>
                <td>{attendance.files === 0 ? "" : attendance.files}</td>
                <td>{attendance.pages === 0 ? "" : attendance.pages}</td>
                <td>{attendance.shownToStudent === 0 ? "" : attendance.shownToStudent}</td>
                <td>{attendance.localQrCodes === 0 ? "" : attendance.localQrCodes}</td>
                <td>{attendance.qrCodes === 0 ? "" : attendance.qrCodes}</td>
                <td>{attendance.attendanceQrCodes === 0 ? "" : attendance.attendanceQrCodes}</td>
            </tr>
        });

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("teacherAttendance.title")}</h2><br/>
                            <h3>{Localization.Get("teacherAttendance.studentList")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode}
                                {(this.state.dataLoaded === false) ? "" : (Localization.GetLanguage() === "CZ" ? this.state.descriptionCz : this.state.descriptionEn) } </h3><br/>
                            <Button color="primary" tag={Link} to={"/teachersubmissions/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                                                   + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId}>{Localization.Get("teacherAttendance.back")}</Button>&nbsp;
                            <br/>&nbsp;<br/>
                            { (this.state.dataLoaded === false) ? <LoadingSpinner /> : <div>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("teacherAttendance.student")}</th>
                                        <th>{Localization.Get("teacherAttendance.submissions")}</th>
                                        <th>{Localization.Get("teacherAttendance.files")}</th>
                                        <th>{Localization.Get("teacherAttendance.pages")}</th>
                                        <th>{Localization.Get("teacherAttendance.shownToStudent")}</th>
                                        <th>{Localization.Get("teacherAttendance.localQrCodes")}</th>
                                        <th>{Localization.Get("teacherAttendance.qrCodes")}</th>
                                        <th>{Localization.Get("teacherAttendance.attendanceQrCodes")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {attendanceList}
                                    {this.getAttendanceSum()}
                                    </tbody>
                                </Table>
                                <br/>
                                <b>{Localization.Get("teacherAttendance.studentCount")}&nbsp;{this.state.studentCount}</b>
                            </div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/teachersubmissions/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId}>{Localization.Get("teacherAttendance.back")}</Button>
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
             </Container>
        );
    }

}

export default TeacherAttendance;