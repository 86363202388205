import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button, Input} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import {Link} from "react-router-dom";
import Table from "react-bootstrap/Table";
import {toastError, toastSuccess} from "../public/Toaster";
import {ToastContainer} from "react-toastify";
import {ButtonGroup} from "react-bootstrap";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class TeacherCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            parallels: [],
            parallelsLoaded: false,
            exams: [],
            examsLoaded: false,
            exam: undefined,
            examsLoading: false,
            refresh: 0,
            showAll: false,
        }
        this.pageBottom = React.createRef();
        this.addOrUpdate = this.addOrUpdate.bind(this);
        this.loadExams = this.loadExams.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            this.loadCourse();
        })();
    }

    loadCourse() {
        rest({path: '/api/teacher/course/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
             (data) => {
                 if (data.result === 1) {
                     this.setState({parallels: data.parallels});
                     this.setState({parallelsLoaded: true});
                 }
             });
    }

    loadExams() {
        rest({path: '/api/teacher/exam/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
             (data) => {
                 this.setState({examsLoading: false});
                 if (data.result === 1) {
                     this.setState({exams: data.parallels, examsLoaded: true, exam: data.parallels.length > 0 ? data.parallels[0].code : undefined});
                     toastSuccess("teacherCourse.examsAreLoaded");
                     setTimeout(() => {
                         this.pageBottom.current.scrollIntoView();
                     }, 0);
                 } else {
                     toastError("teacherCourse.examsLoadFailed");
                 }
             });
        toastSuccess("teacherCourse.examsAreBeingLoaded");
        this.setState({examsLoading: true});
    }

    getParallelDescription(parallel) {
        if (Localization.GetLanguage() === "CZ") {
            return parallel.descriptionCz;
        } else {
            return parallel.descriptionEn;
        }
    }

    addOrUpdate() {
        let exam = undefined;
        for (let i = 0; i < this.state.exams.length; i++) {
            if (this.state.exams[i].code === this.state.exam) {
                exam = this.state.exams[i];
            }
        }
        rest({path: '/api/teacher/exam/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'put', body: exam},
             (data) => {
                 if (data.result === 1) {
                     this.setState({parallelsLoaded: false, parallels: []});
                     this.loadCourse();
                 } else {
                     toastError("teacherCourse.examsAddOrUpdateFailed");
                 }
             });
    }

    handleExamChange = (e) => {
        this.setState({exam: e.target.value});
    }

    refresh = () => {
        this.setState({refresh: this.state.refresh + 1});
        return 1;
    }

    showAllSwitch() {
        this.setState({showAll: !this.state.showAll});
    }

    getVisibleList() {
        const visibleList = [];
        this.state.parallels.forEach(parallel => {
            if (this.state.showAll || parallel.canEdit) {
                visibleList[visibleList.length] = parallel;
            }
        });
        return visibleList;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const parallelList = this.getVisibleList().map(parallel => {
            return <tr key={parallel.code}>
                <td>{parallel.code}</td>
                <td>{this.getParallelDescription(parallel)}</td>
                <td>{parallel.teachers}</td>
                <td>
                    <Button size="sm" color="primary" tag={Link} to={"/teachersubmissions/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                                                     + "/" + parallel.code + "/" + parallel.id}>{Localization.Get("teacherCourse.submissions")}</Button>
                </td>
                <td>
                    <Button size="sm" color="info" tag={Link} to={"/scancodes/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                                                  + "/" + parallel.code + "/" + parallel.id}>{Localization.Get("teacherCourse.scanCodes")}</Button>
                </td>
                <td>
                    {parallel.canEdit === true ?
                     <Button size="sm" color="warning" tag={Link} to={"/setupparallel/" + this.props.params.semesterCode + "/" + this.props.params.courseCode
                                                                      + "/" + parallel.code + "/" + parallel.id}>{Localization.Get("teacherCourse.setup")}</Button>
                        : "" }

                </td>
                <td>
                    {parallel.canEdit === true ?
                        <Button size="sm" color="success" tag={Link}
                        to={"/submitmilestone/teacher/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/"
                        + parallel.code + "/" + parallel.id + "/" + 0 }>{Localization.Get("teacherCourse.submit")}
                        </Button>
                        : "" }
                </td>
            </tr>
        })

        const examList = this.state.exams.map(exam => {
            return <option key={exam.code} value={exam.code}>{Localization.GetLanguage() === "CZ" ? exam.descriptionCz : exam.descriptionEn}</option>
        });

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("teacherCourse.title")}</h2><br/>
                            <h3>{Localization.Get("teacherCourse.parallelList")}  {this.props.params.semesterCode} / {this.props.params.courseCode}</h3>
                            { this.state.parallelsLoaded === false ? <LoadingSpinner /> : <div>
                                { this.state.showAll === false ?
                                  <Button color="success" onClick={() => {this.showAllSwitch();}}>
                                      {Localization.Get("teacherCourse.showAll")}</Button> : "" }
                                { this.state.showAll === false ? <br/> : "" }
                                { this.state.showAll === false ? <br/> : "" }
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("teacherCourse.parallelCode")}</th>
                                        <th>{Localization.Get("teacherCourse.parallelDescription")}</th>
                                        <th>{Localization.Get("teacherCourse.parallelTeachers")}</th>
                                        <th colSpan="4">{Localization.Get("teacherCourse.parallelOperation")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {parallelList}
                                    </tbody>
                                </Table></div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/teachercourses/"}> {Localization.Get("teacherCourse.goBack")}</Button>
                            &nbsp;
                            {(this.state.examsLoading || !this.state.parallelsLoaded) ? <LoadingSpinner /> : <Button color="success" onClick={this.loadExams}>{Localization.Get("teacherCourse.goLoadExams")}</Button>}
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                         { (this.state.examsLoaded && !this.state.examsLoading)  ?
                           <div><h3>{Localization.Get("teacherCourse.examList")}</h3>
                             <Input type="select" onChange={this.handleExamChange} value={this.state.exam} >
                                 {examList}
                             </Input>
                         <br/>
                               { this.state.exam !== undefined ? <Button color="success" onClick={this.addOrUpdate}>{Localization.Get("teacherCourse.addOrUpdate")}</Button> : "" }
                               <p>&nbsp;</p>
                           </div> : ""}
                     </Col>
                </Row>
                 <Footer />
                 <div ref={this.pageBottom}></div>
                 <ToastContainer/>
             </Container>
        );
    }

}

export default TeacherCourse;