import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import {ToastContainer} from 'react-toastify';
import rest from "../public/Rest";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-GB";
import {ButtonGroup, DropdownButton} from "react-bootstrap";
import {Dropdown} from "react-bootstrap";
import {Pagination} from "react-bootstrap";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class Log extends Component {

    constructor(props) {
        super(props);
        const lengthTitle100 = 100;
        this.state = {
            isLanguageInitialized: false,
            entries: [],
            loaded: false,
            from: undefined,
            to: undefined,
            page: 0,
            pages: 0,
            pageLength: 100,
            pageLengthTitle: lengthTitle100,
            refresh: 0,
        }
        registerLocale("cs", cs);
        registerLocale("en-GB", en);
        this.filter = this.filter.bind(this);
        this.filterWithPageLength = this.filterWithPageLength.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/admin/log', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({entries: data.entries});
                         this.setState({loaded: true});
                         this.setState({pages: data.pages});
                         this.setState({page: data.page});
                     }
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const entryList = this.state.entries.map(entry => {
            return <tr key={entry.id}>
                <td>{entry.id}</td>
                <td>{entry.date}</td>
                <td>{entry.clazz}</td>
                <td>{entry.level}</td>
                <td>{entry.text}</td>
                <td>{entry.detail}</td>
                <td>{entry.userId}</td>
                <td>{entry.username}</td>
                <td>{entry.duration === null ? "" : entry.duration}</td>
            </tr>
        })

        const pagination = [];
        if (this.state.page === 0) {
            pagination.push(<Pagination.First disabled />);
            pagination.push(<Pagination.Prev disabled />);
        } else {
            pagination.push(<Pagination.First onClick={() => this.filter(0)} />);
            pagination.push(<Pagination.Prev onClick={() => this.filter(this.state.page - 1)} />);
        }
        let inGap = false;
        let spaces = 3;
        for (let i = 0; i < this.state.pages; i++) {
            let print = false;
            if (i < spaces - 1 || i > this.state.pages - spaces) print = true;
            if (i > this.state.page - spaces && i < this.state.page + spaces) print = true;
            if (!print && !inGap) {
                pagination.push(<Pagination.Ellipsis disabled />);
                inGap = true;
            }
            if (print) {
                inGap = false;
                if (i === this.state.page) {
                    pagination.push(<Pagination.Item key={i} onClick={() => this.filter(i)} active disabled>{i + 1}</Pagination.Item>);
                } else {
                    pagination.push(<Pagination.Item key={i} onClick={() => this.filter(i)}>{i + 1}</Pagination.Item>);
                }
            }
        }
        if (this.state.page === this.state.pages - 1) {
            pagination.push(<Pagination.Next disabled />);
            pagination.push(<Pagination.Last disabled />);
        } else {
            pagination.push(<Pagination.Next onClick={() => this.filter(this.state.page + 1)} />);
            pagination.push(<Pagination.Last onClick={() => this.filter(this.state.pages - 1)}/>);
        }
        const language = Localization.GetLanguage();
        const locale = language === "CZ" ? "cs" : "en";
        const dateFormat = language === "CZ" ? "dd.MM.yyyy" : "MM/dd/yyyy"
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("log.title")}</h2><br/>
                            <h3>{Localization.Get("log.entries")}</h3>
                            { this.state.loaded === false ? <LoadingSpinner /> : <div>
                                <Form onSubmit={this.onSubmitForm}>
                                    <Form.Group className="mb-3" controlId="dateSelection">
                                        <Row>
                                            <Col md={4}>
                                                <Form.Label>{Localization.Get("log.from")}</Form.Label>
                                                <DatePicker className="form-control" dropdownMode="select" closeOnScroll={true} locale={locale}
                                                            dateFormat={dateFormat} calendarStartDay={1}
                                                            selected={this.state.from === undefined ? '' : this.state.from} onChange={ (date) => this.setState({from: date})} />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>{Localization.Get("log.to")}</Form.Label>
                                                <DatePicker className="form-control" dropdownMode="select" closeOnScroll={true} locale={locale}
                                                            dateFormat={dateFormat} calendarStartDay={1}
                                                            selected={this.state.to === undefined ? '' : this.state.to} onChange={ (date) => this.setState({to: date})} />
                                            </Col>
                                            <Col md={4}>
                                                <Form.Label>{Localization.Get("log.lines")}</Form.Label>
                                                <DropdownButton variant="primary" className="floatRight" onSelect={this.setPageSize} title={this.state.pageLengthTitle} >
                                                    <Dropdown.Item key={10} eventKey={10}>10</Dropdown.Item>
                                                    <Dropdown.Item key={20} eventKey={20}>20</Dropdown.Item>
                                                    <Dropdown.Item key={50} eventKey={50}>50</Dropdown.Item>
                                                    <Dropdown.Item key={100} eventKey={100}>100</Dropdown.Item>
                                                    <Dropdown.Item key={1000} eventKey={1000}>1000</Dropdown.Item>
                                                    <Dropdown.Item key={10000} eventKey={10000}>10000</Dropdown.Item>
                                                </DropdownButton>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Button color="primary" onClick={(event) => {event.preventDefault(); this.filter(this.state.page);}}>
                                        {Localization.Get("log.filterSubmit")}
                                    </Button>
                                </Form><br/>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{Localization.Get("log.date")}</th>
                                        <th>{Localization.Get("log.class")}</th>
                                        <th>{Localization.Get("log.level")}</th>
                                        <th>{Localization.Get("log.text")}</th>
                                        <th>{Localization.Get("log.detail")}</th>
                                        <th>{Localization.Get("log.userId")}</th>
                                        <th>{Localization.Get("log.username")}</th>
                                        <th>{Localization.Get("log.duration")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {entryList}
                                    </tbody>
                                </Table>
                                <Pagination>
                                    {pagination}
                                </Pagination>
                            </div> }
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                <ToastContainer/>
             </Container>
        );
    }

    onSubmitForm(event) {
        event.preventDefault();
    }

    pageChange(page) {
        alert(page);
    }

    filter(page) {
        this.filterWithPageLength(page, this.state.pageLength);
    }

    filterWithPageLength(page, pageLength) {
        this.setState({loaded: false});
        const dateFrom = (this.state.from === null || this.state.from === undefined) ? "" : (this.state.from.getUTCDate() + 1) + "." + (this.state.from.getUTCMonth() + 1) + "." + this.state.from.getUTCFullYear();
        const dateTo = (this.state.to === null || this.state.to === undefined) ? "" : (this.state.to.getUTCDate() + 1) + "." + (this.state.to.getUTCMonth() + 1) + "." + this.state.to.getUTCFullYear();
        rest({path: '/api/admin/log', method: 'PATCH', body: {dateFrom: dateFrom, dateTo: dateTo, page: page, pageLength: pageLength}},
             (data) => {
                 if (data.result === 1) {
                     this.setState({entries: data.entries});
                     this.setState({page: data.page});
                     this.setState({pages: data.pages});
                     this.setState({loaded: true});
                 }
             });
    }

    setPageSize(key, event) {
        const oldPageLength = this.state.pageLength;
        const newPageLength = parseInt(key);
        this.setState({pageLength: newPageLength});
        if (key === '10') this.setState({pageLengthTitle: 10});
        if (key === '20') this.setState({pageLengthTitle: 20});
        if (key === '50') this.setState({pageLengthTitle: 50});
        if (key === '100') this.setState({pageLengthTitle: 100});
        if (key === '1000') this.setState({pageLengthTitle: 1000});
        if (key === '10000') this.setState({pageLengthTitle: 10000});
        const newPage = (this.state.page + 1) * oldPageLength / newPageLength - 1;
        this.setState({page: newPage});
        this.filterWithPageLength(newPage, newPageLength);
    }

}

export default Log;