import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import {ToastContainer} from 'react-toastify';
import Table from "react-bootstrap/Table";
import {Button} from "reactstrap";
import {toastError, toastSuccess} from "../public/Toaster";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import Footer from "../public/Footer";

class SubmissionGrades extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            submission: undefined,
            submissionLoaded: false,
            gradings: [], // it is gradings[student], points coming from the submission correction
            gradingLines: [], // it is a property of part for which the file was submitted
            gradesValues: [], // it is gradesValues[student], values from Grades
            newValues: [], // it is newValues[student], values calculation from corrections aggregated for Grades
            targetValues: [],
            saveTarget: [], // it is saveTarget[student]
            refresh: 0,
            writingToGrades: false,
            counter: this.counter(),
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            if (!this.state.submissionLoaded) {
                this.loadSubmissionData();
            }
        })();
    }

    makeGradingLines(submission) {
        const gradingLines = [];
        for (let i = 0; i < submission.parts.length; i++) {
            gradingLines[gradingLines.length] = {
                nameCz: submission.parts[i].nameCz,
                nameEn: submission.parts[i].nameEn,
                contributesTo: submission.parts[i].contributesTo,
                contributesToNameCz: submission.parts[i].contributesToNameCz,
                contributesToNameEn: submission.parts[i].contributesToNameEn,
                minimumValue: submission.parts[i].minimumValue,
                maximumValue: submission.parts[i].maximumValue,
            }
        }
        return gradingLines;
    }

    loadSubmissionData() {
        rest({path: '/api/teacher/submission/' + this.props.params.submissionId + "/" + this.props.params.submissionToken, method: 'get'},
             (submissionData) => {
                 if (submissionData.result === 1 && !this.state.submissionLoaded) {
                     const gradings = [];
                     const values = [];
                     const newValues = [];
                     const saveTarget = [];
                     const gradedStudents = new Set();

                     let studentsChanged = "";
                     const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
                     for (let i = 0; i < sequenceListCount; i++) {
                         if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                             studentsChanged = window.sessionStorage.getItem("submissionListChanged" + i);
                         }
                     }

                     for (let i = 0; i < submissionData.gradings.length; i++) {
                         const grading = submissionData.gradings[i];
                         if (!gradedStudents.has(grading.student)) {
                             saveTarget[grading.student] = false;
                             if (studentsChanged.includes(grading.student)) saveTarget[grading.student] = undefined; // means checked
                             gradedStudents.add(grading.student);
                             const points = [];
                             for (let i = 0; i < submissionData.parts.length; i++) {
                                 points[i] = 0;
                             }
                             values[grading.student] = points;
                         }
                         values[grading.student][grading.part - 1] = {points: grading.points, gradedBy: grading.gradedBy};
                     }

                     const gradingLines = this.makeGradingLines(submissionData);

                     gradedStudents.forEach(student => {
                         newValues[student] = this.getContributions(values[student], gradingLines);
                         this.readGradesData(submissionData.semesterCode, submissionData.courseCode, student);
                         this.state.counter.plus();
                     });

                     for (let k = 0; k < submissionData.students.length; k++) {
                         const student = submissionData.students[k];
                         if (values[student.username] !== undefined) {
                             gradings[gradings.length] = {
                                 username: student.username,
                                 name: student.name,
                                 grading: values[student.username],
                             }

                         }
                     }

                     this.setState({
                                        submission: submissionData,
                                        submissionLoaded: true,
                                        gradings: gradings,
                                        gradingLines: gradingLines,
                                        newValues: newValues,
                                        gradesValues: [],
                                        targetValues: [],
                                        saveTarget: saveTarget,
                                   });
                 }
             });
    }

    counter() {
        let count = 0;
        return {
            plus: () => {
                count++;
            },
            minus: () => {
                count--;
            },
            get: () => {
                return count;
            }
        }
    }

    readGradesData(semesterCode, courseCode, username) {
        rest({
                 path: '/api/teacher/grades/' + semesterCode + '/' + courseCode + '/' + username, method: 'get'
             },
             (data) => {
                 const gradesValues = this.state.gradesValues;
                 const targetValues = this.state.targetValues;
                 if (data.result === 1) {
                     gradesValues[username] = {
                         error: false,
                         milestones: data.milestones,
                     }
                     targetValues[username] = JSON.parse(JSON.stringify(gradesValues[username])); // clone
                 } else {
                     const gradesValues = this.state.gradesValues;
                     gradesValues[username] = {error: true};
                     targetValues[username] = {error: true};
                 }
                 this.state.counter.minus();
                 this.setState({gradesValues: gradesValues, targetValues: targetValues});
             });
    }

    getGradesValues(username) {
        if (this.state.gradesValues[username] === undefined) return <div></div>
        if (this.state.gradesValues[username].error) return <div>(error)</div>
        if (this.state.newValues[username] === undefined) return <div></div>
        const values = [];
        for (let i = 0; i < this.state.newValues[username].length; i++) {
            for (let j = 0; j < this.state.gradesValues[username].milestones.length; j++) {
                if (this.state.newValues[username][i].identifier === this.state.gradesValues[username].milestones[j].identifier) {
                    values[values.length] = {
                        milestone: this.state.gradesValues[username].milestones[j],
                        name: this.state.newValues[username][i].name,
                    }
                }
            }
        }
        return values.map(contribution => {
            let value;
            if (contribution.milestone.evaluationType === "NUMBER") value = contribution.milestone.doubleValue;
            if (contribution.milestone.evaluationType === "STRING") value = contribution.milestone.stringValue;
            if (contribution.milestone.evaluationType === "BOOLEAN") {
                if (contribution.milestone.booleanValue) {
                    value = <input type="checkbox" checked disabled />;
                } else {
                    value = <input type="checkbox" disabled />;
                }
            }
            return <tr key={contribution.milestone.identifier}><td>{contribution.name}:</td><td><b>{value}</b></td></tr>;
        })
    }

    sumTogether() {
        this.aggregate("sum");
    }

    useNewValues() {
        this.aggregate("new");
    }

    aggregate(operation) {
        const targetValues = this.state.targetValues;
        this.state.gradings.forEach(grading => {
            const username = grading.username;
            if (this.state.newValues[username] !== undefined && this.state.gradesValues[username] !== undefined) {
                for (let i = 0; i < this.state.newValues[username].length; i++) {
                    for (let j = 0; j < targetValues[username].milestones.length; j++) {
                        if (this.state.newValues[username][i].identifier === targetValues[username].milestones[j].identifier) {
                            if (targetValues[username].milestones[j].evaluationType === "NUMBER") {
                                if (operation === "new") {
                                    targetValues[username].milestones[j].doubleValue = this.state.newValues[username][i].value;
                                } else {
                                    if (this.state.gradesValues[username].milestones[j].doubleValue === null || this.state.gradesValues[username].milestones[j].doubleValue === undefined || isNaN(this.state.gradesValues[username].milestones[j].doubleValue)) {
                                        targetValues[username].milestones[j].doubleValue = this.state.newValues[username][i].value;
                                    } else if (this.state.newValues[username][i].value === null || this.state.newValues[username][i].value === undefined || isNaN(this.state.newValues[username][i].value)) {
                                        targetValues[username].milestones[j].doubleValue = this.state.gradesValues[username].milestones[j].doubleValue;
                                    } else {
                                        targetValues[username].milestones[j].doubleValue = this.state.gradesValues[username].milestones[j].doubleValue + this.state.newValues[username][i].value;
                                    }
                                }
                            }
                            if (targetValues[username].milestones[j].evaluationType === "STRING") {
                                if (operation === "new") {
                                    targetValues[username].milestones[j].stringValue = (this.state.newValues[username][i].value).toString();
                                } else {
                                    if (this.state.gradesValues[username].milestones[j].stringValue === null || this.state.gradesValues[username].milestones[j].stringValue === undefined || isNaN(parseFloat(this.state.gradesValues[username].milestones[j].stringValue))) {
                                        targetValues[username].milestones[j].stringValue = this.state.newValues[username][i].value.toString();
                                    } else if (this.state.newValues[username][i].value === null || this.state.newValues[username][i].value === undefined || isNaN(this.state.newValues[username][i].value)) {
                                        targetValues[username].milestones[j].stringValue = parseFloat(this.state.gradesValues[username].milestones[j].stringValue);
                                    } else {
                                        targetValues[username].milestones[j].stringValue = (parseFloat(this.state.gradesValues[username].milestones[j].stringValue) + this.state.newValues[username][i].value).toString();
                                    }
                                }
                            }
                            if (targetValues[username].milestones[j].evaluationType === "BOOLEAN") {
                                if (operation === "new") {
                                    targetValues[username].milestones[j].booleanValue = (this.state.newValues[username][i].value !== 0);
                                } else {
                                    targetValues[username].milestones[j].booleanValue = (this.state.gradesValues[username].milestones[j].booleanValue || (this.state.newValues[username][i].value !== 0));
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState({targetValues: targetValues});
    }

    getTargetValues(username) {
        if (this.state.targetValues[username] === undefined) return <div></div>
        if (this.state.targetValues[username].error) return <div>(error)</div>
        if (this.state.newValues[username] === undefined) return <div></div>
        const values = [];
        for (let i = 0; i < this.state.newValues[username].length; i++) {
            for (let j = 0; j < this.state.targetValues[username].milestones.length; j++) {
                if (this.state.newValues[username][i].identifier === this.state.targetValues[username].milestones[j].identifier) {
                    values[values.length] = {
                        milestone: this.state.targetValues[username].milestones[j],
                        name: this.state.newValues[username][i].name,
                        username: username,
                    }
                }
            }
        }
        return values.map(contribution => {
            let value = "";
            let limit = "";
            if (contribution.milestone.evaluationType === "NUMBER") {
                value = <input type="number" value={contribution.milestone.doubleValue} min={contribution.milestone.minimumValue} max={contribution.milestone.maximumValue}
                               onChange={(e) => this.updateTarget(contribution.username, contribution.milestone.identifier, e.target.value)} />;
                limit = "(" + contribution.milestone.minimumValue + " <= x <= " + contribution.milestone.maximumValue + ")";
            }
            if (contribution.milestone.evaluationType === "STRING") {
                value = <input type="text" value={contribution.milestone.stringValue}
                               onChange={(e) => this.updateTarget(contribution.username, contribution.milestone.identifier, e.target.value)} />;
            }
            if (contribution.milestone.evaluationType === "BOOLEAN") {
                value = <input type="checkbox" checked={contribution.milestone.booleanValue} onChange={(e) => this.updateTarget(contribution.username, contribution.milestone.identifier, e.target.checked)} />;
            }
            return <tr key={contribution.milestone.identifier}><td>{contribution.name}:</td><td><b>{value}</b></td><td>{limit}</td></tr>;
        })
    }

    updateTarget(username, identifier, value) {
        const targetValues = this.state.targetValues;
        for (let m = 0; m < targetValues[username].milestones.length; m++) {
            if (targetValues[username].milestones[m].identifier === identifier) {
                const milestone = targetValues[username].milestones[m];
                if (milestone.evaluationType === "NUMBER") milestone.doubleValue = isNaN(parseFloat(value)) ? (milestone.minimumValue === null ? 0 : milestone.minimumValue) : parseFloat(value);
                if (milestone.evaluationType === "BOOLEAN") milestone.booleanValue = value;
                if (milestone.evaluationType === "STRING") milestone.stringValue = value;
            }
        }
        this.setState({targetValues: targetValues});
    }

    getResultList() {

        const gradingLines = this.state.gradingLines;

        return this.state.gradings.map(grading => {
            const grades = this.getGradesValues(grading.username);
            const targets = this.getTargetValues(grading.username);
            let gradesError = false;
            if (this.state.gradesValues[grading.username] !== undefined) {
                if (this.state.gradesValues[grading.username].error) gradesError = true;
            }
            return <tr key={grading.username}>
                <td>{grading.name}</td>
                <td><table>{grades}</table></td>
                <td><table>{this.getNewValues(grading.grading, gradingLines)}<tr><td>&nbsp;</td><td></td><td></td></tr>{this.getGrading(grading.username, grading.grading, grading.gradedBy, gradingLines)}</table></td>
                <td><table>{targets}</table>
                    <br/>
                    {this.state.gradesValues[grading.username] === undefined ? <div>
                        <input type="checkbox" disabled={true} checked={false} />&nbsp;
                        {Localization.Get("submissionGrading.saveThisOne")}</div> : <div>
                        <input type="checkbox" disabled={gradesError} checked={!gradesError && this.state.saveTarget[grading.username] !== false} onChange={(e) => this.setSaveTarget(grading.username, e.target.checked)} />&nbsp;
                        {Localization.Get("submissionGrading.saveThisOne")}</div>}
                </td>
            </tr>
        });
    }

    setSaveTarget(username, checked) {
        const saveTarget = this.state.saveTarget;
        if (checked) {
            saveTarget[username] = undefined;
        } else {
            saveTarget[username] = false;
        }
        this.setState({saveTarget: saveTarget});
    }

    selectNone() {
        const saveTarget = this.state.saveTarget;
        this.state.gradings.forEach(grading => {
            saveTarget[grading.username] = false;
        });
        this.setState({saveTarget: saveTarget});
    }

    selectAll() {
        const saveTarget = this.state.saveTarget;
        this.state.gradings.forEach(grading => {
            saveTarget[grading.username] = undefined;
        });
        this.setState({saveTarget: saveTarget});
    }

    canSave() {
        let canSave = false;
        this.state.gradings.forEach(grading => {
            let gradesError = false;
            if (this.state.gradesValues[grading.username] !== undefined) {
                if (this.state.gradesValues[grading.username].error) gradesError = true;
            }
            if (this.state.saveTarget[grading.username] !== false && !gradesError) canSave = true;
        });
        return canSave;
    }

    getGrading(username, grading, gradedBy, gradingLines) {
        const lines = this.getLines(grading, gradingLines);
        return lines.map(line => {
            return <tr key={lines.key}><td>{line.caption}:</td><td><b>{line.value}</b></td><td>(&#10132; {line.contributesToName}) (@ {line.gradedBy})</td></tr>
        });
    }

    getContributions(grading, gradingLines) {
        const lines = this.getLines(grading, gradingLines);
        const contributions = [];
        for (let i = 0; i < lines.length; i++) {
            let found = false;
            for (let j = 0; j < contributions.length; j++) {
                if (lines[i].contributesTo === contributions[j].identifier) {
                    found = true;
                    if (!isNaN(lines[i].value)) contributions[j].value = contributions[j].value + lines[i].value;
                }
            }
            if (!found) {
                contributions[contributions.length] = {
                    identifier: lines[i].contributesTo,
                    name: lines[i].contributesToName,
                    value: lines[i].value,
                }
            }
        }
        return contributions;
    }

    getNewValues(grading, gradingLines) {
        const contributions = this.getContributions(grading, gradingLines);
        return contributions.map(contribution => {
            return <tr key={contribution.identifier}><td>{contribution.name}:</td><td></td><td><b>{contribution.value}</b></td></tr>
        })
    }

    getLines(grading, gradingLines) {
        const lines = [];
        for (let i = 0; i < grading.length && i < gradingLines.length; i++) {
            lines[lines.length] = {
                key: i,
                caption: (i + 1) + ": " + (Localization.GetLanguage() === "CZ" ? gradingLines[i].nameCz : gradingLines[i].nameEn),
                value: grading[i].points,
                gradedBy: grading[i].gradedBy,
                contributesTo: gradingLines[i].contributesTo,
                contributesToName: Localization.GetLanguage() === "CZ" ? gradingLines[i].contributesToNameCz : gradingLines[i].contributesToNameEn,
                minimumValue: gradingLines[i].minimumValue,
                maximumValue: gradingLines[i].maximumValue,
            }
        }
        return lines;
    }

    saveChanges() {

        const milestones = [];
        const targetValues = this.state.targetValues;

        this.state.gradings.forEach(grading => {
            const username = grading.username;
            if (targetValues[username] !== undefined && !targetValues[username].error && this.state.saveTarget[grading.username] !== false
                && !this.state.gradesValues[username].error) {
                for (let i = 0; i < this.state.newValues[username].length; i++) {
                    for (let j = 0; j < targetValues[username].milestones.length; j++) {
                        if (this.state.newValues[username][i].identifier === targetValues[username].milestones[j].identifier) {
                            targetValues[username].milestones[j].username = username;
                            milestones[milestones.length] = targetValues[username].milestones[j];
                        }
                    }
                }
            }
        })

        this.setState({writingToGrades: true, targetValues: targetValues});
        toastSuccess(Localization.Get("submissionGrading.saving"));
        rest({
                 path: '/api/teacher/grades/' + this.state.submission.semesterCode + '/' + this.state.submission.courseCode,
                 method: 'put', body: milestones,
             },
             (data) => {
                 this.setState({writingToGrades: false});
                 if (data.result === 1) {
                     const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
                     for (let i = 0; i < sequenceListCount; i++) {
                         if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                             window.sessionStorage.setItem("submissionListChanged" + i, "");
                         }
                     }
                     this.setState({submissionLoaded: false});
                     this.loadSubmissionData();
                     toastSuccess(Localization.Get("submissionGrading.saveCorrectionSuccess"));
                 } else {
                     toastError(Localization.Get("submissionGrading.saveCorrectionError"));
                 }
             });
    }

    getDescription() {
        if (Localization.GetLanguage() === "CZ") {
            return this.state.submission.descriptionCz;
        } else {
            return this.state.submission.descriptionEn;
        }
    }

    getPreviousSubmission() {
        const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
        let previousSubmissionId = null;
        let previousSubmissionToken = null;
        for (let i = 0; i < sequenceListCount; i++) {
            if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                if (previousSubmissionId == null) return null;
                return {id: previousSubmissionId, token: previousSubmissionToken};
            } else {
                previousSubmissionId = window.sessionStorage.getItem("submissionListId" + i);
                previousSubmissionToken = window.sessionStorage.getItem("submissionListToken" + i);
            }
        }
        return null;
    }

    getNextSubmission() {
        const sequenceListCount = parseInt(window.sessionStorage.getItem("submissionListCount"));
        for (let i = 0; i < sequenceListCount; i++) {
            if (this.props.params.submissionId === window.sessionStorage.getItem("submissionListId" + i)) {
                if (i < sequenceListCount - 1) return {id: window.sessionStorage.getItem("submissionListId" + (i + 1)),
                    token: window.sessionStorage.getItem("submissionListToken" + (i + 1))};
            }
        }
        return null;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        if (this.state.submissionLoaded && this.state.counter.get() === 0) {
            return (
                <Container>
                    <Navigation /><br/>
                    {this.state.isLanguageInitialized ? <div>
                        <View style={{alignItems: 'flex-end'}}>
                            <ButtonGroup>
                                <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                                <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                            </ButtonGroup>
                        </View>
                    </div> : "" }
                    <Row>
                        <Col md={12}>
                            {this.state.isLanguageInitialized ? <div>
                                <h1>{Localization.Get("main.title")}</h1>
                                <h2>{Localization.Get("submissionGrading.title")}</h2><br/>
                                <h3>{Localization.Get("submissionGrading.results") + " / " + this.state.submission.semesterCode
                                     + " / " + this.state.submission.courseCode + " / " + this.state.submission.parallelCode + " - " + this.getDescription() + " / " + this.props.params.submissionId}</h3><br/>
                            </div> : <LoadingSpinner />}
                            <h4>{Localization.Get("submissionGrading.resultList")}</h4>
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>{Localization.Get("submissionGrading.student")}</th>
                                    <th>{Localization.Get("submissionGrading.currentGrades")}</th>
                                    <th>{Localization.Get("submissionGrading.loadedGrading")}</th>
                                    <th>{Localization.Get("submissionGrading.targetGrades")}&nbsp;
                                        <Button color="success" onClick={() => {this.selectNone();}}>{Localization.Get("submissionGrading.selectNone")}</Button>&nbsp;
                                        <Button color="success" onClick={() => {this.selectAll();}}>{Localization.Get("submissionGrading.selectAll")}</Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.getResultList()}
                                </tbody>
                            </Table>
                            <Button disabled={!this.canSave() || this.state.writingToGrades} color="success" onClick={() => {this.saveChanges();}}>{Localization.Get("submissionGrading.saveChanges")}</Button>&nbsp;
                            <Button color="info" onClick={() => {this.useNewValues();}}>{Localization.Get("submissionGrading.useNewValues")}</Button>&nbsp;
                            <Button color="info" onClick={() => {this.sumTogether();}}>{Localization.Get("submissionGrading.sumTogether")}</Button>&nbsp;
                            <Button color="primary" tag={Link} to={"/teachersubmissions/" + this.state.submission.semesterCode + "/" + this.state.submission.courseCode + "/" + this.state.submission.parallelCode+ "/" + this.state.submission.parallelId}>
                                {Localization.Get("submissionGrading.goBack")}</Button>&nbsp;
                            <Button color="warning" tag={Link} to={"/submissioncorrection/" + this.props.params.submissionId + "/" + this.props.params.submissionToken + "/0/0"}>
                                {Localization.Get("submissionGrading.goCorrection")}</Button>&nbsp;
                            {this.getPreviousSubmission() !== null ?
                             <Button color="primary" onClick={() => {window.location = "/submissiongrades/" + this.getPreviousSubmission().id + "/" + this.getPreviousSubmission().token;}}>
                                 &#10094; {Localization.Get("submissionGrading.previousSubmission")}</Button>
                                                                   : ""}
                            {this.getPreviousSubmission() !== null ? " " : ""}
                            {this.getNextSubmission() !== null ?
                             <Button color="primary" onClick={() => {window.location = "/submissiongrades/" + this.getNextSubmission().id + "/" + this.getNextSubmission().token;}}>
                                 {Localization.Get("submissionGrading.nextSubmission")} &#10095;</Button>
                                                               : ""}
                            {this.state.writingToGrades ? <div><br/><LoadingSpinner /></div> : <div><br/><br/></div>}
                        </Col>
                    </Row>
                    <Footer />
                    <ToastContainer/>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Navigation /><br/>
                    {this.state.isLanguageInitialized ? <div>
                        <View style={{alignItems: 'flex-end'}}>
                            <ButtonGroup>
                                <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                                <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                            </ButtonGroup>
                        </View>
                    </div> : "" }
                    <Row>
                        <Col md={12}>
                            {this.state.isLanguageInitialized ? <div>
                                <h1>{Localization.Get("main.title")}</h1>
                                <h2>{Localization.Get("submissionGrading.title")}</h2><br/>
                                <h3>{Localization.Get("submissionGrading.results")}</h3><br/>
                            </div> : <LoadingSpinner />}
                            <LoadingSpinner />
                        </Col>
                    </Row>
                    <Footer />
                    <ToastContainer/>
                </Container>
            );
        }
    }
}

export default SubmissionGrades;