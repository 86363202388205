import React from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Localization from './Localization';

export default function toastIt(result) {
    if (result.text === undefined || result.result === undefined) return;
    if (result.text === "") return;
    const type = result.result === 1 ? "success" : "error";
    toast(Localization.Get(result.text), {
        type: type,
        theme: "colored",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function toastSuccess(text) {
    toastIt({result: 1, text: text});
}

export function toastError(text) {
    toastIt({result: 0, text: text});
}
