import Localization from '../public/Localization';

export default function checkIt(password) {
    if (password.match(/[A-Z]/) == null) {
        return Localization.Get("checkPassword.missingUpperCase");
    }
    if (password.match(/[a-z]/) == null) {
        return Localization.Get("checkPassword.missingLowerCase");
    }
    if (password.match(/[0-9]/) == null) {
        return Localization.Get("checkPassword.missingNumeric");
    }
    let specialPresent = false;
    for (let i = 0; i < password.length; i++) {
        const letter = password.substring(i, i + 1);
        if (letter.match(/[A-Z]/) == null && letter.match(/[a-z]/) == null && letter.match(/[0-9]/) == null) {
            specialPresent = true;
        }
    }
    if (!specialPresent) {
        return Localization.Get("checkPassword.missingSpecial");
    }
    if (password.length < 8) {
        return Localization.Get("checkPassword.tooShort");
    }
    return "";
}
