import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import {toastError, toastSuccess} from "../public/Toaster";
import {ToastContainer} from "react-toastify";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Constants from "../user/Constants";
import Footer from "../public/Footer";

class NewCodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            fileLoaded: false,
            students: 1,
            parts: 1,
            file: undefined,
            fileBeingGenerated: false,
            generationEnabled: true,
            refresh: 0,
            comment: "",
            filesLoaded: false,
            files: [],
            showing: 10,
        }
        this.generateFile = this.generateFile.bind(this);
        this.getFile = this.getFile.bind(this);
        this.showMore = this.showMore.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/teacher/qrcodefile', method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({files: data.files, filesLoaded: true});
                     }
                 });
        })();
    }

    getFile() {
        const file = new Blob([this.binaryStringToArray(atob(this.state.file))], {type: 'application/pdf'});
        window.open(window.URL.createObjectURL(file));
    }

    binaryStringToArray(binary) {
        const array = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) array[i] = binary.charCodeAt(i);
        return array;
    }

    showMore() {
        this.setState({showing: 2 * this.state.showing});
    }

    generateFile() {
        rest({path: '/api/teacher/qrcodefile',
                 body: {students: this.state.students, parts: this.state.parts, comment: this.state.comment}, method: 'post'},
             (data) => {
                 if (data.result === 1) {
                     this.setState({fileBeingGenerated: false, fileLoaded: true, files: data.files});
                     toastSuccess("newCodes.fileLoaded");
                 } else {
                     this.setState({fileBeingGenerated: false});
                     toastError("newCodes.fileLoadedFailed");
                 }
             });
        this.setState({fileBeingGenerated: true, fileLoaded: false});
    }

    handleStudentsChange(e) {
        const students = isNaN(parseInt(e.target.value)) ? 1 : parseInt(e.target.value);
        this.setState({students: students});
        this.checkLimits(this.state.parts * students);
    }

    handlePartChange(e) {
        const parts = isNaN(parseInt(e.target.value)) ? 1 : parseInt(e.target.value);
        this.setState({parts: parts});
        this.checkLimits(this.state.students * parts);
    }

    checkLimits(value) {
        if (value > 18000) {
            if (this.state.generationEnabled) {
                this.setState({generationEnabled: false});
                toastError("newCodes.maxLimit");
            }
        } else {
            if (!this.state.generationEnabled) {
                this.setState({generationEnabled: true});
            }
        }
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        let firstShown = 0;

        if (this.state.files.length > this.state.showing) {
            firstShown = this.state.files[this.state.files.length - this.state.showing].id;
        }

        const fileList = this.state.files.map(file => {

            if (file.id < firstShown) {
                return "";
            } else {
                return <tr key={file.id}>
                    <td>{file.id}</td>
                    <td>{Localization.GetLanguage() === "CZ" ? file.dateCz : file.dateEn}</td>
                    <td>{file.students}</td>
                    <td>{file.parts}</td>
                    <td>{file.startOfSequence === null ? "" : file.startOfSequence}</td>
                    <td>{file.startOfSequence === null ? "" : parseInt(file.startOfSequence) + parseInt(file.students) - 1}</td>
                    <td>{file.comment}</td>
                    <td>{file.token === "" ? Localization.Get("newCodes.pending") : <Button size="sm" color="success" onClick={() => {window.open(Constants.GetServer() + "/api/teacher/qrcodefiledownload/" + file.id + "/" + file.token)}}>
                        {Localization.Get("newCodes.download")}</Button>}</td>
                </tr>
            }

        });

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("newCodes.title")}</h2><br/>
                            <h3>{Localization.Get("newCodes.createPages")}</h3>
                            { this.state.parallelsLoaded === false ? <LoadingSpinner /> : <div>
                                <label htmlFor={"students"}>{Localization.Get("newCodes.students")}</label>&nbsp;
                                <input id={"students"} type={"number"} min={1} max={1500} value={this.state.students} onChange={(e) => this.handleStudentsChange(e)} /> (s &lt;= 1500, s * p &lt;= 18000)
                                <br/><br/>
                                <label htmlFor={"parts"}>{Localization.Get("newCodes.parts")}</label>&nbsp;
                                <input id={"parts"} type={"number"} min={1} max={100} value={this.state.parts} onChange={(e) => this.handlePartChange(e)} /> (p &lt;= 100, s * p &lt;= 18000)
                                <br/>&nbsp;<br/>
                                {Localization.Get("newCodes.comment")}
                                <textarea className="form-control" id="comment" placeholder="" value={this.state.comment}
                                          onChange={event => {
                                              this.setState({comment: event.target.value})
                                          }}
                                          rows={5}/><br/>
                                </div> }
                            {(this.state.fileBeingGenerated || !this.state.generationEnabled) ? "" : <Button color="success" onClick={this.generateFile}>{Localization.Get("newCodes.generateFile")}</Button>}
                            <p>&nbsp;</p>
                            {this.state.fileBeingGenerated ? <LoadingSpinner /> : ""}
                            {this.state.filesLoaded ?
                             <div>
                                 <h3>{Localization.Get("newCodes.createdFiles")}</h3>
                                 {this.state.files.length <= this.state.showing ? "" :  <div><Button color="success" onClick={this.showMore}>{Localization.Get("newCodes.showMore")}</Button><br/><br/></div>}
                             <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>{Localization.Get("newCodes.id")}</th>
                                    <th>{Localization.Get("newCodes.date")}</th>
                                    <th>{Localization.Get("newCodes.studentsTable")}</th>
                                    <th>{Localization.Get("newCodes.partsTable")}</th>
                                    <th>{Localization.Get("newCodes.startOfSequence")}</th>
                                    <th>{Localization.Get("newCodes.endOfSequence")}</th>
                                    <th>{Localization.Get("newCodes.commentTable")}</th>
                                    <th>{Localization.Get("newCodes.operations")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {fileList}
                                </tbody>
                             </Table></div> : "" }
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />
                        }
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default NewCodes;