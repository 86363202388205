import React, {Component} from 'react';
import '../App.css';
import rest from "./Rest";

class LoginBlocked extends Component {

    componentDidMount() {
        (() => {
            rest({path: '/api/public/csrf', method: 'get'}, (data) => {
                if (data.result === 1) {
                    window.sessionStorage.setItem('CSRF_TOKEN', data.csrfToken);
                    window.sessionStorage.setItem('CSRF_HEADER', data.csrfHeader);
                }
                (() => {window.location = "/";})();
            });
        })();
    }

    render() {
        return (<div/>);
    }

}

export default LoginBlocked;