import React from "react";

export default function uploadFile(file, submissionId, remoteFileId, that, completed, failed, progress, canContinue) {
    console.log(file.name + " " + submissionId + " " + remoteFileId);

    const send = async (source) => {
        return new Promise(function (resolve, reject) {
            const size = source.data.size;
            try {
                const formData = new FormData();
                formData.append("fromByte", source.fromByte);
                formData.append("filename", file.name);
                formData.append("file", source.data);
                const csrfHeaderName = window.sessionStorage.getItem('CSRF_HEADER');
                const csrfToken = window.sessionStorage.getItem('CSRF_TOKEN');
                let csrfHeader = {};
                csrfHeader[csrfHeaderName] = csrfToken;
                fetch('/api/user/fileupload/' + submissionId + '/' + remoteFileId, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        ...csrfHeader,
                    },
                    body: formData,
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.error("Call error: " + response.status);
                    }
                }).then(data => {
                    if (data.result === 1) {
                        resolve([size, true, source.fromFrame, source.toFrame, size]);
                    } else {
                        resolve([0, false, source.fromFrame, source.toFrame, size]);
                    }
                }).catch((error) => {
                    resolve([0, false, source.fromFrame, source.toFrame, size]);
                });
            } catch (e) {
                resolve([0, false, source.fromFrame, source.toFrame, size]);
            }
        })
    }

    const fileUpload = async (getNewData, updateControl) => {
        const unit = 8 * 1024 * 1024; // 8 MB
        let confirmedTotalMB = 0;
        let confirmedTotalFiles = 0;
        let unprocessedFiles = 0;
        let finished = false;
        while (!finished && canContinue(that)) {
            const maxUnprocessedFiles = 4;
            if (unprocessedFiles < maxUnprocessedFiles) {
                const source = getNewData(unit);
                finished = source.finished;
                if (!finished && source.fromFrame !== source.toFrame) {
                    unprocessedFiles++;
                    send(source).then(function ([confirmed, result, fromFrame, toFrame, portionSize]) {
                        updateControl(result, fromFrame, toFrame, portionSize);
                        unprocessedFiles--;
                        confirmedTotalMB = confirmedTotalMB + confirmed;
                        confirmedTotalFiles++;
                    });
                }
            }
            await new Promise((resolve, reject) => setTimeout(resolve, 10));
        }
    }

    const transmitFile = async (progress, completed, failed) => {
        const id1 = 0;
        const id2 = 0;
        const mainBlob = new Blob([file], { type: "application/octet-stream" });
        const size = mainBlob.size;
        let fileControl;
        if (size > 0) {
            let confirmed = 0;
            const unit = 8 * 1024 * 1024;
            const max = Math.ceil(size / unit);
            fileControl = Array(max);
            for (let i = 0; i < max; i++) {
                fileControl[i] = 0;
            }
            // 0 - not transmitted, 1 - transmitted, 2 - being transmitted
            let allSent = false;
            let chunksInProcess = 0;
            if (size !== confirmed) {
                await fileUpload(function(bytes) { // getNewData
                    let pointer = 0;
                    // searching for first byte
                    while (fileControl[pointer] !== 0 && pointer < max) pointer++;
                    if (pointer === max) {
                        // nothing to send right now
                        pointer = 0;
                        while (fileControl[pointer] === 1 && pointer < max) pointer++;
                        if (pointer === max) {
                            // all done
                            const finished = true;
                            const data = null;
                            const fromFrame = 0;
                            const toFrame = 0;
                            const fromByte = 0;
                            return ({finished, data, fromFrame, toFrame, id1, id2, fromByte});
                        } else {
                            // waiting for confirmation
                            const finished = false;
                            const data = null;
                            const fromFrame = 0;
                            const toFrame = 0;
                            const fromByte = 0;
                            return ({finished, data, fromFrame, toFrame, id1, id2, fromByte});
                        }
                    }
                    // pointer is the first byte
                    const fromFrame = pointer;
                    const fromByte = pointer * unit;
                    fileControl[pointer] = 2;
                    pointer++;
                    const toFrame = pointer;
                    let toByte = pointer * unit;
                    if (toByte > size) toByte = size;
                    const finished = false;
                    const data = mainBlob.slice(fromByte, toByte);
                    if (toByte !== fromByte) chunksInProcess++;
                    return ({finished, data, fromFrame, toFrame, id1, id2, fromByte});
                }, function(result, fromFrame, toFrame, portionSize) {
                    if (portionSize !== 0) chunksInProcess--;
                    if (result) {
                        fileControl[fromFrame] = 1;
                        confirmed = confirmed + portionSize;
                        progress(portionSize);
                        if (confirmed === size) allSent = true;
                    } else {
                        fileControl[fromFrame] = 0;
                    }
                });
                while ((!allSent && canContinue(that)) || chunksInProcess !== 0) await new Promise((resolve, reject) => setTimeout(resolve, 250));
            }
            if (confirmed === size) {
                completed();
            } else {
                failed();
            }
        } else {
            completed();
        }
        return "done";
    }

    transmitFile((value) => progress(value, that), () => completed(that), () => failed(that))
        .then(() => {})
        .catch(() => {failed();})
        .finally(() => {});

}

