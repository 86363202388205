import React, {Component} from 'react';
import Localization from "../public/Localization";
import Constants from "../user/Constants";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ToastContainer} from 'react-toastify';
import {Link} from "react-router-dom";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import Footer from "../public/Footer";

class StudentSubmissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            submissions: [],
            submissionsLoaded: false,
            nameCz: " / ",
            nameEn: " / ",
            refresh: 0,
            canSubmit: false,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/student/submission/' + this.props.params.semesterCode + "/" + this.props.params.courseCode
                     + "/" + this.props.params.parallelId + "/" + this.props.params.milestoneIdentifier, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({submissions: data.submissions, nameCz: " - " + data.descriptionCz + " / " + data.nameCz, nameEn: " - " + data.descriptionEn + " / " + data.nameEn});
                        this.setState({submissionsLoaded: true});
                        if (data.canSubmit === true) this.setState({canSubmit: true});
                    }
                });
        })();
    }

    getSubmissionState(state) {
        if (state === "Q") return Localization.Get("submissionState.requested");
        if (state === "S") return Localization.Get("submissionState.submitted");
        if (state === "N") return Localization.Get("submissionState.converted");
        if (state === "F") return Localization.Get("submissionState.conversionFailed");
        if (state === "C") return Localization.Get("submissionState.corrected");
        if (state === "R") return Localization.Get("submissionState.releasedForStudent");
    }

    getFilenameList(files) {
        const filelist = files.map(file => {
            if (file.token === "") return "";
            file.link = Constants.GetServer() + "/api/user/submissionfiledownload/" + file.fileId + "/" + file.token + "";
            return <li key={file.fileId}>
                    <a href={file.link} target="_blank">{file.filename}</a>
            </li>
        });
        return filelist;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const submissionsWithComment = [];
        this.state.submissions.forEach(submission => {
            if (submission.comment !== null && submission.comment !== "") {
                submissionsWithComment[submissionsWithComment.length] = submission;
                submissionsWithComment[submissionsWithComment.length] = {commentLine: submission.comment, id: submission.id};
            } else {
                submissionsWithComment[submissionsWithComment.length] = submission;
            }
        })

        const submissionList = submissionsWithComment.map(submission => {


            if (submission.commentLine !== undefined) {
                return <tr key={submission.id + "comment"}>
                    <td>{submission.id}</td>
                    <td colSpan={4}>
                        <b>{Localization.Get("teacherSubmissions.comment")}</b><br/>{submission.commentLine}
                    </td>
                </tr>
            } else {
                const filenameList = this.getFilenameList(submission.files);
                return <tr key={submission.id}>
                    <td>{submission.id}</td>
                    <td>{Localization.GetLanguage() === "CZ" ? submission.dateCz : submission.dateEn}</td>
                    <td>{this.getSubmissionState(submission.state)}</td>
                    <td>{submission.submittedBy}</td>
                    <td>
                        <ul>
                            {filenameList}
                        </ul>
                    </td>
                    <td>
                        <Button size="sm" color="primary" tag={Link} to={"/studentsubmission/" + this.props.params.semesterCode
                                                                         + "/" + this.props.params.courseCode + "/" + this.props.params.parallelCode + "/" + this.props.params.parallelId + "/" + this.props.params.milestoneIdentifier
                                                                         + "/" + submission.id}>{Localization.Get("studentSubmissions.studentSubmission")}</Button>
                    </td>
                </tr>
            }
        });

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("studentSubmissions.title")}</h2><br/>
                            <h3>{Localization.Get("studentSubmissions.submissionList")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode}
                                {(this.state.submissionsLoaded === false) ? "" : (Localization.GetLanguage() === "CZ" ? this.state.nameCz : this.state.nameEn) } </h3>
                            { (this.state.submissionsLoaded === false) ? <LoadingSpinner /> : <div>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("studentSubmissions.id")}</th>
                                        <th>{Localization.Get("studentSubmissions.date")}</th>
                                        <th>{Localization.Get("studentSubmissions.state")}</th>
                                        <th>{Localization.Get("studentSubmissions.submittedBy")}</th>
                                        <th>{Localization.Get("studentSubmissions.files")}</th>
                                        <th>{Localization.Get("studentSubmissions.operations")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {submissionList}
                                    </tbody>
                                </Table>
                            </div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/studentcourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                {Localization.Get("studentSubmissions.goBack")}</Button>
                            &nbsp;
                            { this.state.canSubmit === true ?
                              <Button color="success" tag={Link}
                                      to={"/submitmilestone/student/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/"
                                          + this.props.params.parallelCode + "/" + this.props.params.parallelId + "/" + this.props.params.milestoneIdentifier}>{Localization.Get("studentSubmissions.submit")}
                              </Button> : "" }
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default StudentSubmissions;