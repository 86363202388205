import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button, Input, Label, Progress} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "./Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {toastError, toastSuccess} from "../public/Toaster";
import uploadFile from "./UploadFile";
import {ToastContainer} from 'react-toastify';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import Footer from "../public/Footer";

class SubmitMilestone extends Component {

    // https://codefrontend.com/file-upload-reactjs/ 21.1.2023

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            parallels: [],
            parallelsLoaded: false,
            submissionSingle: false,
            submissionParts: false,
            milestone: undefined,
            parts: [],
            uploadParts: [],
            canSubmit: false,
            submitting: false,
            submitted: false,
            submissionProgressText: "0 %",
            submissionProgressValue: "0 %",
            milestones: [],
            students: [],
            student: "",
            refresh: 0,
            comment: "",
            cancelRequest: false,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/' + this.props.params.type + '/course/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
                 (data) => {
                     if (data.result === 1) {
                         this.setState({parallels: data.parallels});
                         for (let i = 0; i < data.parallels.length; i++) {
                             if (data.parallels[i].id === this.props.params.parallelId) {
                                 const newMilestones = [];
                                 for (let j = 0; j < data.parallels[i].milestones.length; j++) {
                                     if (data.parallels[i].milestones[j].shown) {
                                         newMilestones[newMilestones.length] = data.parallels[i].milestones[j];
                                     }
                                 }
                                 data.parallels[i].milestones = newMilestones;
                                 const newStudents = [];
                                 newStudents[0] = {user: "", username: ""};
                                 const sortedStudents = data.parallels[i].students.sort((a, b) => a.name.localeCompare(b.name, 'cs'));
                                 for (let j = 0; j < sortedStudents.length; j++) {
                                     newStudents[newStudents.length] = sortedStudents[j];
                                 }
                                 data.parallels[i].students = newStudents;
                                 this.setState({milestones: data.parallels[i].milestones, students: data.parallels[i].students});
                                 let milestoneIdentifier = "";
                                 if (this.props.params.type === "teacher") {
                                     if (newMilestones.length > 0) milestoneIdentifier = newMilestones[0].identifier;
                                 } else {
                                     milestoneIdentifier = this.props.params.milestoneIdentifier;
                                 }
                                 for (let j = 0; j < data.parallels[i].milestones.length; j++) {
                                     if (data.parallels[i].milestones[j].identifier === milestoneIdentifier) {
                                         const milestone = data.parallels[i].milestones[j];
                                         const parts = milestone.parts;
                                         const uploadParts = [];
                                         for (let k = 0; k <= parts.length; k++) uploadParts[k] = {files: []};
                                         if (milestone.submissionType === "A") {
                                             this.setState({submissionSingle: true, submissionParts: false, milestone: milestone, parts: parts, uploadParts: uploadParts});
                                         }
                                         if (milestone.submissionType === "P" || parts.length <= 1) {
                                             this.setState({submissionSingle: false, submissionParts: true, milestone: milestone, parts: parts, uploadParts: uploadParts});
                                         }
                                         if (milestone.submissionType === "S" && parts.length > 1) {
                                             this.setState({submissionSingle: true, submissionParts: true, milestone: milestone, parts: parts, uploadParts: uploadParts});
                                         }
                                     }
                                 }
                             }
                         }
                         this.setState({parallelsLoaded: true});
                     }
                 });
        })();
    }

    getMilestoneDescription(milestone) {
        if (Localization.GetLanguage() === "CZ") {
            return milestone.nameCz;
        } else {
            return milestone.nameEn;
        }
    }

    getThisParallelName() {
        let name = "";
        for (let i = 0; i < this.state.parallels.length; i++) {
            if (this.state.parallels[i].id === this.props.params.parallelId) {
                if (Localization.GetLanguage() === "CZ") {
                    name = " - " + this.state.parallels[i].descriptionCz;
                } else {
                    name = " - " + this.state.parallels[i].descriptionEn;
                }
            }
        }
        if (name === "") return name;
        if (this.props.params.type === "teacher") return name;
        for (let i = 0; i < this.state.parallels.length; i++) {
            if (this.state.parallels[i].id === this.props.params.parallelId) {
                for (let j = 0; j < this.state.parallels[i].milestones.length; j++) {
                    if (this.state.parallels[i].milestones[j].identifier === this.props.params.milestoneIdentifier) {
                        return name + " / " + this.getMilestoneDescription(this.state.parallels[i].milestones[j]);
                    }
                }
            }
        }
        return name;
    }

    getDeadline(identifier) {
        if (this.props.params.type === "teacher") return "";
        for (let i = 0; i < this.state.parallels.length; i++) {
            for (let j = 0; j < this.state.parallels[i].milestones.length; j++) {
                if (this.state.parallels[i].milestones[j].identifier === identifier) {
                    return Localization.Get("submitMilestone.deadline") + ": "
                           + (Localization.GetLanguage() === "CZ" ? this.state.parallels[i].milestones[j].deadlineCz : this.state.parallels[i].milestones[j].deadlineEn);
                }
            }
        }
        return "";
    }

    addFile(id, e){
        const uploadParts = this.state.uploadParts;
        let changed = false;
        for (let i = 0; i < e.target.files.length; i++) {
            const filename = e.target.files[i].name.toLowerCase();
            if (filename.endsWith(".pdf") || filename.endsWith(".png") || filename.endsWith(".jpg") || filename.endsWith(".jpeg")) {
                changed = true;
                uploadParts[id].files[uploadParts[id].files.length] = e.target.files[i];
            }
        }
        if (changed) this.setState({uploadParts: uploadParts});
        e.target.value = null;
        this.updateCanSubmit();
    }

    download(id, fileId) {
        let mimeType = 'application/octet-stream';
        let file = this.state.uploadParts[id].files[fileId];
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([file], { type: mimeType }));
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    remove(id, fileId) {
        const uploadParts = this.state.uploadParts;
        uploadParts[id].files.splice(fileId, 1);
        this.setState({uploadParts: uploadParts});
        this.updateCanSubmit();
    }

    partHeading(id) {
        if (id === 0) {
            if (this.state.parts.length <= 1) {
                return <h4>{Localization.Get("submitMilestone.plain")}</h4>;
            } else {
                return <h4>{Localization.Get("submitMilestone.noParts")}</h4>;
            }
        } else {
            if (Localization.GetLanguage() === "CZ") {
                return <h4>{Localization.Get("submitMilestone.partNo") + " " + id + ": " + this.state.parts[id-1].nameCz}</h4>;
            } else {
                return <h4>{Localization.Get("submitMilestone.partNo") + " " + id + ": " + this.state.parts[id-1].nameEn}</h4>;
            }
        }

    }

    submissionSection(id) {

        let fileCountPart0 = 0;
        let fileCountPartNot0 = 0;
        for (let i = 0; i < this.state.uploadParts.length; i++) {
            if (i === 0) {
                fileCountPart0 = fileCountPart0 + this.state.uploadParts[i].files.length;
            } else {
                fileCountPartNot0 = fileCountPartNot0 + this.state.uploadParts[i].files.length;
            }
        }

        if (id === 0 && fileCountPartNot0 > 0) return <div></div>;
        if (id !== 0 && fileCountPart0 > 0) return <div></div>;

        const files = this.state.uploadParts[id].files;
        for (let i = 0; i < files.length; i++) files[i].id = i;
        const fileList = files.map(file => {
            return <tr key={id + "." +file.id}>
                <td>{file.name}</td>
                <td>{file.size}</td>
                <td><Button color="primary" onClick={() => {this.download(id, file.id);}}>{Localization.Get("submitMilestone.download")}</Button>&nbsp;
                    <Button color="danger" disabled={this.state.submitting || this.state.submitted} onClick={() => {this.remove(id, file.id);}}>{Localization.Get("submitMilestone.remove")}</Button>
                </td>
            </tr>
        })
        return <div>
            {this.partHeading(id)}
            <Table bordered hover>
                <thead>
                <tr>
                    <th>{Localization.Get("submitMilestone.filename")}</th>
                    <th>{Localization.Get("submitMilestone.fileSize")}</th>
                    <th>{Localization.Get("submitMilestone.fileOperations")}</th>
                </tr>
                </thead>
                <tbody>
                {fileList}
                </tbody>
            </Table>
            {this.state.submitting === true || this.state.submitted ?
                <Label className="btn btn-primary btn-file disabled" >{Localization.Get("submitMilestone.submitFile")}</Label>
            :
                <Label className="btn btn-primary btn-file" >{Localization.Get("submitMilestone.submitFile")}
                    <input type="file" id="filebox" name="filebox" onChange={(e) => {this.addFile(id, e);}} style={{ display: "none" }}
                           multiple accept=".jpg, .jpeg, .png, .pdf" /></Label>
            }
        </div>
    }

    async submit() {
        this.setState({submitting: true, cancelRequest: false});
        const files = [];
        for (let i = 0; i < this.state.uploadParts.length; i++) {
            for (let j = 0; j < this.state.uploadParts[i].files.length; j++) {
                files[files.length] = {part: i};
            }
        }
        let milestoneIdentifier = "";
        if (this.props.params.type === "student") {
            milestoneIdentifier = this.props.params.milestoneIdentifier
        } else {
            milestoneIdentifier = this.state.milestone.identifier;
        }
        rest({
                       path: '/api/' + this.props.params.type + '/submissionrequest', method: 'post', body: {
                           semesterCode: this.props.params.semesterCode,
                           courseCode: this.props.params.courseCode,
                           parallelId: this.props.params.parallelId,
                           milestoneIdentifier: milestoneIdentifier,
                           files: files,
                           submittedFor: this.state.student,
                           comment: this.state.comment,
                       }, context: this
                   },
                   async (data) => {
                       const that = data.context;
                       if (data.result === 1) {
                           let partId = 0;
                           let fileCount = 0;
                           let totalFileSize = 0;
                           let submissionProgress = 0;
                           let failed = false;
                           for (let i = 0; i < that.state.uploadParts.length; i++) {
                               for (let j = 0; j < that.state.uploadParts[i].files.length; j++) {
                                   totalFileSize = totalFileSize + that.state.uploadParts[i].files[j].size;
                               }
                           }
                           while (partId < that.state.uploadParts.length && that.state.submitting === true && this.state.cancelRequest === false) {
                               let fileId = 0;
                               while (fileId < that.state.uploadParts[partId].files.length && that.state.submitting === true && this.state.cancelRequest === false) {
                                   const remoteFileId = data.files[fileCount++].fileId;
                                   let fileUploadCompleted = false;
                                   uploadFile(that.state.uploadParts[partId].files[fileId], data.submissionId, remoteFileId, that,
                                              (other) => {
                                                  fileUploadCompleted = true;
                                              },
                                              (other) => {
                                                  fileUploadCompleted = true;
                                                  failed = true
                                                  other.setState({submitting: false, cancelRequest: false});
                                              },
                                              (progress, other) => {
                                                    if (other.state.submitting === true && this.state.cancelRequest === false) {
                                                        submissionProgress = submissionProgress + progress;
                                                        other.setState({submissionProgressText: Math.floor(submissionProgress * 100 / totalFileSize) + " %",
                                                            submissionProgressValue: Math.floor(submissionProgress * 100 / totalFileSize)});
                                                    }
                                              },
                                              (other) => {
                                                    return that.state.submitting && !that.state.cancelRequest;
                                              });
                                   while (!fileUploadCompleted && that.state.submitting === true && that.state.cancelRequest === false) {
                                       await new Promise((resolve, reject) => setTimeout(resolve, 100));
                                   }
                                   fileId++;
                               }
                               partId++;
                           }
                           if (that.state.submitting === true && this.state.cancelRequest === false) {
                               await rest({path: '/api/user/fileupload/' + data.submissionId, method: 'post', body: {}},
                                          (data) => {
                                              if (data.result === 1) {
                                                  this.setState({submissionProgressText: Localization.Get("submitMilestone.submitted"), submissionProgressValue: 100, submitted: true});
                                                  toastSuccess(Localization.Get("submitMilestone.submitted"));
                                              } else {
                                                  toastError(Localization.Get("general.error"));
                                                  this.setState({submissionProgressText: "0 %", submissionProgressValue: 0});
                                                  that.cancel();
                                              }
                                          });
                           } else {
                               if (failed === true) {
                                   toastError(Localization.Get("submitMilestone.cancelled"));
                                   this.setState({submissionProgressText: "0 %", submissionProgressValue: 0});
                               } else {
                                   toastError(Localization.Get("submitMilestone.cancelled"));
                                   this.setState({submissionProgressText: "0 %", submissionProgressValue: 0});
                               }
                           }
                           that.cancel();
                       } else {
                           toastError(Localization.Get("general.error"));
                           this.setState({submissionProgressText: "0 %", submissionProgressValue: 0});
                           that.cancel();
                       }
                   });
    }

    cancel() {
        this.setState({submitting: false, cancelRequest: false});
    }

    requestCancel() {
        toastSuccess(Localization.Get("submitMilestone.willBeCancelled"));
        this.setState({cancelRequest: true});
    }

    updateCanSubmit() {
        let files = 0;
        for (let i = 0; i < this.state.uploadParts.length; i++) {
            files = files + this.state.uploadParts[i].files.length;
        }
        if (files > 0 && this.state.canSubmit === false) this.setState({canSubmit: true});
        if (files === 0 && this.state.canSubmit === true) this.setState({canSubmit: false});
    }

    handleMilestoneChange = (e) => {
        this.setState({milestone: e.target.value});
        for (let j = 0; j < this.state.milestones.length; j++) {
            if (this.state.milestones[j].identifier === e.target.value) {
                const milestone = this.state.milestones[j];
                if (!milestone.shown) {
                    this.setState({submissionSingle: false, submissionParts: false, milestone: milestone, parts: [], uploadParts: []});
                } else {
                    const parts = milestone.parts;
                    const uploadParts = [];
                    for (let k = 0; k <= parts.length; k++) uploadParts[k] = {files: []};
                    if (milestone.submissionType === "A") {
                        this.setState({submissionSingle: true, submissionParts: false, milestone: milestone, parts: parts, uploadParts: uploadParts});
                    }
                    if (milestone.submissionType === "P" || parts.length <= 1) {
                        this.setState({submissionSingle: false, submissionParts: true, milestone: milestone, parts: parts, uploadParts: uploadParts});
                    }
                    if (milestone.submissionType === "S" && parts.length > 1) {
                        this.setState({submissionSingle: true, submissionParts: true, milestone: milestone, parts: parts, uploadParts: uploadParts});
                    }
                }
            }
        }
    }

    handleStudentChange = (e) => {
        this.setState({student: e.target.value});
    }

    getTeacherSelection() {
        if (this.props.params.type === "student" || this.state.milestone === undefined) return <div></div>;
        const milestoneList = this.state.milestones.map(milestone => {
            if (Localization.GetLanguage() === "CZ") {
                return <option key={milestone.identifier} value={milestone.identifier}>{milestone.nameCz}</option>
            } else {
                return <option key={milestone.identifier} value={milestone.identifier}>{milestone.nameEn}</option>
            }
        })
        const studentList = this.state.students.map(student => {
            return <option key={student.username} value={student.username}>{student.name}</option>
        })
        return <div>
            <Row>
                <Col md={6}>
                    <h3>{Localization.Get("submitMilestone.milestoneList")}</h3>
                    <Input type="select" onChange={this.handleMilestoneChange} value={this.state.milestone.identifier} >
                        {milestoneList}
                    </Input>
                </Col>
                <Col md={6}>
                    <h3>{Localization.Get("submitMilestone.studentList")}</h3>
                    <Input type="select" onChange={this.handleStudentChange} value={this.state.student} >
                        {studentList}
                    </Input>
                </Col>
            </Row><br/>
        </div>;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const submissionParts = this.state.parts.map(part => {
            return <div key={part.part}>
                {this.submissionSection(part.part)}
            </div>
        });

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("submitMilestone.title")}</h2><br/>
                            <h3>{Localization.Get("submitMilestone.makeSubmission")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode} {this.getThisParallelName()} </h3>
                            { this.props.params.type === "student" ? <div><b>{this.getDeadline(this.props.params.milestoneIdentifier)}</b></div> : "" }
                            <br/>
                            { (this.state.parallelsLoaded === false) ? <LoadingSpinner /> : <div>
                                {this.getTeacherSelection()}
                                { this.state.submissionSingle === false ? "" :
                                    <div>
                                        {this.submissionSection(0)}
                                    </div>
                                }
                                { this.state.submissionParts === false ? "" :
                                    <div>
                                        {submissionParts}
                                    </div>
                                }
                            </div> }
                            <br/>
                            {Localization.Get("submitMilestone.comment")}
                            <textarea className="form-control" id="comment" placeholder="" value={this.state.comment}
                                      onChange={event => {
                                          this.setState({comment: event.target.value})
                                      }}
                                      rows={5}/><br/>
                            {Localization.Get("submitMilestone.submissionProgress")}: {this.state.submissionProgressText}
                            <Progress color="success" value={this.state.submissionProgressValue} /><p>&nbsp;</p>
                            <Button color="warning" disabled={this.state.canSubmit === false || this.state.submitting || this.state.submitted} onClick={() => {this.submit();}}>{Localization.Get("submitMilestone.uploadFiles")}</Button>&nbsp;
                            { this.props.params.type === "student" ?
                              <Button color="primary" tag={Link} to={"/studentcourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                  {Localization.Get("submitMilestone.goBack")}</Button>
                              :
                              <Button color="primary" tag={Link} to={"/teachercourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                  {Localization.Get("submitMilestone.goBack")}</Button>
                            }
                            &nbsp;
                            {this.state.submitting && this.state.cancelRequest === false ? <Button color="success" onClick={() => {this.requestCancel();}}>{Localization.Get("submitMilestone.cancel")}</Button> : "" }
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default SubmitMilestone;