import React, { useState } from "react";
import { useZxing } from "react-zxing";
import { useMediaDevices } from "react-media-devices";
import {Input} from "reactstrap";

// https://github.com/nomadoda/react-zxing/tree/main/example/src 21.1.2023

export default function BarcodeScanner(props) {
    const [deviceId, setDeviceId] = useState(0);
    const [deviceSelected, setDeviceSelected] = useState(false);

    const constraints = {
        video: true,
        audio: false,
    };
    const { devices } = useMediaDevices({constraints: constraints});

    let i = 0;
    while (!deviceSelected && devices !== null && devices.length > 0 && i < devices.length) {
        if (devices[i].kind === "videoinput") {
            setDeviceSelected(true); // this will get set for the next run only
            setDeviceId(i); // set the last one
        }
        i++;
    }

    //alert(devices?.[deviceId]?.deviceId);

    const {
        ref,
        torch: {
            on: torchOn,
            off: torchOff,
            isOn: isTorchOn,
            isAvailable: isTorchAvailable,
        },
    } = useZxing({
        paused: false,
        deviceId: devices?.[deviceId]?.deviceId,
        onResult(result) {
            props.callback(result.getText());
        },
    });

    const handleSelectedDevice = (e) => {
        setDeviceId(e.target.value);
    }

    return (
        <>
            <div>
                        <Input type="select" onChange={handleSelectedDevice} value={deviceId} >
                            {devices?.map((device, index) => (
                                device.kind === "videoinput" ?
                                    <option value={index}>{device.label.trim() === "" ? "???" : device.label}</option>
                                    : ""
                            ))}
                        </Input><br/>
                {isTorchAvailable ?
                 <div><button className="btn btn-primary"
                         onClick={() => {
                             if (isTorchOn) {
                                 torchOff();
                             } else {
                                 torchOn();
                             }
                         }}
                         disabled={!isTorchAvailable}
                 >
                     {isTorchOn ? props.torchOff : props.torchOn }
                 </button><br/><br/></div> : ""}
            </div>
            <video ref={ref} />
        </>
    );
};

