import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "./Navigation";
import rest from "../public/Rest";
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Button} from "reactstrap";
import {toastError} from "../public/Toaster";
import Footer from "../public/Footer";

class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            username: null,
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/public/session', method: 'delete'},
                 (data) => {
                     if (data.result === 1) {
                         window.location("/");
                     } else {
                         toastError("general.error");
                     }
                 });
        })();
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {(this.state.isLanguageInitialized && this.state.username !== null) ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("logout.title")}</h2>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
             </Container>
        );
    }

}

export default Logout;