import React, {Component} from 'react';
import Localization from "./Localization";
import '../App.css';
import LoadingSpinner from "./Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import Footer from "./Footer";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
    }

    // 12.6.2022 https://stackoverflow.com/questions/52211465/drawing-on-a-html5-canvas-how-to-draw-at-tip-of-mouse-cursor

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        return (
             <Container>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <p>
                                {Localization.Get("home.intro")}
                            </p>
                            {(this.state.ssoUrl !== null) ? <div>
                                <a className="btn btn-primary btn-lg" onClick={this.ssoLogin}>{Localization.Get("home.login")}</a><br/><br/>
                                <br/><br/>
                            </div> : <div>&nbsp;</div>}
                            <Button color="warning" tag={Link} to={"/login"}> {Localization.Get("home.localLogin")}</Button>
                            <br/><br/>
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                    </Col>
                </Row>
                 <Footer />
             </Container>
        );
    }

    ssoLogin() {
        window.location = "/api/oauth20?code=null&state=null";
    }
}

export default Home;