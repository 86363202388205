import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import {ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import Table from "react-bootstrap/Table";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class StudentCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            parallels: [],
            parallelsLoaded: false,
            refresh: 0,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/student/course/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({parallels: data.parallels});
                        this.setState({parallelsLoaded: true});
                    }
                });
        })();
    }

    getParallelDescription(parallel) {
        if (Localization.GetLanguage() === "CZ") {
            return parallel.descriptionCz;
        } else {
            return parallel.descriptionEn;
        }
    }

    getMilestoneDescription(milestone) {
        if (Localization.GetLanguage() === "CZ") {
            return milestone.nameCz;
        } else {
            return milestone.nameEn;
        }
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const parallels = this.state.parallels;
        const milestones = [];
        for (let i = 0; i < parallels.length; i++) {
            for (let j = 0; j < parallels[i].milestones.length; j++) {
                parallels[i].milestones[j].descriptionCz = parallels[i].descriptionCz;
                parallels[i].milestones[j].descriptionEn = parallels[i].descriptionEn;
                parallels[i].milestones[j].parallelCode = parallels[i].code;
                parallels[i].milestones[j].descriptionEn = parallels[i].descriptionEn;
                parallels[i].milestones[j].parallelId = parallels[i].id;
                parallels[i].milestones[j].key = i + "-" + j;
                milestones[milestones.length] = parallels[i].milestones[j];
            }
        }

        const milestoneList = milestones.map(milestone => {
            return <tr key={milestone.key}>
                <td>{milestone.parallelCode}</td>
                <td>{this.getParallelDescription(milestone)}</td>
                <td>{this.getMilestoneDescription(milestone)}</td>
                <td>
                    <Button size="sm" color="primary" tag={Link}
                            to={"/studentsubmissions/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/" + milestone.parallelCode + "/"
                                + milestone.parallelId + "/" + milestone.identifier}>{Localization.Get("studentCourse.view")}
                    </Button>&nbsp;
                    {milestone.studentSubmission ?
                     <Button size="sm" color="success" tag={Link}
                             to={"/submitmilestone/student/" + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/" + milestone.parallelCode + "/"
                                 + milestone.parallelId + "/" + milestone.identifier }>{Localization.Get("studentCourse.submit")}
                     </Button> : ""}
                </td>
            </tr>
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("studentCourse.title")}</h2><br/>
                            <h3>{Localization.Get("studentCourse.parallelList")} {this.props.params.semesterCode} / {this.props.params.courseCode}</h3>
                            { (this.state.parallelsLoaded === false || this.state.milestonesLoaded === false) ? <LoadingSpinner /> : <div>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("studentCourse.parallelCode")}</th>
                                        <th>{Localization.Get("studentCourse.parallelDescription")}</th>
                                        <th>{Localization.Get("studentCourse.parallelMilestoneDescription")}</th>
                                        <th>{Localization.Get("studentCourse.parallelOperation")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {milestoneList}
                                    </tbody>
                                </Table></div> }
                            <br/>
                            <Button color="primary" tag={Link} to={"/studentcourses/"}>
                                {Localization.Get("studentCourse.goBack")}</Button>
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
             </Container>
        );
    }

}

export default StudentCourse;