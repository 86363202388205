import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ButtonGroup} from "react-bootstrap";
import {Button, Input} from "reactstrap";
import {Link} from "react-router-dom";
import {View} from "react-native-web";
import Footer from "../public/Footer";

class TeacherCourses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            courses: [],
            coursesLoaded: false,
            semestersLoaded: false,
            semesters: [],
            semester: "",
            refresh: 0,
            showAll: false,
        }
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/user/semester', method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({semesters: data.semesters});
                        this.setState({semestersLoaded: true});
                        this.setState({semester: data.currentSemesterCode});
                        this.loadCourses(data.currentSemesterCode);
                    }
                });
        })();
    }

    getCourseName(course) {
        if (Localization.GetLanguage() === "CZ") {
            return course.nameCz;
        } else {
            return course.nameEn;
        }
    }

    getSemesterName(semester) {
        if (Localization.GetLanguage() === "CZ") {
            return semester.code + " - " + semester.nameCz;
        } else {
            return semester.code + " - " + semester.nameEn;
        }
    }

    handleSemesterChange = (e) => {
        this.setState({showAll: false});
        this.setState({semester: e.target.value});
        this.setState({coursesLoaded: false});
        this.loadCourses(e.target.value);
    }

    loadCourses(semesterCode) {
        if (semesterCode !== "") {
            rest({path: '/api/teacher/course/' + semesterCode, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        this.setState({courses: data.courses});
                        this.setState({coursesLoaded: true});
                    }
                });
        }
    }

    showAllSwitch() {
        this.setState({showAll: !this.state.showAll});
    }

    getVisibleList() {
        const visibleList = [];
        this.state.courses.forEach(course => {
            if (this.state.showAll || course.shown) {
                visibleList[visibleList.length] = course;
            }
        });
        return visibleList;
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const semesterList =  this.state.semesters.map(semester => {
                    return <option key={semester.code} value={semester.code}>{this.getSemesterName(semester)}</option>
                });

        const courseList = this.getVisibleList().map(course => {
            return <tr key={course.code}>
                <td>{course.code}</td>
                <td>{this.getCourseName(course)}</td>
                <td>
                    <Button size="sm" color="primary" tag={Link} to={"/teachercourse/" + this.state.semester + "/" + course.code}>{Localization.Get("teacherCourses.course")}</Button>
                </td><td>
                    <Button size="sm" color="success" tag={Link} to={"/teacherchart/" + this.state.semester + "/" + course.code}>{Localization.Get("teacherCourses.chart")}</Button>
                </td>
            </tr>
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("teacherCourses.title")}</h2><br/>
                             <h3>{Localization.Get("teacherCourses.semesterList")}</h3>
                                 { this.state.semestersLoaded === false ? <LoadingSpinner /> : <div>
                                     <Input type="select" onChange={this.handleSemesterChange} value={this.state.semester} >
                                         {semesterList}
                                     </Input> </div>
                                 }
                                 <br/>
                             <h3>{Localization.Get("teacherCourses.courseList")}</h3>
                             { this.state.coursesLoaded === false ? <LoadingSpinner /> : <div>
                                 { this.state.showAll === false ?
                                   <Button color="success" onClick={() => {this.showAllSwitch();}}>
                                       {Localization.Get("teacherCourses.showAll")}</Button> : "" }
                                 { this.state.showAll === false ? <br/> : "" }
                                 { this.state.showAll === false ? <br/> : "" }
                                 <Table bordered hover>
                                     <thead>
                                     <tr>
                                         <th>{Localization.Get("teacherCourses.courseCode")}</th>
                                         <th>{Localization.Get("teacherCourses.courseName")}</th>
                                         <th colSpan={2}>{Localization.Get("teacherCourses.courseOperation")}</th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {courseList}
                                     </tbody>
                                 </Table></div> }
                             <p>&nbsp;</p>
                         </div> : <LoadingSpinner />}
                     </Col>
                 </Row>
                 <Footer />
             </Container>
        );
    }

}

export default TeacherCourses;