import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Button, Input} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-GB";
import {ToastContainer} from 'react-toastify';
import {View} from "react-native-web";
import {ButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import {toastSuccess} from "../public/Toaster";
import Footer from "../public/Footer";

class SetupParallel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            milestones: [],
            milestoneKeys: [],
            milestonesLoaded: false,
            parallels: [],
            parallelKeys: [],
            parallelsLoaded: false,
            parallel: this.props.params.parallelId,
            refresh: 0,
        }
        registerLocale("cs", cs);
        registerLocale("en-GB", en);
        this.saveIt = this.saveIt.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            rest({path: '/api/teacher/course/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        const parallels = [];
                        const parallelKeys = [];
                        data.parallels.forEach(parallel => {
                            const milestones = [];
                            parallel.milestones.forEach(milestone => {
                                milestone.partCount = milestone.parts.length;
                                milestone.deadline = Date.parse(milestone.deadline);
                                milestones[milestone.identifier] = milestone;
                            })
                            parallel.milestones = milestones;
                            parallels[parallel.id] = parallel;
                            parallelKeys[parallelKeys.length] = parallel.id;
                        })
                        this.setState({parallels: parallels});
                        this.setState({parallelKeys: parallelKeys});
                        this.setState({parallelsLoaded: true});
                    }
                });
        })();
        (() => {
            rest({path: '/api/teacher/milestone/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
                (data) => {
                    if (data.result === 1) {
                        const milestones = [];
                        const milestoneKeys = [];
                        data.milestones.forEach(milestone => {
                            milestones[milestone.identifier] = milestone;
                            milestoneKeys[milestoneKeys.length] = milestone.identifier;
                        })
                        this.setState({milestones: milestones});
                        this.setState({milestoneKeys: milestoneKeys});
                        this.setState({milestonesLoaded: true});
                    }
                });
        })();
    }

    getMilestoneDescription(milestone) {
        if (Localization.GetLanguage() === "CZ") {
            return milestone.identifier + " - " + milestone.nameCz;
        } else {
            return milestone.identifier + " - " + milestone.nameEn;
        }
    }

    handleParallelChange() {
        const parallels = this.state.parallels;
        this.state.milestoneKeys.forEach(milestoneKey => {
            parallels[this.props.params.parallelId].milestones[milestoneKey] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestoneKey], milestoneKey);
            parallels[this.state.parallel].milestones[milestoneKey] = this.fixMilestone(parallels[this.state.parallel].milestones[milestoneKey], milestoneKey);
            parallels[this.props.params.parallelId].milestones[milestoneKey].shown = parallels[this.state.parallel].milestones[milestoneKey].shown;
            parallels[this.props.params.parallelId].milestones[milestoneKey].deadline = parallels[this.state.parallel].milestones[milestoneKey].deadline;
            parallels[this.props.params.parallelId].milestones[milestoneKey].submissionType = parallels[this.state.parallel].milestones[milestoneKey].submissionType;
            parallels[this.props.params.parallelId].milestones[milestoneKey].studentIsShown = parallels[this.state.parallel].milestones[milestoneKey].studentIsShown;
            parallels[this.props.params.parallelId].milestones[milestoneKey].studentSubmission = parallels[this.state.parallel].milestones[milestoneKey].studentSubmission;
            parallels[this.props.params.parallelId].milestones[milestoneKey].studentReSubmission = parallels[this.state.parallel].milestones[milestoneKey].studentReSubmission;
            parallels[this.props.params.parallelId].milestones[milestoneKey].partCount = parallels[this.state.parallel].milestones[milestoneKey].partCount;
            for (let i = 0; i < parallels[this.state.parallel].milestones[milestoneKey].partCount; i++) {
                parallels[this.props.params.parallelId].milestones[milestoneKey].parts[i] = {
                    part: parallels[this.state.parallel].milestones[milestoneKey].parts[i].part,
                    nameCz: parallels[this.state.parallel].milestones[milestoneKey].parts[i].nameCz,
                    nameEn: parallels[this.state.parallel].milestones[milestoneKey].parts[i].nameEn,
                    minimumValue: parallels[this.state.parallel].milestones[milestoneKey].parts[i].minimumValue,
                    maximumValue: parallels[this.state.parallel].milestones[milestoneKey].parts[i].maximumValue,
                    contributesTo: parallels[this.state.parallel].milestones[milestoneKey].parts[i].contributesTo,
                }
            }
        });
        this.setState({parallels: parallels});
        toastSuccess("setupParallel.settingOverwritten")
    }

    getThisParallelName() {
        if (this.state.parallels[this.props.params.parallelId] === undefined) {
            return "";
        } else {
            if (Localization.GetLanguage() === "CZ") {
                return " - " + this.state.parallels[this.props.params.parallelId].descriptionCz;
            } else {
                return " - " + this.state.parallels[this.props.params.parallelId].descriptionEn;
            }
        }
    }

    getParallelName(parallel) {
        if (Localization.GetLanguage() === "CZ") {
            return parallel.code + " - " + parallel.descriptionCz;
        } else {
            return parallel.code + " - " + parallel.descriptionEn;
        }
    }

    setMilestoneIsShown(milestone, checked) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].shown = checked;
        this.setState({parallels: parallels});
    }

    setStudentSubmission(milestone, checked) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].studentSubmission = checked;
        this.setState({parallels: parallels});
    }

    setStudentReSubmission(milestone, checked) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].studentReSubmission = checked;
        this.setState({parallels: parallels});
    }

    getHour(milestone, parallelMilestone) {
        try {
            if (parallelMilestone.deadline === null || isNaN(parallelMilestone.deadline)) {
                return "";
            } else {
                return <Input type="select" onChange={(e) => this.setDeadlineHour(milestone, e.target.value)} value={(new Date(parallelMilestone.deadline)).getHours()} >
                    [<option value={0}>00</option>, <option value={1}>01</option>, <option value={2}>02</option>, <option value={3}>03</option>,
                    <option value={4}>04</option>, <option value={5}>05</option>, <option value={6}>06</option>, <option value={7}>07</option>,
                    <option value={8}>08</option>, <option value={9}>09</option>, <option value={10}>10</option>, <option value={11}>11</option>,
                    <option value={12}>12</option>, <option value={13}>13</option>, <option value={14}>14</option>, <option value={15}>15</option>,
                    <option value={16}>16</option>, <option value={17}>17</option>, <option value={18}>18</option>, <option value={19}>19</option>,
                    <option value={20}>20</option>, <option value={21}>21</option>, <option value={22}>22</option>, <option value={23}>23</option>]
                    </Input>
            }
        } catch (e) {
            return "";
        }
    }

    getMinute(milestone, parallelMilestone) {
        try {
            if (parallelMilestone.deadline === null || isNaN(parallelMilestone.deadline)) {
                return "";
            } else {
                return <Input type="select" onChange={(e) => this.setDeadlineMinute(milestone, e.target.value)} value={(new Date(parallelMilestone.deadline)).getMinutes()} >
                    [<option value={0}>00</option>, <option value={5}>05</option>, <option value={10}>10</option>, <option value={15}>15</option>,
                    <option value={20}>20</option>, <option value={25}>25</option>, <option value={30}>30</option>, <option value={35}>35</option>,
                    <option value={40}>40</option>, <option value={45}>45</option>, <option value={50}>50</option>, <option value={55}>55</option>]
                    </Input>
            }
        } catch (e) {
            return "";
        }
    }

    getDivider(parallelMilestone) {
        try {
            if (parallelMilestone.deadline === null || isNaN(parallelMilestone.deadline)) {
                return "";
            } else {
                return ":"
            }
        } catch (e) {
            return "";
        }
    }

    setDeadlineHour(milestone, hour) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline = new Date(parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline.setHours(hour);
        this.setState({parallels: parallels});
    }

    setDeadlineMinute(milestone, minute) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline = new Date(parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline.setMinutes(minute);
        this.setState({parallels: parallels});
    }

    setDeadline(milestone, deadline) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].deadline = deadline;
        this.setState({parallels: parallels});
    }

    setStudentIsShown(milestone, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].studentIsShown = value;
        this.setState({parallels: parallels});
    }

    setSubmissionType(milestone, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].submissionType = value;
        this.setState({parallels: parallels});
    }

    setPartNameCz(milestone, part, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[part].nameCz = value;
        this.setState({parallels: parallels});
    }

    setPartNameEn(milestone, part, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[part].nameEn = value;
        this.setState({parallels: parallels});
    }

    setPartMinimumValue(milestone, part, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[part].minimumValue = (isNaN(parseInt(value)) ? 0 : parseInt(value));
        this.setState({parallels: parallels});
    }

    setPartMaximumValue(milestone, part, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[part].maximumValue = (isNaN(parseInt(value)) ? 0 : parseInt(value));
        this.setState({parallels: parallels});
    }

    setPartContributesTo(milestone, part, value) {
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[part].contributesTo = value;
        this.setState({parallels: parallels});
    }

    setParts(milestone, value) {
        value = Number.parseInt(value);
        if (value === undefined || value <= 0) value = 1;
        const parallels = this.state.parallels;
        parallels[this.props.params.parallelId].milestones[milestone.identifier] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestone.identifier], milestone.identifier);
        for (let i = parallels[this.props.params.parallelId].milestones[milestone.identifier].partCount; i <= value; i++) {
            if (parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[i-1] === undefined) {
                parallels[this.props.params.parallelId].milestones[milestone.identifier].parts[i-1] = {
                    part: i,
                    nameCz: i.toString(),
                    nameEn: i.toString(),
                    minimumValue: 0,
                    maximumValue: 100,
                    contributesTo: milestone.identifier,
                }
            }
        }
        parallels[this.props.params.parallelId].milestones[milestone.identifier].partCount = value;
        this.setState({parallels: parallels});
    }

    fixMilestone(milestone, identifier) {
        if (milestone === undefined) milestone = {identifier: identifier};
        if (milestone.shown === undefined) milestone.shown = false;
        if (milestone.deadline === undefined) milestone.deadline = null;
        if (milestone.studentSubmission === undefined) milestone.studentSubmission = false;
        if (milestone.studentReSubmission === undefined) milestone.studentReSubmission = false;
        if (milestone.submissionType === undefined) milestone.submissionType = "S";
        if (milestone.studentIsShown === undefined) milestone.studentIsShown = "A";
        if (milestone.partCount === undefined) {
            milestone.partCount = 1;
            milestone.parts = [{
                part: 1,
                nameCz: this.state.milestones[identifier].nameCz,
                nameEn: this.state.milestones[identifier].nameEn,
                minimumValue: 0,
                maximumValue: 100,
                contributesTo: identifier,
            }];
        }
        return milestone;
    }

    getMilestoneSetup(milestone) {
        if (!this.state.parallelsLoaded || !this.state.milestonesLoaded) return (<div></div>);
        let parallelMilestone = this.state.parallels[this.props.params.parallelId].milestones[milestone.identifier];
        parallelMilestone = this.fixMilestone(parallelMilestone, milestone.identifier);

        const indices = [];
        for (let i = 0; i < parallelMilestone.partCount; i++) indices[i] = i;
        const parts = indices.map(i => {

            const contributionList = this.state.milestoneKeys.map(milestoneKey => {
                return <option value={this.state.milestones[milestoneKey].identifier}>{this.getMilestoneDescription(this.state.milestones[milestoneKey])}</option>
            })

            return (
                <tr>
                    <td>{i+1}</td>
                    <td>
                        <input type="text" value={parallelMilestone.parts[i].nameCz} onChange={(e) => {this.setPartNameCz(milestone, i, e.target.value)}} />
                    </td>
                    <td>
                        <input type="text" value={parallelMilestone.parts[i].nameEn} onChange={(e) => {this.setPartNameEn(milestone, i, e.target.value)}} />
                    </td>
                    <td>
                        <input type="number" value={parallelMilestone.parts[i].minimumValue} onChange={(e) => {this.setPartMinimumValue(milestone, i, e.target.value)}}
                               min="-100" max="0" />
                    </td>
                    <td>
                        <input type="number" value={parallelMilestone.parts[i].maximumValue} onChange={(e) => {this.setPartMaximumValue(milestone, i, e.target.value)}}
                               min="0" max="100" />
                    </td>
                    <td>
                        <Input type="select" onChange={(e) => this.setPartContributesTo(milestone, i, e.target.value)} value={parallelMilestone.parts[i].contributesTo} >
                            {contributionList}
                        </Input>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <h4>{Localization.Get("setupParallel.milestoneSetup")} {this.getMilestoneDescription(milestone)}</h4>
                <Table>
                    <tbody>
                    <tr>
                        <td>{Localization.Get("setupParallel.basicSettings")}</td>
                        <td>
                            <input id={milestone.identifier + "shown"} type="checkbox"
                                   checked={parallelMilestone.shown === true}
                                   onChange={(e) => {
                                       this.setMilestoneIsShown(milestone, e.target.checked);
                                   }} />
                            &nbsp;
                            <label htmlFor={milestone.identifier + "-shown"}>{Localization.Get("setupParallel.shown")}</label>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                {parallelMilestone.shown === true ?
                    <div>
                        <Table>
                            <tbody>
                            <tr>
                                <td>{Localization.Get("setupParallel.deadline")}</td>
                                <td>
                                    <DatePicker className="form-control" dropdownMode="select" closeOnScroll={true} locale={Localization.GetLanguage() === "CZ" ? "cs" : "en"}
                                                dateFormat={Localization.GetLanguage() === "CZ" ? "dd.MM.yyyy" : "MM/dd/yyyy"} calendarStartDay={1}
                                                selected={parallelMilestone.deadline === undefined ? "" : parallelMilestone.deadline} onChange={ (date) => this.setDeadline(milestone, date)} />
                                </td>
                                <td>{this.getHour(milestone, parallelMilestone)}</td>
                                <td>{this.getDivider(parallelMilestone)}</td>
                                <td>{this.getMinute(milestone, parallelMilestone)}</td>
                            </tr>
                            <tr>
                                <td>{Localization.Get("setupParallel.studentSubmissions")}</td>
                                <td>
                                    <input id={milestone.identifier + "-submission"} type="checkbox"
                                           checked={parallelMilestone.studentSubmission === true}
                                           onChange={(e) => {
                                               this.setStudentSubmission(milestone, e.target.checked);
                                           }} />
                                    &nbsp;
                                    <label htmlFor={milestone.identifier + "-submission"}>{Localization.Get("setupParallel.studentSubmission")}</label>
                                    <br/>
                                    <input id={milestone.identifier + "reSubmission"} type="checkbox"
                                           checked={parallelMilestone.studentReSubmission === true}
                                           disabled={parallelMilestone.studentSubmission !== true}
                                           onChange={(e) => {
                                               this.setStudentReSubmission(milestone, e.target.checked);
                                           }} />
                                    &nbsp;
                                    <label htmlFor={milestone.identifier + "-reSubmission"}>{Localization.Get("setupParallel.studentReSubmission")}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>{Localization.Get("setupParallel.studentIsShown")}</td>
                                <td>
                                    <form>
                                        <div onChange={(e) => this.setStudentIsShown(milestone, e.target.value)} value = "O">
                                            <input type="radio" value="L" checked={parallelMilestone.studentIsShown === "L"} /> {Localization.Get("setupParallel.lastSubmission")}<br/>
                                            <input type="radio" value="A" checked={parallelMilestone.studentIsShown === "A"} /> {Localization.Get("setupParallel.allSubmissions")}
                                        </div>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <td>{Localization.Get("setupParallel.parts")}</td>
                                <td>
                                    <input type="number" value={parallelMilestone.partCount} onChange={(e) => {this.setParts(milestone, e.target.value)}}
                                           min="1" max="100" /> {Localization.Get("setupParallel.valueMustBePositive")}<br/>
                                </td>
                            </tr>
                            { parallelMilestone.partCount > 1 ?
                            <tr>
                                <td>{Localization.Get("setupParallel.submissionType")}</td>
                                <td>
                                    <form>
                                        <div onChange={(e) => this.setSubmissionType(milestone, e.target.value)}>
                                            <input type="radio" value="P" checked={parallelMilestone.submissionType === "P"} /> {Localization.Get("setupParallel.inParts")}<br/>
                                            <input type="radio" value="A" checked={parallelMilestone.submissionType === "A"} /> {Localization.Get("setupParallel.allTogether")}<br/>
                                            <input type="radio" value="S" checked={parallelMilestone.submissionType === "S"} /> {Localization.Get("setupParallel.studentDecides")}
                                        </div>
                                    </form>
                                </td>
                            </tr> : "" }
                            </tbody>
                        </Table>
                        <Table>
                            <thead>
                            <tr>
                                <th>{Localization.Get("setupParallel.part")}</th>
                                <th>{Localization.Get("setupParallel.nameCz")}</th>
                                <th>{Localization.Get("setupParallel.nameEn")}</th>
                                <th>{Localization.Get("setupParallel.minPoints")}</th>
                                <th>{Localization.Get("setupParallel.maxPoints")}</th>
                                <th>{Localization.Get("setupParallel.contributesTo")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {parts}
                            </tbody>
                        </Table>
                    </div>
                : ""}
                <br/>
            </div>
        );
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");

        const parallelList =  this.state.parallelKeys.map(parallelKey => {
            return <option value={this.state.parallels[parallelKey].id}>{this.getParallelName(this.state.parallels[parallelKey])}</option>
        })

        const milestoneList = this.state.milestoneKeys.map(milestoneKey => {
            return this.getMilestoneSetup(this.state.milestones[milestoneKey]);
        })

        return (
             <Container>
                 <Navigation /><br/>
                 {this.state.isLanguageInitialized ? <div>
                     <View style={{alignItems: 'flex-end'}}>
                         <ButtonGroup>
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("CZ");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.cz")}</Button>&nbsp;
                             <Button color="primary" size="small" onClick={() => {Localization.SetLanguage("EN");this.setState({refresh: this.state.refresh + 1});}}>{Localization.Get("language.en")}</Button>
                         </ButtonGroup>
                     </View>
                 </div> : "" }
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("setupParallel.title")}</h2><br/>
                            <h3>{Localization.Get("setupParallel.parallelList")}</h3>
                            { this.state.parallelsLoaded === false ? <LoadingSpinner /> : <div>
                                <Input type="select" onChange={(e) => {this.setState({parallel: e.target.value})}} value={this.state.parallel} >
                                    {parallelList}
                                </Input> </div>
                            }
                            <p>{Localization.Get("setupParallel.instructionForUse")}</p>
                            <Button color="warning" onClick={() => {this.handleParallelChange()}}>{Localization.Get("setupParallel.overwriteIt")}</Button>
                            <br/>
                            <br/>
                            <h3>{Localization.Get("setupParallel.milestoneList")} {this.props.params.semesterCode} / {this.props.params.courseCode} / {this.props.params.parallelCode} {this.getThisParallelName()}</h3>
                            { this.state.parallelsLoaded === false || this.state.milestonesLoaded === false ? <LoadingSpinner /> :
                                <div>
                                    {milestoneList}
                                    <br/><Button color="success" onClick={this.saveIt}>{Localization.Get("setupParallel.saveIt")}</Button>&nbsp;
                                    <Button color="primary" tag={Link} to={"/teachercourse/" + this.props.params.semesterCode + "/" + this.props.params.courseCode}>
                                        {Localization.Get("setupParallel.goBack")}</Button>
                                </div>
                            }
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

    saveIt() {
        let parallels = this.state.parallels;
        let milestones = [];
        this.state.milestoneKeys.forEach(milestoneKey => {
            milestones[milestones.length] = this.fixMilestone(parallels[this.props.params.parallelId].milestones[milestoneKey], milestoneKey);
        });
        rest({path: '/api/teacher/parallel/' + this.props.params.semesterCode + "/" + this.props.params.courseCode + "/" + this.props.params.parallelId,
                 method: 'put', body: {
                milestones: milestones
        }},
            (data) => {
                // nothing
            });
    }

}

export default SetupParallel;