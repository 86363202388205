import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import {Input} from 'reactstrap';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";
import Table from "react-bootstrap/Table";
import {ToastContainer} from "react-toastify";
import Footer from "../public/Footer";

class TeacherChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            parallels: [],
            parallelsLoaded: false,
            milestones: [],
            milestone: "",
            milestoneIdentifiers: [],
            part: "",
            checkedParallel: [], // checkedParallel[id]
            refresh: 0,
            callBacksInjected: false,
            chartData: [],
            chartCallBack: null,
            grading: [],
            studentGrades: [],
        }
        this.getData = this.getData.bind(this);
        this.injectCallBacks = this.injectCallBacks.bind(this);
        this.localizeAndAppendScript = this.localizeAndAppendScript.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            this.loadCourse();
        })();
    }

    loadCourse() {
        rest({path: '/api/teacher/chart/' + this.props.params.semesterCode + "/" + this.props.params.courseCode, method: 'get'},
             (data) => {
                 if (data.result === 1 && !this.state.parallelsLoaded) {
                     this.setState({parallels: data.parallels});
                     if (data.milestones.length > 0) this.setState({milestone: data.milestones[0]});
                     this.setState({parallelsLoaded: true});
                     const checkedParallel = [];
                     for (let i = 0; i < data.parallels.length; i++) {
                         checkedParallel[data.parallels[i].id] = false;
                     }
                     this.setState({checkedParallel: checkedParallel});
                     const milestoneIdentifiers = [];
                     for (let i = 0; i < data.milestones.length; i++) {
                         milestoneIdentifiers[data.milestones[i].identifier] = i;
                         data.milestones[i].parts = 0;
                     }
                     this.setState({milestoneIdentifiers: milestoneIdentifiers});
                     for (let i = 0; i < data.grading.length; i++) {
                         const identifier = data.grading[i].identifier;
                         if (data.milestones[milestoneIdentifiers[identifier]].parts < data.grading[i].part) {
                            data.milestones[milestoneIdentifiers[identifier]].parts = data.grading[i].part;
                         }
                     }
                     this.setState({milestones: data.milestones, studentGrades: data.studentGradesDtos, grading: data.grading});

                     const chartScript = document.createElement("script");
                     chartScript.text = data.chartJs;
                     chartScript.async = true;
                     document.body.appendChild(chartScript);

                     this.localizeAndAppendScript(data.drawChartJs);

                     this.injectCallBacks();

                 }
             });
    }

    localizationReplace(text, key) {
        return text.replace("teacherChart." + key, Localization.Get("teacherChart." + key));
    }

    localizeAndAppendScript(teacherChart) {
        if (this.state.isLanguageInitialized) {
            teacherChart = this.localizationReplace(teacherChart, "student");
            teacherChart = this.localizationReplace(teacherChart, "points");

            const drawStatisticsScript = document.createElement("script");
            drawStatisticsScript.text = teacherChart;
            drawStatisticsScript.async = true;
            document.body.appendChild(drawStatisticsScript);
        } else {
            (() => setTimeout(() => this.localizeAndAppendScript(teacherChart), 100))();
        }
    }

    injectCallBacks() {
        if (!this.state.callBacksInjected) {
            try {
                window["setGetData"](this.getData);
                this.setState({callBacksInjected: true});
            } catch (e) {
                (() => setTimeout(() => this.injectCallBacks(), 100))();
            }
        }
    }

    getData(chartCallBack) {
        this.setState({chartCallBack: chartCallBack});
        return this.state.chartData;
    }

    getParallelDescription(parallel) {
        if (Localization.GetLanguage() === "CZ") {
            return parallel.descriptionCz;
        } else {
            return parallel.descriptionEn;
        }
    }

    getMilestoneDescription(milestone) {
        if (Localization.GetLanguage() === "CZ") {
            return milestone.nameCz;
        } else {
            return milestone.nameEn;
        }
    }

    getMilestoneDescriptionWithSlash(milestone) {
        return " / " + this.getMilestoneDescription(milestone);
    }

    getPartWithSlash(part) {
        return " / " + part;
    }

    getColor(index) {
        const colors = [
            {border: "rgba(0,0,255,1.0)", background: "rgba(0,0,255,0.5)"},
            {border: "rgba(255,0,0,1.0)", background: "rgba(255,0,0,0.5)"},
            {border: "rgba(0,255,0,1.0)", background: "rgba(0,255,0,0.5)"},
            {border: "rgba(255,128,0,1.0)", background: "rgba(255,128,0,0.5)"},
            {border: "rgba(0,255,255,1.0)", background: "rgba(0,255,255,0.5)"},
            {border: "rgba(255,0,255,1.0)", background: "rgba(255,0,255,0.5)"},
            {border: "rgba(0,0,0,1.0)", background: "rgba(0,0,0,0.5)"},
        ];
        while (index >= colors.length) index = index - colors.length;
        return colors[index];
    }

    getValueForGrades(grades, identifier) {
        let value = -1;
        for (let i = 0; i < grades.length; i++) {
            if (grades[i].identifier === identifier) {
                if (grades[i].evaluationType === "NUMBER") {
                    if (grades[i].doubleValue === null) {
                        return 0;
                    } else {
                        return grades[i].doubleValue;
                    }
                }
                if (grades[i].evaluationType === "STRING") {
                    if (grades[i].stringValue === null || grades[i].stringValue === "null") {
                        return 0;
                    } else {
                        return 1;
                    }
                }
                if (grades[i].evaluationType === "BOOLEAN") {
                    if (grades[i].booleanValue === null || grades[i].booleanValue === false) {
                        return 0;
                    } else {
                        return 1;
                    }
                }
            }
        }
        return value;
    }

    randomResult(step, count) {
        let result = 0;
        for (let i = 0; i < count; i++) {
            result = result + Math.random() * step;
        }
        return Math.round(result);
    }

    drawData(identifier, part, checkedParallel) {
        const datasets = [];
        const demo = false;

        for (let i = 0; i < this.state.parallels.length; i++) {
            if (checkedParallel[this.state.parallels[i].id]) {
                const data = [];
                if (this.state.part === "") {
                    // grades
                    // Get list of all students for checked parallel
                    // Get all grades for students of the parallel
                    for (let j = 0; j < this.state.parallels[i].students.length; j++) {
                        const username = this.state.parallels[i].students[j].username;
                        if (demo) {
                            data[data.length] = this.randomResult(2, 7);
                        } else {
                            for (let k = 0; k < this.state.studentGrades.length; k++) {
                                if (this.state.studentGrades[k].username === username) {
                                    data[data.length] = this.getValueForGrades(this.state.studentGrades[k].grades, identifier);
                                }
                            }
                        }
                    }
                } else {
                    // grading
                    const partNumber = Number.parseInt(part);
                    for (let j = 0; j < this.state.parallels[i].students.length; j++) {
                        if (demo) {
                            data[data.length] = this.randomResult(2, 7);
                        } else {
                            const username = this.state.parallels[i].students[j].username;
                            for (let k = 0; k < this.state.grading.length; k++) {
                                const grading = this.state.grading[k];
                                if (grading.student === username && grading.identifier === identifier && grading.part === partNumber && grading.points !== null) {
                                    data[data.length] = grading.points;
                                }
                            }
                        }
                    }
                }
                const sortedData = data.sort((a, b) => b - a);
                datasets[datasets.length] = {label: this.state.parallels[i].code, data: sortedData};
            }
        }

        // final touch - setting the labels and data series lengths
        let maxLength = 0;
        for (let i = 0; i < datasets.length; i++) {
            if (datasets[i].data.length > maxLength) maxLength = datasets[i].data.length;
        }
        const labels = [];
        while (labels.length < maxLength) labels[labels.length] = "";
        for (let i = 0; i < datasets.length; i++) {
            while (datasets[i].data.length < maxLength) datasets[i].data[datasets[i].data.length] = -1;
            if (datasets[i].data.length > maxLength) maxLength = datasets[i].data.length;
            datasets[i].borderColor = this.getColor(i).border;
            datasets[i].backgroundColor = this.getColor(i).background;
        }
        const chartData = {labels: labels, datasets: datasets};
        if (this.state.chartCallBack === null) {
            this.setState({chartData: chartData});
        } else {
            this.state.chartCallBack(chartData);
        }
    }

    handleMilestoneChange = (e) => {
        this.setState({milestone: e.target.value});
        this.setState({milestone: this.state.milestones[this.state.milestoneIdentifiers[e.target.value]]});
        this.setState({part: ""});
        this.drawData(this.state.milestones[this.state.milestoneIdentifiers[e.target.value]].identifier, this.state.part, this.state.checkedParallel);
    }

    handlePartChange = (e) => {
        this.setState({part: e.target.value});
        this.drawData(this.state.milestone.identifier, e.target.value, this.state.checkedParallel);
    }

    refresh = () => {
        this.setState({refresh: this.state.refresh + 1});
        return 1;
    }

    updateTarget(parallelId, value) {
        const checkedParallel = this.state.checkedParallel;
        checkedParallel[parallelId] = value;
        this.setState({checkedParallel: checkedParallel});
        this.drawData(this.state.milestone.identifier, this.state.part, checkedParallel);
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const chartPlace = React.createElement("div", {id: "chartPlace"}, "");

        const parallelList = this.state.parallels.map(parallel => {
            return <tr key={parallel.code}>
                <td>{parallel.code}</td>
                <td>{this.getParallelDescription(parallel)}</td>
                <td>{parallel.teachers}</td>
                <td>
                    <input type="checkbox" checked={this.state.checkedParallel[parallel.id]} onChange={(e) => this.updateTarget(parallel.id, e.target.checked)} /> {Localization.Get("teacherChart.showIt")}
                </td>
            </tr>
        });

        const milestoneList = this.state.milestones.map(milestone => {
            if (Localization.GetLanguage() === "CZ") {
                return <option key={milestone.identifier} value={milestone.identifier}>{milestone.nameCz}</option>
            } else {
                return <option key={milestone.identifier} value={milestone.identifier}>{milestone.nameEn}</option>
            }
        });

        const parts = [""];
        for (let i = 0; i < this.state.milestone.parts; i++) {
            parts[parts.length] = (i + 1).toString();
        }
        const partList = parts.map(part => {
            return <option key={part} value={part}>{part}</option>
        });

        return (
             <Container>
                 <Navigation /><br/>
                 <Row>
                     <Col md={12}>
                        {this.state.isLanguageInitialized ? <div>
                            <h1>{Localization.Get("main.title")}</h1>
                            <h2>{Localization.Get("teacherChart.title")}</h2><br/>
                            <h3>{Localization.Get("teacherChart.settings")}  {this.props.params.semesterCode} / {this.props.params.courseCode}</h3>
                            { this.state.parallelsLoaded === false ? <LoadingSpinner /> : <div>
                                <b>{Localization.Get("teacherChart.selectMilestone")}</b>
                                <Input type="select" onChange={this.handleMilestoneChange} value={this.state.milestone.identifier} >
                                    {milestoneList}
                                </Input>
                                <br/>
                                <b>{Localization.Get("teacherChart.selectPart")}</b><br/>
                                <Input type="select" onChange={this.handlePartChange} value={this.state.part} >
                                    {partList}
                                </Input>
                                <br/>
                                <b>{Localization.Get("teacherChart.selectParallels")}</b><br/>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>{Localization.Get("teacherChart.parallelCode")}</th>
                                        <th>{Localization.Get("teacherChart.parallelDescription")}</th>
                                        <th>{Localization.Get("teacherChart.parallelTeachers")}</th>
                                        <th>{Localization.Get("teacherChart.selection")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {parallelList}
                                    </tbody>
                                </Table>
                                <h3>{Localization.Get("teacherChart.chart")}  {this.props.params.semesterCode} / {this.props.params.courseCode}
                                    {this.state.milestone === "" ? "" : this.getMilestoneDescriptionWithSlash(this.state.milestone)}
                                    {this.state.part === "" ? "" : this.getPartWithSlash(this.state.part)}
                                </h3>
                                {chartPlace}
                            </div>
                            }
                            <p>&nbsp;</p>
                        </div> : <LoadingSpinner />}
                     </Col>
                </Row>
                 <Footer />
                 <ToastContainer/>
             </Container>
        );
    }

}

export default TeacherChart;