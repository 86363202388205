import React, {Component} from 'react';
import Localization from "../public/Localization";
import '../App.css';
import LoadingSpinner from "../public/Spinner";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from "../user/Navigation";
import rest from "../public/Rest";

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLanguageInitialized: false,
            requestRecords: null,
            heapRecords: null,
            currentMinute: null,
            callBacksInjected: false,
            dataRequested: false,
            refresh: 0,
        }
        this.getData = this.getData.bind(this);
        this.injectCallBacks = this.injectCallBacks.bind(this);
        this.localizeAndAppendScript = this.localizeAndAppendScript.bind(this);
    }

    componentDidMount() {
        (() => {
            if (Localization.Initialize(() => {this.setState({isLanguageInitialized: true});})) this.setState({isLanguageInitialized: true});
        })();
        (() => {
            if (!this.state.dataRequested) {
                this.setState({dataRequested: true});
                rest({path: '/api/admin/statistics', method: 'get'},
                     (data) => {
                         if (data.result === 1) {
                             this.setState({requestRecords: data.requestRecords});
                             this.setState({heapRecords: data.heapRecords});
                             this.setState({currentMinute: data.currentMinute});

                             const chartScript = document.createElement("script");
                             chartScript.text = data.chartJs;
                             chartScript.async = true;
                             document.body.appendChild(chartScript);

                             this.localizeAndAppendScript(data.drawStatisticsJs);

                             this.injectCallBacks();
                         }
                     });
            }
        })();
    }

    localizationReplace(text, key) {
        return text.replace("statistics." + key, Localization.Get("statistics." + key));
    }

    localizeAndAppendScript(drawStatisticsJs) {
        if (this.state.isLanguageInitialized) {
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "requestsPerMinute");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "count");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "minute");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "minute");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "minute");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "requestsAvgTime");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "requestsMinTime");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "requestsMaxTime");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "time");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "heapAvg");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "heapMin");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "heapMax");
            drawStatisticsJs = this.localizationReplace(drawStatisticsJs, "heapUseMegaBytes");

            const drawStatisticsScript = document.createElement("script");
            drawStatisticsScript.text = drawStatisticsJs;
            drawStatisticsScript.async = true;
            document.body.appendChild(drawStatisticsScript);
        } else {
            (() => setTimeout(() => this.localizeAndAppendScript(drawStatisticsJs), 100))();
        }
    }

    injectCallBacks() {
        if (!this.state.callBacksInjected) {
            try {
                window["setGetData"](this.getData);
                this.setState({callBacksInjected: true});
            } catch (e) {
                (() => setTimeout(() => this.injectCallBacks(), 100))();
            }
        }
    }

    getData() {
        return {requestRecords: this.state.requestRecords, heapRecords: this.state.heapRecords, currentMinute: this.state.currentMinute};
    }

    render() {
        if (this.state.isLanguageInitialized) document.title = Localization.Get("main.appTitle");
        const requestsPerMinute = React.createElement("div", {id: "requestsPerMinute"}, "");
        const responsesTimePerMinute = React.createElement("div", {id: "responsesTimePerMinute"}, "");
        const heapUsePerMinute = React.createElement("div", {id: "heapUsePerMinute"}, "");
        return (
             <Container>
                 <Navigation /><br/>
                 <Row>
                     <Col md={12}>
                         {this.state.isLanguageInitialized ? <div>
                             <h1>{Localization.Get("main.title")}</h1>
                             <h2>{Localization.Get("statistics.title")}</h2><br/>
                             <h3>{Localization.Get("statistics.requestsPerMinuteTitle")}</h3>
                             {requestsPerMinute}
                             <div id="requestsPerMinute"/>
                             <h3>{Localization.Get("statistics.responsesTimePerMinuteTitle")}</h3>
                             {responsesTimePerMinute}
                             <div id="responsesTimePerMinute"/>
                             <h3>{Localization.Get("statistics.heapUsePerMinuteTitle")}</h3>
                             {heapUsePerMinute}
                             <div id="heapUsePerMinute"/>
                             <p>&nbsp;</p>
                         </div> : <LoadingSpinner />}
                     </Col>
                </Row>
             </Container>
        );
    }

}

export default LandingPage;